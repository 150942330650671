.hidden {
    display: none !important;
}
.kbrhidden {
    display: none !important;
}
.tab-title {
    cursor: pointer;
}
.pck-btn {
    cursor: pointer;
    background-color: #ff0027;
}
.order-line-selected{
    background-color: #ff9999 !important;
    border-radius: 5px;
}
.cac_line{
    cursor: pointer !important;
}
.cac_line:hover{
    background-color: #ff9999 !important;
}
.tlm_line:hover{
    background-color: #ff9999 !important;
}
.greenbg{
    background-color: #ccffcc !important;
}

.border {
    border: 2px solid black;
}

.line {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.plusMinusInput {
    font-family: 'Fa solid 900', sans-serif;
    font-size: 15px;
    line-height: 15px;
    background-color: #fd323d;
    border-radius: 4px;
    padding: 5px;
    color: white;
}
.dev-btn-container{
    position: fixed;
    top: 70px !important;
}

.fa {
    font-family: 'Fa solid 900', sans-serif;
}

.cursor_pointer{
    cursor: pointer;
}
.cursor_forbidden{
    cursor: not-allowed;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.option:hover .tooltiptext-option {
    visibility: visible;
    opacity: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.paint:hover .tooltiptext-paint {
    visibility: visible;
    opacity: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.question:hover .tooltiptext-question {
    visibility: visible;
    opacity: 1;
}
