    /* I you want to edit this code, write also the modifications in the ./css/custom.css file */
.kbrwview-report .direction {
  font-family: Fontawesome, sans-serif;
}
.kbrwview-report .flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kbrwview-report table {
  border-collapse: collapse;
  background: white;
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: none;
  margin-bottom: 20px;
  padding-bottom: 250px; /* 5 rows shown from the sticky */
}
.kbrwview-report thead {
  display: table-header-group;
  vertical-align: middle;
  border: none;
  border-color: inherit;
  border-top-color: inherit;
  border-right-color: inherit;
  border-bottom-color: inherit;
  border-left-color: inherit;
}
.kbrwview-report th {
  font-weight: 900;
}
.kbrwview-report thead th {
  position: sticky;
  top: 70px;
  bottom: 150px;
}
.kbrwview-report .direction {
  width: 0px;
}
.kbrwview-report table tr {
  height: 50px;
}
.kbrwview-report table tbody tr {
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  background-color: #fdfdfd;
}
.kbrwview-report table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
.kbrwview-report table tbody tr:hover{
  background-color: #e0e0e0;
}
.kbrwview-report tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.kbrwview-report table thead tr th {
  background: #fd323d;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
}
.kbrwview-report table tbody tr td, table tbody tr th{
  border: none;
  padding-left: 20px;
}
.kbrwview-report  h1 ,.kbrwview-report  h2 ,.kbrwview-report  h3 {
  font-weight: normal;
}
.kbrwview-report  h1 { font-size: 30px; }
.kbrwview-report  h2 { font-size: 24px; }
.kbrwview-report  h3 { font-size: 20px; }
div {
  outline:none;
}
/* FOR IE */
input::-ms-clear {
    display: none;
}
