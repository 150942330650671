#loader {
  display: none
}

@page {
  size: A4;
  margin-top: 0.0cm !important;
  margin-bottom: 0.0cm !important;
  margin-left: 0.0cm !important;
  margin-right: 0.0cm !important;
}

@media print {
  .not-printed {
    display: none !important;
  }

  .print-label-group {
    width: 210mm !important;
    height: 297mm !important;
    page-break-after: always;
  }

  .need-pagebreak-after {
    page-break-after: always;
  }

  .need-pagebreak-before {
    page-break-before: always;
  }

  #content .need-pagebreak-after:last-of-type {
    page-break-after: avoid !important;
  }

  .print-label-list, .print-label {
    page-break-inside: avoid !important;
  }

  body {
    width: auto!important;
    margin: auto!important;
  }
}
