#loader {
  display: none
}

@page {
  size: A4;
  margin-top: 0.0cm !important;
  margin-bottom: 0.0cm !important;
  margin-left: 0.0cm !important;
  margin-right: 0.0cm !important;
}

@media print {
  .not-printed {
    display: none !important;
  }

  .missingp-table-row-wrap {
    page-break-inside: avoid;
  }

  body{
    width: auto!important;
    margin: auto!important;
  }
}
