#loader {
  display: none
}

@page {
  size: A4;
  margin-top: 0.0cm !important;
  margin-bottom: 0.0cm !important;
  margin-left: 0.0cm !important;
  margin-right: 0.0cm !important;
}

@media print {
  .not-printed,
  .content-container,
  .Footer {
    display: none !important;
  }

  body{
    width: auto!important;
    margin: auto!important;
  }

  .product-declaration-errors,
  .product-controled-errors,
  .bac-declaration-errors,
  .bac-controled-errors,
  .control-comment-content,
  .mission-info {
    page-break-inside: avoid;
  }
}
