.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #9FAAAE;
  stroke-opacity: 0.3;
}

line#limit {
  stroke: #FED966;
  stroke-width: 3;
  stroke-dasharray: 3 6;
}

.tooltip-row {
  display: inline-flex;
  align-items: center;
}

.mini-color {
  width: 15px; height: 15px;
}

.marg-tooltip-content {
  margin-right: 5px
}

.cross-over {
  fill: none;
  stroke: grey;
  stroke-width: 1;
  shape-rendering: crispEdges;
}
