:root {
  --mpx-blue: #4cafb1;
  --white: white;
  --black: black;
  --mpx_print_title: #8b445c;
  --light-grey: #d1d1d1;
  --medium-aquamarine: #65ca8e;
  --gainsboro: rgba(216, 216, 216, .43);
  --mpx_primary: #fd323d;
  --steel-blue-2: #227092;
  --medium-sea-green: #20a757;
  --mpx_primary_darken: #a3040e;
  --orange-red: #e8522d;
  --black-2: #333;
  --monop-red: #fa3543;
  --bg: rgba(166, 166, 166, .6);
  --white-smoke: whitesmoke;
  --dark-salmon: #ee9a85;
  --steel-blue: #227092;
  --dark-red: #991d00;
  --dark-orange: #e8902d;
  --mpx_primary_lighten: #ff7b82;
  --mpx_primary_shade: #dedede;
  --mpx_primary_shade_darken: #999;
  --mpx_primary_shade_lighten: #f2f2f2;
  --mpx_gray: #303030;
  --dark-orange-2: rgba(232, 144, 45, .47);
  --indian-red: rgba(250, 53, 67, .69);
  --mpx-blue-hover: #3e9798;
  --mpx-orange-hover: #bf7623;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

body {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

a {
  color: #333;
  text-decoration: none;
}

.container {
  text-align: left;
  background-color: #fff;
  flex-flow: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  overflow: visible;
}

.container.home {
  font-family: Roboto, sans-serif;
}

.image {
  width: 200px;
}

.form-block {
  flex: 0 auto;
}

.form {
  width: 90%;
}

.jar-button {
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.jar-button.floating-button {
  text-transform: uppercase;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, .67);
}

.jar-button.floating-button.disabled {
  z-index: 1;
  box-shadow: none;
  cursor: not-allowed;
  background-color: rgba(250, 53, 67, .6);
  position: fixed;
}

.jar-button.floating-button.botton-button {
  position: static;
}

.header-wrapper {
  z-index: 2;
  width: 100%;
  height: 90px;
  display: block;
  position: fixed;
  top: 0;
  overflow: visible;
}

.content-title-wrappe {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.content-title-wrappe.dispatch-wrapper {
  flex: 0 auto;
}

.header-content {
  z-index: 5;
  color: #fff;
  background-color: #fd323d;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  font-weight: 200;
  display: flex;
  position: relative;
  box-shadow: 0 1px 10px rgba(51, 51, 51, .5);
}

.menu-button-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu-button-wrapper.menu-button {
  cursor: pointer;
}

.text-block {
  padding: 5px 10px;
  font-family: Materialicons, sans-serif;
  font-size: 60px;
}

.menu-bg {
  background-color: rgba(166, 166, 166, .6);
  width: 100%;
  height: 100vh;
  transition: background-color .5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.menu-bg.menu-bg-hidden {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .5s;
}

.menu-bg.menu-bg-hidden.hidden, .hidden {
  display: none;
}

.menu-wrapper {
  z-index: 10;
  background-color: #f5f5f5;
  flex-direction: column;
  width: 70%;
  height: 100vh;
  transition: left .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 1px 0 11px #6d6d6d;
}

.menu-wrapper.menu-wrapper-hidden {
  transition: left .5s;
  left: -75%;
}

.menu-user-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
}

.text-block-2 {
  color: #fd323d;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  font-family: Materialicons, sans-serif;
  font-size: 150px;
  display: flex;
}

.text-block-3.user-label {
  font-size: 30px;
  font-weight: 500;
}

.body {
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  overflow: visible;
}

.store-label {
  margin-top: 20px;
  font-size: 18px;
}

.menu-items-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.menu-items {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.disconnect-wrapper {
  position: static;
}

.menu-bottom-button {
  cursor: pointer;
  background-color: #d1d1d1;
  border-width: 1px;
  border-color: #f5f5f5;
  border-bottom-style: solid;
  justify-content: center;
  align-items: center;
  height: 70px;
  display: flex;
}

.menu-bottom-button.last {
  border-bottom-width: 0;
}

.text-block-4 {
  text-transform: uppercase;
  font-size: 16px;
}

.item {
  color: #333;
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  align-items: center;
  height: 70px;
  text-decoration: none;
  display: flex;
}

.item.collect-item {
  border-top: 1px solid #d1d1d1;
}

.item.collect-item.active {
  color: #fff;
  background-color: #e8522d;
  display: flex;
}

.item.preparation-item {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}

.item.preparation-item.active {
  color: #fff;
  background-color: #e8522d;
}

.item.active {
  color: #fff;
  background-color: #fd323d;
}

.item.first {
  border-top: 1px solid #d1d1d1;
}

.item-label {
  padding-left: 20px;
  font-size: 25px;
}

.text-block-7 {
  padding-left: 30px;
  font-family: Materialicons, sans-serif;
  font-size: 45px;
}

.text-block-8 {
  color: #ee9a85;
  font-family: Materialicons, sans-serif;
  font-size: 45px;
}

.close-menu {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.menu-title-wrapper {
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  font-size: 35px;
  display: flex;
}

.title {
  font-weight: 300;
}

.form-messages {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.success-message {
  color: #52b67b;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-weight: 700;
  display: flex;
}

.success-message.hidden {
  display: none;
}

.error-message {
  color: #fd323d;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-weight: 700;
  display: flex;
}

.error-message.hidden {
  display: none;
}

.link-block {
  color: #333;
  text-decoration: none;
}

.loader-wrapper {
  z-index: 1001;
  background-color: rgba(166, 166, 166, .6);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.loader-wrapper.hidden {
  transition-property: none;
  display: none;
}

.modal-wrappers {
  z-index: 1000;
  background-color: rgba(166, 166, 166, .6);
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrappers.hidden {
  display: none;
}

.loader-content {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.html-embed {
  padding: 10px;
  box-shadow: 1px 1px 3px rgba(51, 51, 51, .66);
}

.confirmation-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 500px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.confirmation-modal.hidden {
  display: none;
}

.modal-title {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  font-size: 22px;
  font-weight: 500;
  display: flex;
}

.modal-content {
  justify-content: center;
  align-items: center;
  max-height: 90vh;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
  overflow: auto;
}

.modal-content.control-report {
  flex: 1;
  padding-left: 25px;
  padding-right: 25px;
  overflow: scroll;
}

.modal-content.batch-modal {
  max-height: 80vh;
  overflow: hidden;
}

.modal-actions {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 5px 20px 20px;
  display: flex;
}

.modal-actions.control-report {
  flex: 0 auto;
  justify-content: center;
}

.modal-button {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 7px;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 30px;
  font-size: 18px;
  display: flex;
}

.modal-button.ok-button {
  background-color: #fd323d;
  border-radius: 7px;
}

.modal-button.ok-button:hover {
  background-color: rgba(253, 50, 61, .8);
}

.modal-button.ok-button.ok-error {
  width: 200px;
}

.modal-button.ok-button.control-report {
  background-color: #227092;
}

.modal-button.ok-button.control-report:hover {
  background-color: rgba(34, 112, 146, .9);
}

.modal-button.ok-button.control-report.not-printed {
  flex: 0 auto;
  width: 200px;
}

.modal-button.ok-button.previsualisation {
  background-color: #227092;
}

.modal-button.ok-button.modal-delete-action {
  margin-bottom: 5px;
  margin-left: 25px;
  margin-right: 25px;
}

.modal-button.ko-button {
  color: #333;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #333;
  border-radius: 7px;
}

.modal-button.ko-button:hover {
  background-color: rgba(0, 0, 0, .05);
}

.modal-button.ko-button.hidden {
  display: none;
}

.modal-button.add-ug {
  background-color: #fd323d;
  flex: 0 auto;
  min-width: 40px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Materialicons, sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.modal-button.add-ug:hover {
  background-color: rgba(253, 50, 61, .8);
}

.modal-button.control-report.print-btn {
  border-radius: 0;
  flex: 0 auto;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.modal-button.delete-button {
  background-color: #fd323d;
  border-radius: 7px;
  margin-bottom: 5px;
  margin-left: 25px;
  margin-right: 25px;
}

.modal-button.delete-button:hover {
  background-color: rgba(253, 50, 61, .8);
}

.modal-button.delete-button.ok-error {
  width: 200px;
}

.modal-button.delete-button.control-report {
  background-color: #227092;
}

.modal-button.delete-button.control-report:hover {
  background-color: rgba(34, 112, 146, .9);
}

.modal-button.delete-button.control-report.not-printed {
  flex: 0 auto;
  width: 200px;
}

.modal-button.delete-button.previsualisation {
  background-color: #227092;
}

.modal-button.delete-button.modal-delete-action {
  margin-bottom: 5px;
  margin-left: 25px;
  margin-right: 25px;
}

.scanner-modal {
  background-color: #f5f5f5;
  flex-direction: column;
  max-width: 500px;
  margin-left: 30px;
  margin-right: 30px;
  box-shadow: 1px 1px 11px rgba(51, 51, 51, .5);
}

.scanner-modal.hidden {
  display: none;
}

.scanner-modal-title {
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 22px;
  font-weight: 500;
  display: flex;
}

.form-block-2 {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 20px;
  display: flex;
}

.text-field {
  margin-bottom: 0;
}

.text-field.codebar-input {
  font-size: 20px;
}

.text-field-2 {
  text-align: center;
  border-style: none;
  font-size: 24px;
  font-weight: 400;
}

.qty-modal {
  background-color: #f5f5f5;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
}

.modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  max-width: 500px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.modal.qty-modal {
  border-radius: 7px;
  flex: 0 auto;
  display: block;
}

.modal.qty-modal.hidden {
  display: none;
}

.modal.error-modal {
  border-radius: 7px;
}

.modal.error-modal.hidden {
  display: none;
}

.modal.report-modal {
  border-radius: 7px;
}

.modal.report-modal.hidden, .modal.delay-modal.hidden, .modal.scanner-error-modal.hidden, .modal.select-splitting-modal.hidden, .modal.comment-modal.hidden, .modal.select-modal.hidden {
  display: none;
}

.modal.save-slots-modal {
  max-width: 800px;
}

.modal.print-missings-modal.hidden {
  display: none;
}

.modal.print-selection-modal {
  border-radius: 7px;
  flex: 0 auto;
  display: block;
}

.modal.print-selection-modal.hidden {
  display: none;
}

.modal.user-details-modal {
  min-width: 550px;
}

.modal.zone-iris-modal {
  width: 80%;
  max-width: none;
}

.modal.control-report-modal {
  flex: 1;
  width: 80%;
  max-width: none;
  display: flex;
}

.modal.split-mission {
  width: 800px;
  max-width: none;
  max-height: 100%;
  overflow: auto;
}

.modal.split-mission.hidden {
  display: none;
}

.modal.batch-error {
  max-height: 90vh;
}

.text {
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
}

.text.max-qty-text {
  text-align: left;
  flex: none;
  margin-right: 10px;
  padding-bottom: 0;
  line-height: 16px;
}

.text.section-number {
  padding-bottom: 10px;
  font-weight: 700;
}

.qty-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.qty-input {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-family: Materialicons, sans-serif;
  font-size: 40px;
  display: flex;
}

.qty-input:hover {
  background-color: rgba(253, 50, 61, .8);
}

.qty-input.plus-input.number-modification {
  width: 40px;
  height: 40px;
  font-size: 25px;
  line-height: 25px;
}

.qty-input.minus-input.number-modification {
  width: 40px;
  height: 40px;
  font-size: 25px;
}

.qty {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 30px;
  font-weight: 500;
  display: flex;
}

.qty.show-number {
  color: #303030;
  border-radius: 5px;
  width: 80px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
}

.content {
  width: 100%;
}

.content-container {
  border-style: none;
  flex-flow: column;
  flex: 0 auto;
  align-content: stretch;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding-top: 90px;
  overflow: visible;
}

.content-container.dispatch-container {
  flex-direction: column;
  display: flex;
}

.content-container.container-zones {
  height: 100%;
}

.collect-indication {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  display: flex;
}

.indication-text {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 300;
  display: flex;
}

.manual-link {
  color: #333;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
}

.manual-link.cleanlink {
  color: #fff;
  text-align: center;
  background-color: #fd323d;
  border-radius: 7px;
  padding: 12px;
  text-decoration: none;
}

.manual-link.cleanlink.saisie-manuel {
  text-transform: uppercase;
  background-color: #ff712e;
  margin-bottom: 10px;
  font-size: 15px;
}

.product-list {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.product {
  cursor: pointer;
  background-color: #f2f2f2;
  border-style: none;
  border-width: 1px;
  border-radius: 5px;
  flex-flow: column;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
  padding: 10px;
}

.product.first {
  border-top: 1px solid #d1d1d1;
}

.product.expedition-order {
  margin-top: 40px;
}

.product-img-wrapper {
  flex-direction: column;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  margin-right: 10px;
  display: flex;
}

.product-img {
  max-width: 100px;
}

.product-description {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  display: flex;
}

.product-title-wrapper {
  flex-flow: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.product-brand {
  color: #303030;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: center;
  margin-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  line-height: 25px;
  display: none;
  position: static;
}

.product-brand.main-view {
  padding-top: 1px;
  display: block;
}

.product-label {
  font-size: 16px;
}

.product-details {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
  display: flex;
}

.product-details-line {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.product-details-key {
  width: 50px;
  margin-right: 5px;
  font-weight: 500;
}

.product-details-value.monop-usage {
  font-style: italic;
}

.bo-product-ean {
  color: #fd323d;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.product-list-wrapper {
  background-color: #fff;
  border: 1px #000;
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
  display: flex;
}

.product-list-wrapper.hidden {
  display: none;
}

.product-list-shelf {
  padding-left: 30px;
}

.products {
  padding-left: 20px;
  padding-right: 20px;
}

.items-tabs {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.items-tab {
  text-align: center;
  cursor: pointer;
  background-color: rgba(209, 209, 209, .4);
  border-radius: 10px;
  width: 200px;
  padding: 10px;
  font-size: 18px;
}

.items-tab.active {
  color: #fff;
  background-color: #e8522d;
}

.product-image {
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

.product-image.preparation-product {
  padding-top: 40px;
}

.image-3 {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.product-details-description {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.product-details-title {
  margin-bottom: 10px;
  padding: 10px;
}

.product-name {
  flex: 0 auto;
  align-items: center;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  display: flex;
}

.product-attributes {
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.product-attributes-line {
  justify-content: flex-start;
  align-items: center;
  margin-right: 35px;
  display: flex;
}

.product-attributes-key {
  text-transform: uppercase;
  width: 200px;
  font-weight: 500;
}

.product-attributes-value {
  text-align: right;
  flex: 1;
}

.product-details-ean {
  color: #e8522d;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.report-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.report-title-wrapper {
  flex-direction: column;
  align-items: stretch;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

.report-list {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.report-item {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  display: flex;
}

.report-icon {
  color: #fd323d;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding-right: 10px;
  font-family: Materialicons, sans-serif;
  font-size: 30px;
  display: flex;
}

.report-content {
  flex-flow: column wrap;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.report-content-title {
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  font-size: 18px;
  display: flex;
}

.report-content-title-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.report-qty {
  text-align: right;
  flex: 1;
  align-items: center;
  font-size: 18px;
  display: block;
}

.report-clear {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #e8522d;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-family: Materialicons, sans-serif;
  font-size: 30px;
  display: flex;
}

.div-block-3 {
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  display: flex;
}

.report-subtitle {
  font-weight: 200;
}

.product-list-indication {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  font-size: 20px;
  display: flex;
}

.product-list-overlay-wrapper {
  z-index: 10;
  background-color: #f2f2f2;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.product-list-overlay {
  flex-flow: column;
  justify-content: flex-start;
  height: 100%;
  display: flex;
  overflow: auto;
}

.product-list-overlay.hidden {
  display: none;
}

.product-list-overlay-indication {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 60px 10px;
  font-size: 20px;
  display: flex;
}

.error-list {
  list-style-type: disc;
}

.error-list.batch-modal {
  max-height: 40vh;
  overflow: auto;
}

.div-block-4 {
  text-align: center;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: block;
}

.item-remaining-count {
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding-right: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  display: flex;
}

.report-type {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.checkbox-wrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.checked {
  color: #707070;
  font-family: Materialicons, sans-serif;
  font-size: 30px;
}

.unchecked, .check {
  font-family: Materialicons, sans-serif;
  font-size: 30px;
}

.report-label {
  padding-left: 10px;
}

.close-overlay {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-family: Materialicons, sans-serif;
  font-size: 50px;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
}

.text-block-13 {
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 50px;
  display: flex;
}

.collect-report {
  justify-content: center;
  align-items: center;
  display: flex;
}

.report-title-subtitle {
  padding: 5px;
  font-size: 18px;
}

.order-dispatch {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.dispatch-title {
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  font-size: 20px;
  display: flex;
}

.dispatch-indication {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.div-block-5 {
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  display: flex;
}

.text-block-14 {
  display: flex;
}

.dispatch-codebar {
  color: #333;
  justify-content: center;
  align-items: center;
  height: 120px;
  font-family: "Idautomationhc39m code 39 barcode", sans-serif;
  font-size: 30px;
  display: flex;
}

.text-block-16 {
  color: #ee9a85;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: Materialicons, sans-serif;
  font-size: 90px;
  display: flex;
}

.close-dispatch {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  font-family: Materialicons, sans-serif;
  font-size: 70px;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
}

.scanner-wrapper {
  justify-content: center;
  display: flex;
}

.text-block-17 {
  color: #ff3200;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dispatch-product-overlay-wrapper {
  z-index: 10;
  background-color: #fff;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.dispatch-product-overlay-wrapper.hidden {
  display: none;
}

.dispatch-product-overlay {
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  display: flex;
}

.overlay-wrapper {
  z-index: 10;
  background-color: #f2f2f2;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.overlay-wrapper.hidden {
  display: none;
}

.snack-wrapper {
  width: 70%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  transition: top .3s cubic-bezier(.55, .085, .68, .53);
  position: relative;
  top: 0;
}

.snack-wrapper.snack-hidden {
  z-index: 4;
  height: 60px;
  transition: top .2s;
  display: none;
  top: -60px;
  overflow: hidden;
}

.snack {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  box-shadow: 0 1px 10px rgba(51, 51, 51, .5);
}

.snack.success {
  background-color: #65ca8e;
}

.snack.error {
  background-color: #ee9a85;
}

.snack.info {
  background-color: #227092;
}

.snack-text {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.overlay-snack-wrapper {
  width: 70%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  transition: top .5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.overlay-snack-wrapper.overlay-snack-wrapper-hidden {
  height: 60px;
  transition: top .2s cubic-bezier(.55, .085, .68, .53);
  top: -80px;
}

.overlay-snack {
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 1px 10px rgba(51, 51, 51, .5);
}

.overlay-snack.error {
  background-color: #ee9a85;
}

.overlay-snack.success {
  background-color: #65ca8e;
}

.overlay-snack.info {
  background-color: #227092;
}

.text-block-19 {
  font-size: 20px;
}

.div-block-6 {
  justify-content: center;
  align-items: center;
  height: 500px;
  display: flex;
}

.div-block-7 {
  background-color: rgba(0, 0, 0, 0);
  width: 400px;
  box-shadow: 0 1px 3px #000;
}

.modal-message {
  text-align: center;
  font-size: 16px;
}

.div-block-8.commandlistitem {
  background-color: rgba(216, 216, 216, .43);
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 10px;
}

.collectscontainer {
  padding: 10px;
}

.preparation-form-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.prepscontainer {
  padding-left: 10px;
  padding-right: 10px;
}

.pagination-wrapper {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.pagination {
  justify-content: center;
  display: flex;
}

.pagination-number {
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  font-size: 26px;
  display: flex;
}

.pagination-arrow {
  color: #fff;
  cursor: pointer;
  background-color: #e8522d;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  display: flex;
}

.pagination-arrow.right {
  border-radius: 0 7px 7px 0;
  width: 80px;
  text-decoration: none;
}

.pagination-arrow.disabled {
  cursor: default;
  background-color: rgba(232, 82, 45, .4);
}

.pagination-arrow.left {
  width: 80px;
  text-decoration: none;
}

.product-actions {
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.product-actions.hidden {
  display: none;
}

.product-remove {
  color: #333;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  font-family: Fontawesome, sans-serif;
  font-size: 40px;
  text-decoration: none;
  display: flex;
}

.product-remove.hidden {
  display: none;
}

.package-info {
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
}

.package-info.package-done {
  align-items: center;
  padding-top: 40px;
}

.package-info-title {
  font-size: 24px;
  font-weight: 700;
}

.package-info-indication {
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  display: flex;
}

.package-info-spec {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.package-info-spec-item {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  display: flex;
}

.package-info-spec-label {
  width: 300px;
  padding-right: 20px;
}

.package-info-spec-value {
  align-items: center;
  min-width: 100px;
  padding-left: 20px;
  font-weight: 700;
  display: flex;
}

.package-weight-input {
  text-align: center;
  width: 60px;
  margin-bottom: 0;
}

.form-block-4 {
  margin-bottom: 0;
}

.form-3 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.package-weight-indication {
  margin-bottom: 20px;
  font-weight: 400;
}

.package-info-total-product, .package-total-weigth {
  text-align: left;
  width: 100%;
  padding-left: 20px;
}

.text-block-22 {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
}

.text-block-23 {
  padding-left: 10px;
  padding-right: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 28px;
}

.products-hint {
  text-align: center;
  padding: 20px 10px;
}

.scanner-download-link {
  color: #fd323d;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.user-hint {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.product-id-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.dispatch-indication-order {
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 24px;
  display: flex;
}

.bold {
  font-size: 30px;
  font-weight: 700;
}

.package-bulky {
  width: 100%;
  padding-left: 20px;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.bo-tabs {
  border-bottom: 5px solid #ff7b82;
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.tab-name {
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 700;
}

.text-block-24 {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.bo-action-button {
  color: #fff;
  text-align: center;
  background-color: #fd323d;
  border-radius: 0;
  width: 60%;
  margin: 10px auto 20px;
  padding: 16px;
  font-size: 25px;
  font-weight: 700;
  display: block;
}

.bo-table-line {
  border: 1px solid #ff7b82;
  height: 41px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.bo-table {
  border: 2px solid #ff7b82;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.bo-table-name {
  margin-bottom: 10px;
  margin-left: 0;
  padding-left: 50px;
  font-size: 22px;
  font-weight: 700;
}

.div-block-11 {
  flex: 1;
}

.text-block-25 {
  text-align: center;
  padding-top: 0;
  font-size: 19px;
  font-weight: 700;
}

.page-title {
  color: #fff;
}

.div-block-12 {
  background-color: #227092;
}

.commande-info {
  flex: 0 auto;
}

.div-block-13 {
  background-color: rgba(32, 167, 87, .17);
  display: block;
}

.body-2 {
  min-height: 100%;
}

.div-block-14 {
  width: 100%;
}

.order-info-wrapper {
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.text-block-26 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: block;
}

.command-number.content-title {
  color: #303030;
}

.pck-btn {
  color: #fff;
  border-radius: 10px;
  flex: 0 auto;
  padding: 16px;
  font-weight: 700;
  display: block;
}

.pck-btn.huge-btn {
  background-color: #fd323d;
  width: 80%;
}

.pck-btn.huge-btn.incdec-product-btn {
  min-width: 70px;
  margin-left: 10px;
  margin-right: 10px;
}

.pck-btn.huge-btn.incdec-product-btn.increment-product-btn, .pck-btn.huge-btn.incdec-product-btn.decrement-product-btn {
  font-size: 40px;
}

.pck-btn.huge-btn.end-of-return-btn {
  position: fixed;
  bottom: 5px;
}

.pck-btn.btn-product.btn-disabled {
  background-color: #dedede;
}

.pck-btn.small {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  padding: 0 15px;
}

.pck-btn.small.more-info-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.pck-btn.small.more-info-btn.active {
  background-color: #dedede;
}

.pck-btn.refresh-btn {
  background-color: #227092;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 70px;
  right: 15px;
}

.pck-btn.create-order-btn {
  background-color: #20a757;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 0;
  display: flex;
  position: fixed;
  top: 70px;
}

.pck-btn.print-labels-btn {
  background-color: #ff712e;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin: 0;
  padding: 10px 0;
  display: flex;
  position: relative;
  left: 0;
}

.pck-btn.collect-btn {
  padding: 0;
}

.pck-btn.collect-btn.vue-no-scanette-btn {
  background-color: #20a757;
}

.pck-btn.collect-btn.vue-scanette-btn {
  background-color: #227092;
  margin-right: 13px;
}

.pck-btn.pause-btn {
  background-color: #b20000;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 70px;
  right: 15px;
}

.pck-btn.resume-btn {
  background-color: #20a757;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 70px;
  right: 15px;
}

.pck-btn.stop-button {
  background-color: #b20000;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 70px;
  right: 85px;
}

.pck-btn.resume-stop {
  background-color: #20a757;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 70px;
  right: 85px;
}

.pck-btn.print-bon-degrade {
  background-color: #ffb300;
  border-radius: 4px;
  width: 200px;
  margin-left: 10px;
  padding: 10px 0;
  position: relative;
}

.pck-btn-text {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

.pck-btn-text.huge-btn {
  width: 100%;
}

.pck-btn-text.btn-disabled {
  color: #6d6969;
}

.pck-btn-text.more-info-btn, .pck-btn-text.more-info-icon {
  font-family: Fontawesome, sans-serif;
  line-height: 40px;
}

.pck-btn-text.refresh-btn-txt {
  font-family: Materialicons, sans-serif;
  font-size: 20px;
  line-height: 20px;
}

.pck-btn-text.new-order-btn-txt {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
}

.pck-btn-text.collect-btn-text {
  text-transform: capitalize;
  padding: 10px;
  line-height: 14px;
  display: flex;
}

.pck-btn-text.collect-btn-text.vue-scanette-btn {
  margin-right: 0;
}

.pck-btn-text.collect-btn-text.vue-no-scanette-btn {
  margin-left: 0;
}

.pck-btn-text.resume-text {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 22px;
  line-height: 0;
}

.pck-btn-text.pause-text {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  line-height: 0;
}

.pck-btn-text.stop-button-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 0;
}

.div-block-16 {
  height: 0;
}

.border-hack {
  border-bottom: 1px solid #ff7b82;
  width: 100%;
  height: 0;
}

.heading {
  color: #fd323d;
  text-align: center;
  font-weight: 400;
}

.content-title-wrapper {
  text-align: center;
  flex: 0 auto;
  padding-top: 25px;
  padding-bottom: 5px;
  position: static;
}

.form-wrapper, .text-field-3 {
  margin-bottom: 0;
}

.div-block-17 {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.div-block-18 {
  flex-direction: column;
  display: flex;
}

.content-wrapper {
  flex: 0 auto;
}

.content-wrapper.missions-collecte {
  border: 1px solid #000;
  flex-flow: wrap;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 20px;
  display: flex;
}

.content-wrapper.liste-mission-wrapper {
  flex-direction: column;
  padding-bottom: 20px;
  display: flex;
}

.content-wrapper.order-count-wrapper {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.heading-2 {
  color: #303030;
}

.bac-list-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 10px;
  display: flex;
}

.bac-list {
  flex-direction: row;
  flex: 0 auto;
  justify-content: space-between;
  display: flex;
}

.bac {
  flex: none;
  justify-content: space-around;
  align-items: baseline;
  padding: 5px;
  display: flex;
}

.bac-id {
  margin-right: 20px;
}

.form-block-5 {
  width: 180px;
  margin-bottom: 0;
  margin-right: 20px;
}

.text-block-27 {
  font-size: 25px;
}

.mpx-pck-btn-text {
  text-align: center;
  font-size: 25px;
}

.heading-3 {
  color: #fd323d;
  text-align: center;
  text-transform: none;
  font-weight: 500;
}

.heading-4 {
  text-align: center;
}

.content-title {
  color: #303030;
  text-align: center;
  margin-right: 5px;
  font-size: 38px;
  font-weight: 300;
  line-height: 40px;
  display: inline;
}

.bac-type {
  margin-bottom: 0;
  padding-left: 1px;
}

.image-4 {
  display: inline-block;
}

.bo-product-description {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.bo-product {
  cursor: pointer;
  border: 1px #ff7b82;
  border-top-color: #a3040e;
  flex-direction: row;
  flex: none;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
}

.bo-product.first {
  border-top: 1px solid #d1d1d1;
}

.bo-product.bo-wrapper-grey {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.bo-product-brand {
  color: #303030;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  display: block;
}

.bo-product-label {
  flex: 0 auto;
  margin-left: 5px;
  font-size: 16px;
}

.bo-product-list {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.bo-product-list-wrapper {
  background-color: #fff;
  border: 1px #000;
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.bo-product-list-wrapper.hidden {
  display: none;
}

.bo-product-img {
  max-width: 100px;
}

.bo-bac-list-wrapper {
  flex: none;
}

.bo-bac-list-wrapper.bo-product {
  flex-flow: column wrap;
}

.bo-bac-list {
  flex-direction: row;
  flex: 0 auto;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.bo-bac-id {
  margin-right: 20px;
}

.bo-bac {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

.bo-btn {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 10px;
  flex: 0 auto;
  margin-right: 10px;
  font-weight: 700;
}

.bo-btn:hover {
  background-color: rgba(250, 53, 67, .9);
}

.bo-btn.huge-btn {
  width: 80%;
}

.bo-btn.substitution-btn {
  cursor: pointer;
  background-color: #ff712e;
  justify-content: center;
  align-items: center;
  width: 85px;
  margin-right: 0;
  padding: 10px 5px;
  display: flex;
}

.bo-btn.substitution-btn.disabled {
  cursor: default;
  background-color: rgba(255, 113, 46, .6);
}

.bo-btn.missing-btn {
  cursor: pointer;
  background-color: #fa3543;
  justify-content: center;
  align-items: center;
  width: 85px;
  margin-right: 0;
  padding: 10px 5px;
  display: flex;
}

.bo-btn.missing-btn.disabled {
  cursor: default;
  background-color: rgba(246, 26, 40, .6);
}

.bo-btn.remove-bac-btn {
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-family: Fontawesome, sans-serif;
  display: flex;
}

.bo-btn.add-bac-btn {
  cursor: pointer;
  background-color: #65ca8e;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 0;
  font-family: Fontawesome, sans-serif;
  display: flex;
}

.bo-btn.add-bac-btn:hover {
  background-color: rgba(101, 202, 142, .9);
}

.bo-btn.add-comment-bt {
  cursor: pointer;
  background-color: #e8902d;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font-family: Fontawesome webfont, sans-serif;
  display: flex;
}

.bo-btn.add-comment-bt:hover {
  background-color: rgba(232, 144, 45, .9);
}

.bo-btn.del-substitution {
  background-color: #a3040e;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 5px;
  padding: 13px;
  display: flex;
}

.bo-btn.del-missing {
  background-color: #a3040e;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 5px;
  padding: 13px;
  display: flex;
}

.bo-btn.add-substitution, .bo-btn.add-missing {
  background-color: #20a757;
  margin-left: 5px;
  margin-right: 0;
  padding: 13px;
}

.bo-btn.del-substitution2 {
  background-color: #ff712e;
  border-style: none;
  border-left-width: 1px;
  border-left-color: #e8902d;
  border-right-width: 3px;
  border-right-color: #e8902d;
  border-radius: 5px 0 0 5px;
  margin-right: 0;
}

.bo-btn.add-substitution2 {
  background-color: #ff712e;
  border-style: none;
  border-left-width: 3px;
  border-left-color: #e8902d;
  border-radius: 0 5px 5px 0;
  margin-right: 0;
}

.bo-btn.substitution-btn2 {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px #000;
  border-bottom: 1px #000;
  border-radius: 0;
  margin-right: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  box-shadow: inset 0 0 3px #000;
}

.bo-btn.add-missing2 {
  border-radius: 0 5px 5px 0;
  margin-right: 0;
}

.bo-btn.del-missing2 {
  border-radius: 5px 0 0 5px;
  margin-right: 0;
}

.bo-btn.missing-number, .bo-btn.substitution-number {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  width: 100px;
  margin-right: 0;
  box-shadow: inset 0 0 4px #000;
}

.bo-btn.del-collected {
  background-color: #00bf5d;
  border-style: none;
  border-left-width: 1px;
  border-left-color: #e8902d;
  border-right-width: 3px;
  border-right-color: #e8902d;
  border-radius: 5px 0 0 5px;
  margin-right: 0;
}

.bo-btn.add-collected {
  background-color: #00bf5d;
  border-style: none;
  border-left-width: 3px;
  border-left-color: #e8902d;
  border-radius: 0 5px 5px 0;
  margin-right: 0;
}

.bo-btn.collected-number {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  width: 100px;
  margin-right: 0;
  box-shadow: inset 0 0 4px #000;
}

.bo-btn.remove-ean-btn {
  cursor: pointer;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 0;
  font-family: Fontawesome, sans-serif;
  display: flex;
}

.bo-btn.bo-counter-btn {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-right-style: none;
  border-radius: 5px 0 0 5px;
  width: 80px;
  margin-right: 0;
  padding: 10px;
}

.bo-btn.bo-counter-btn.missing-counter {
  border-left-style: none;
  border-radius: 0;
}

.bo-btn.bo-counter-btn-action {
  border-style: none;
  border-left-width: 3px;
  border-left-color: #e8902d;
  border-radius: 0 5px 5px 0;
  margin-right: 0;
}

.bo-btn.bo-counter-btn-action.add-sub-btn {
  background-color: #e8902d;
  border: 1px solid #e8902d;
}

.bo-btn.bo-counter-btn-action.add-sub-btn:hover {
  background-color: rgba(232, 144, 45, .9);
  border-color: rgba(232, 144, 45, .9);
}

.bo-btn.bo-counter-btn-action.add-sub-btn.disabled-add-sub-btn {
  background-color: #adadad;
  border-color: #adadad;
  border-radius: 0 5px 5px 0;
}

.bo-btn.bo-counter-btn-action.add-pv-btn {
  border: 1px solid var(--mpx-blue);
  background-color: var(--mpx-blue);
}

.bo-btn.bo-counter-btn-action.add-pv-btn:hover {
  background-color: rgba(76, 175, 177, .9);
  border-color: rgba(76, 175, 177, .9);
}

.bo-btn.btn-missing {
  border: 1px solid #fd323d;
  border-radius: 0;
  margin-right: 0;
}

.bo-btn.btn-missing.del-missing-btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.bo-btn.btn-missing.add-missing-btn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bo-btn-text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 25px;
  font-weight: 300;
  line-height: 20px;
}

.bo-btn-text.substitution-btn-text {
  color: #000;
  justify-content: center;
  align-items: center;
  width: 30px;
  font-family: Materialicons, sans-serif;
  font-size: 35px;
  font-weight: 400;
  display: flex;
}

.bo-btn-text.missing-btn-text {
  justify-content: center;
  font-family: Fontawesome, sans-serif;
  display: flex;
}

.bo-btn-text.remove-bac-btn-text {
  font-size: 15px;
  line-height: 30px;
}

.bo-btn-text.substitution-btn-qty {
  color: #000;
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
}

.bo-btn-text.add-comment {
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
}

.bo-btn-text.del-button-text {
  color: #fff;
  padding: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 14px;
}

.bo-btn-text.missing-qty {
  font-weight: 400;
}

.bo-btn-text.collected-btn-qty {
  color: #000;
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
}

.bo-btn-text.remove-ean-btn-text {
  font-size: 15px;
  line-height: 25px;
}

.bo-btn-text.counter-btn {
  color: #000;
  font-weight: 400;
}

.bo-btn-text.action-button-text {
  color: #fff;
  padding: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.bo-btn-text.action-button-text.missing-btn-text {
  font-size: 20px;
}

.bo-btn-text.missing-btn-qty {
  color: #000;
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
}

.bo-wrapper-grey {
  background-color: #f2f2f2;
  border-radius: 5px;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
}

.bo-wrapper-grey.bo-bac-list {
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
}

.bo-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.bo-wrapper.bo-bac-list-wrapper {
  margin-top: 10px;
}

.bo-content-title {
  color: #303030;
  text-align: center;
  font-weight: 400;
}

.login-input {
  border-radius: 5px;
}

.login-input.email-input, .login-input.password-input {
  max-width: 100%;
}

.login-form {
  border: 1px solid #999;
  border-radius: 10px;
  flex-flow: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px;
  padding: 10px;
  display: flex;
}

.pck-content-container {
  background-color: #fff;
  border: 1px #000;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  padding: 20px;
  display: flex;
}

.pck-content-container.hidden {
  display: none;
}

.pck-content-container.product-list-wrapper {
  flex-wrap: nowrap;
  overflow: auto;
}

.pck-content-container.order-info-wrapper {
  align-items: stretch;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pck-content-container.item-image {
  flex: 0 auto;
  align-items: center;
}

.pck-content-container.product-picking-report {
  flex: 0 auto;
}

.pck-content-container.item-description-wrapper {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.liste-mission-header {
  flex-flow: wrap;
  flex: 0 auto;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 10px;
  display: flex;
}

.header-collecte-2 {
  width: 35%;
}

.header-collecte-1 {
  width: 25%;
}

.header-expedition-3, .text-block-51 {
  width: 20%;
}

.mission-id {
  justify-content: center;
  align-items: center;
}

.div-block-19 {
  width: 25%;
  padding-right: 5px;
  display: block;
}

.div-block-20 {
  width: 35%;
  padding-right: 5px;
}

.div-block-21, .div-block-22 {
  width: 20%;
  padding-right: 5px;
}

.white {
  color: #f2f2f2;
}

.header-collecte-4 {
  width: 20%;
}

.header-expedition-1 {
  width: 15%;
}

.header-expedition-4 {
  width: 20%;
}

.header-expedition-5 {
  width: 15%;
}

.btn-wrapper {
  flex-flow: column-reverse;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.btn-wrapper.btn-products-wrapper {
  justify-content: space-around;
}

.btn-wrapper.bo-action-btn-wrapper {
  flex: 0 auto;
  justify-content: center;
  width: auto;
  display: flex;
}

.div-block-23 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pck-logo-container {
  flex: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  display: flex;
}

.header-expedition-2 {
  width: 30%;
}

.header-collecte-3 {
  width: 20%;
}

.mission-expedition {
  border: 1px solid #000;
  flex-wrap: nowrap;
  flex: 0 auto;
  align-items: center;
  padding: 5px 20px;
  display: flex;
}

.div-block-25 {
  width: 15%;
  padding-right: 5px;
}

.div-block-26 {
  width: 30%;
  padding-right: 5px;
}

.div-block-27, .div-block-28 {
  width: 20%;
  padding-right: 5px;
}

.div-block-29 {
  width: 15%;
  padding-right: 5px;
}

.div-block-30 {
  padding-right: 5px;
}

.mission-collecte-wrapper {
  flex-flow: column;
  flex: 0 auto;
  align-content: space-around;
  justify-content: center;
  display: flex;
}

.mission-expedition-wrapper, .mission-localisation-wrapper {
  flex-flow: column;
  flex: 0 auto;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.div-block-31 {
  background-color: rgba(163, 4, 14, .8);
  border: 1px solid #a3040e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.header-retour-1 {
  width: 25%;
}

.header-retour-2 {
  width: 35%;
}

.header-retour-3, .header-retour-4 {
  width: 20%;
}

.mission-retour {
  border: 1px solid #000;
  padding: 5px 20px;
  display: flex;
}

.div-block-33 {
  width: 25%;
}

.div-block-34 {
  width: 35%;
}

.div-block-35, .div-block-36 {
  width: 20%;
}

.product-ean {
  color: #fd323d;
  flex: 0 auto;
  width: 160px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.product-details-wrapper {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 0;
  display: flex;
}

.product-picking-zone {
  margin-left: 0;
  margin-right: 10px;
}

.order-summary {
  border: 1px solid #999;
  border-radius: 10px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  padding: 10px;
  display: flex;
  box-shadow: 1px 1px 4px -1px #000;
}

.order-summary-line {
  flex: 0 auto;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
}

.order-summary-key {
  width: 300px;
  margin-right: 20px;
  font-weight: 700;
}

.div-block-37 {
  flex-direction: row;
  display: flex;
}

.tab-title {
  color: #999;
  flex: 1;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  display: flex;
}

.tab-title:hover {
  color: #6c6c6c;
  background-color: rgba(34, 112, 146, .1);
}

.tab-title.tab-selected {
  color: #303030;
  flex: 1;
  margin-left: 10px;
}

.tab-title.tab-selected.retour-title, .tab-title.tab-selected.collect-title, .tab-title.retour-title, .tab-title.collect-title {
  flex: 0 auto;
}

.tab-title.collect-title.collect-title-left.tab-selected, .tab-title.collect-title.collect-title-done {
  cursor: pointer;
}

.tab-title.missions-tab-selected {
  color: #fff;
  background-color: #227092;
}

.tab-title.collect-header.missions-tab-selected {
  flex: 1;
}

.tab-wrapper {
  justify-content: space-around;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.header-title {
  color: #303030;
  text-transform: uppercase;
  border-bottom: 4px solid #a3040e;
  flex: 1;
  padding: 10px 20px;
  font-weight: 700;
}

.table-line {
  border: 1px solid #dedede;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
  margin-bottom: 0;
  padding: 10px 20px;
  display: flex;
}

.table-line.impair {
  background-color: #f2f2f2;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-line.pair {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-line.mission-cell.pair {
  justify-content: space-around;
}

.table-line.table-cell {
  min-height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.table-line.grid-header {
  background-color: #e8902d;
  border-style: none;
  border-color: #dedede;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
}

.mission-cell {
  color: #303030;
  flex: 1;
  min-height: 100%;
  padding: 10px 20px;
}

.mission-header {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.div-block-38 {
  max-height: 100%;
  box-shadow: 0 0 4px 1px #dedede;
}

.div-block-39 {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.order-count-name {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 300;
}

.div-block-40 {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  display: block;
}

.checkbox-field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.form-4 {
  display: block;
}

.form-block-6 {
  flex: 1;
}

.order-dashboard-wrapper-fake {
  margin-bottom: 30px;
  padding: 20px;
}

.order-dashboard-list {
  margin-bottom: 20px;
}

.order-count-line {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.order-count-wrapper {
  color: #f2f2f2;
  text-align: center;
  background-color: #999;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100px;
  padding: 10px;
  display: flex;
  box-shadow: 0 0 2px #303030;
}

.order-count {
  font-size: 32px;
  font-weight: 300;
  line-height: 32px;
}

.checkbox-field-2 {
  margin-bottom: 0;
}

.table-cell {
  text-align: center;
  flex: 1;
  height: 100%;
  padding-left: 0;
}

.table-cell.no-expand {
  flex: 0 auto;
  padding-right: 0;
  display: block;
}

.table-cell.number-cell {
  flex: none;
}

.table-cell.mission-picker-name {
  justify-content: center;
  display: flex;
}

.table-cell.calendar-tab-cell {
  justify-content: center;
  height: 100%;
  display: flex;
}

.div-block-41 {
  font-family: Fontawesome, sans-serif;
}

.checkbox-icon {
  cursor: pointer;
  font-family: Fontawesome, sans-serif;
}

.checkbox-icon.checkbox-ok {
  font-family: Fontawesome, sans-serif;
}

.checkbox-icon.checkbox-ok.disabled {
  color: rgba(48, 48, 48, .5);
  cursor: not-allowed;
}

.checkbox-icon.checkbox-ko {
  color: #333;
}

.checkbox-icon.checkbox-ko:hover {
  color: rgba(51, 51, 51, .8);
}

.checkbox-icon.checkbox-ko.disabled {
  color: rgba(51, 51, 51, .5);
  cursor: default;
}

.checkbox-icon.diabled {
  color: rgba(48, 48, 48, .5);
}

.div-block-42 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.product-label-wrapper {
  margin-right: 10px;
}

.product-info-wrapper {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.product-image {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  box-shadow: 0 0 6px #303030;
}

.item-description {
  display: flex;
}

.product-title {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 0;
  display: flex;
}

.product-title.expedition-product {
  justify-content: flex-start;
}

.div-block-43 {
  text-align: left;
  flex-direction: column;
  flex: 1;
}

.scan-label-title {
  color: #303030;
  text-align: center;
  font-weight: 300;
}

.report-title {
  border-left: 5px solid #fa3543;
  max-width: 60%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 9px;
  font-size: 36px;
  font-weight: 400;
}

.report-content-subtitle {
  flex-wrap: wrap;
  margin-right: 5px;
  display: inline;
}

.report-content-subtitle.item-substitutions-list {
  padding-left: 20px;
}

.div-block-44 {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.dispatch-comments {
  width: 80%;
}

.div-block-46 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-52 {
  line-height: 30px;
}

.bo-product-title-wrapper {
  flex-flow: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.text-block-53, .items-collected-count, .items-remaining-count, .text-block-54 {
  display: inline;
}

.text-block-55 {
  margin-right: 5px;
  display: inline;
}

.text-block-56 {
  display: inline;
}

.items-collected-count-title, .items-remaining-count-title {
  justify-content: center;
  margin-left: 5px;
  display: flex;
}

.item-count-wrapper {
  text-align: center;
  flex: 0 auto;
  width: 110px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
}

.text-block-57 {
  display: inline;
}

.item-collected-count {
  flex: 0 auto;
  display: inline;
}

.proud, .text-block-59, .text-block-60 {
  display: inline;
}

.items-collected-text {
  margin-left: 5px;
  display: inline;
}

.item-total-count {
  flex: 0 auto;
  display: inline;
}

.substituted-item-ean {
  display: inline;
}

.text-block-61 {
  margin-right: 5px;
  display: inline;
}

.div-block-47 {
  justify-content: space-around;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.max-qty {
  flex: 0 auto;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 16px;
}

.dev-btn-container {
  flex-direction: row-reverse;
  justify-content: flex-start;
  display: flex;
  position: static;
}

.bo-bac-container {
  flex-wrap: wrap;
  flex: 1;
  align-content: stretch;
  justify-content: flex-start;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.collect-mission-title {
  color: #b33520;
  text-transform: capitalize;
  font-weight: 700;
}

.collect-mission-name {
  margin-left: 10px;
  font-weight: 500;
  display: block;
}

.div-block-49 {
  display: flex;
}

.div-block-50 {
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
  line-height: 30px;
  position: fixed;
  top: 107px;
  left: 535px;
}

.div-block-51 {
  background-color: #f2f2f2;
  display: flex;
}

.dispatch-mission {
  background-color: #f2f2f2;
  flex: 0 auto;
  width: 100%;
  display: flex;
}

.text-block-62 {
  text-transform: capitalize;
}

.mission-title {
  text-align: left;
  text-transform: capitalize;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  display: flex;
}

.bac-list-expedition-wrapper {
  margin: 0;
  padding: 5px;
}

.bac-number {
  clear: none;
  color: #fd323d;
  text-align: center;
  text-transform: none;
  flex: none;
  font-weight: 500;
}

.expetition-mission-header {
  flex: 1;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.bac-list-expedition {
  flex-flow: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  display: flex;
}

.bac-number-expedition-text {
  color: #f2f2f2;
  font-size: 17px;
}

.bac-number-expedition {
  color: #fff;
  background-color: #006b92;
  border-radius: 10px;
  flex: none;
  justify-content: center;
  width: 99%;
  margin: 10px;
  padding: 10px;
  display: flex;
}

.div-block-52 {
  justify-content: space-between;
  margin-right: 0;
  display: flex;
}

.mission-button-wrapper {
  justify-content: space-between;
  display: flex;
}

.div-block-53, .detail-container {
  flex: 1;
}

.order-list {
  color: #333;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0;
  display: flex;
}

.text-block-63 {
  clear: none;
  text-align: left;
}

.text-block-64, .text-block-65 {
  text-align: left;
}

.order-name-in-list {
  text-align: left;
  flex: 1;
  justify-content: flex-start;
  margin-left: 5px;
  margin-right: 0;
  padding-bottom: 2px;
  padding-right: 0;
  display: flex;
}

.order-pin {
  padding-left: 0;
  font-family: Fontawesome, sans-serif;
  font-size: 14px;
  display: flex;
}

.order-block {
  justify-content: space-between;
  margin-left: 10px;
  display: flex;
}

.order-number-cell {
  text-align: left;
}

.product-number {
  margin-bottom: 0;
  margin-left: 0;
  font-weight: 400;
}

.order-link-list {
  justify-content: center;
  display: flex;
}

.div-block-55 {
  flex: none;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.top-button-wrapper {
  justify-content: flex-start;
  display: flex;
  position: fixed;
  top: 75px;
  left: 15px;
}

.searching-input-div {
  flex-flow: wrap;
  display: flex;
}

.bold-text {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.interaction-button-container {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.substitution-product {
  clear: none;
  color: #ff712e;
  flex: 1;
  justify-content: flex-end;
  margin-left: 0;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  display: flex;
  position: relative;
  overflow: visible;
}

.substitution-product.degrade {
  margin-left: 10px;
}

.substitution-product.main-view {
  color: #ff712e;
  flex: 0 auto;
  display: flex;
}

.substitution-product.main-view.orange {
  color: #e8902d;
  font-weight: 700;
}

.substitutionwrap {
  flex: 0 auto;
  justify-content: flex-end;
  display: flex;
}

.dispo-date {
  margin-left: 10px;
  font-weight: 500;
}

.comment-mission {
  margin: 0 30px;
}

.panel-line {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 0;
  display: flex;
  box-shadow: 1px 1px 7px #000;
}

.textinputcartomenu {
  color: #fff;
}

.btn-main-action-carto {
  cursor: pointer;
  background-color: #3898ec;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 0;
  margin-right: 15px;
  padding: 9px 15px;
  display: flex;
}

.btn-main-action-carto:hover {
  background-color: rgba(56, 152, 236, .8);
}

.btn-main-action-carto.btn-change-img-carto {
  width: 450px;
}

.btn-main-action-carto.btn-change-img-carto.disabled {
  cursor: not-allowed;
  background-color: #d1d1d1;
}

.btn-main-action-carto.btn-new-img-carto.disabled {
  cursor: not-allowed;
  background-color: #d1d1d1;
  width: 450px;
}

.btn-main-action-carto.delete-carto.disabled {
  cursor: not-allowed;
  background-color: #d1d1d1;
}

.option-tab {
  text-align: center;
  flex: 0 auto;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
  font-family: Materialicons, sans-serif;
  font-size: 32px;
  line-height: 30px;
  position: static;
}

.panel-action-carto {
  background-color: #ececec;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 13vh;
  margin-top: 0;
  padding-top: 0;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 1px 0 3px #000;
}

.panel-action-carto.paintingmenu {
  flex-direction: column;
  justify-content: flex-start;
}

.tab {
  background-color: #ececec;
  border-style: none;
  border-color: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  padding: 4px 4px 2px;
  position: static;
  bottom: 40px;
}

.tab.paint {
  padding: 4px 4px 2px;
  position: fixed;
  bottom: 13vh;
  left: 45px;
}

.tab.option {
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  position: fixed;
  bottom: 13vh;
}

.tab.question {
  position: fixed;
  bottom: 13vh;
  left: 90px;
}

.fatext {
  color: #fff;
  margin-right: 10px;
  font-family: Fontawesome webfont, sans-serif;
}

.print-tab {
  text-align: center;
  margin-top: 0;
  font-family: Materialicons, sans-serif;
  font-size: 30px;
  line-height: 30px;
  position: static;
}

.question-tab {
  text-align: center;
  margin-top: 0;
  font-family: Materialicons, sans-serif;
  font-size: 20px;
  line-height: 30px;
  position: static;
}

.shapeeditioninput {
  width: 44%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.shapeeditioninput.shaperecheche, .shapeeditioninput.shapemodifier, .shapeeditioninput.shapeajout {
  text-align: center;
  width: 250px;
  margin-right: 20px;
}

.shapeeditioninput.shapecopy {
  text-align: center;
  width: 250px;
}

.shapeeditioninput.shapesupprimer {
  text-align: center;
  width: 250px;
  margin-right: 20px;
}

.field-label {
  margin-bottom: 0;
  margin-left: 5px;
}

.inputsformcontainer {
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
}

.shapeeditioninputdiv {
  flex-flow: wrap;
  flex: auto;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 0;
  display: flex;
}

.input-info-top {
  width: 200px;
  margin-top: 5px;
  margin-right: 2%;
}

.input-info-top.input-ug-shape {
  width: 115px;
  margin-right: 10px;
}

.input-info-top.input-name-shape {
  width: 200px;
  margin-right: 10px;
}

.input-info-top.shape-id {
  width: 100px;
  margin-right: 10px;
}

.formnexdiv {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.shapeinputfielddiv {
  flex-wrap: wrap;
  min-width: 710px;
  display: flex;
}

.checkbox-field-3 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.checkbox-field-3.heritagecheck {
  width: 9%;
  margin-right: 20px;
}

.option-tab-2 {
  text-align: center;
  margin-top: 5px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  line-height: 30px;
  position: static;
}

.formblockshape {
  width: 100%;
  height: 100%;
  margin-left: 5px;
}

.shape-scale {
  width: 40px;
  height: 100%;
  margin-left: 10px;
}

.drawtoolsimages {
  color: #fd323d;
  cursor: pointer;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  margin-left: 4px;
  margin-right: 5px;
  font-family: Materialicons, sans-serif;
  font-size: 40px;
  line-height: 40px;
  display: flex;
}

.drawtoolsimages:hover {
  box-shadow: 0 0 5px #fff;
}

.drawtoolsimages.trashimage {
  margin-left: 0;
  margin-right: 0;
  font-family: Materialicons, sans-serif;
  font-size: 45px;
}

.drawtoolsimages.trashimage.disabled-drawtools {
  color: #999;
  cursor: not-allowed;
  background-color: #fff;
}

.drawtoolsimages.trashimage.disabled-drawtools:hover {
  box-shadow: none;
}

.drawtoolsimages.pen-image {
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-left: 0;
  margin-right: 0;
  font-family: Materialicons, sans-serif;
  font-size: 45px;
  display: flex;
}

.drawtoolsimages.pen-image.tool-selected {
  width: 50px;
  height: 50px;
  margin-bottom: 0;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, .5);
}

.drawtoolsimages.pen-image.tool-selected:hover {
  box-shadow: inset 0 0 8px #000, 0 0 5px #fff;
}

.drawtoolsimages.pen-image.tool-selected.disabled-drawtools {
  color: #999;
  background-color: #fff;
}

.drawtoolsimages.pen-image.disabled-drawtools {
  color: #999;
  cursor: not-allowed;
  background-color: #fff;
}

.drawtoolsimages.pen-image.disabled-drawtools:hover {
  box-shadow: none;
}

.drawtoolsimages.changeimage {
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  font-family: Materialicons, sans-serif;
  font-size: 45px;
  display: flex;
}

.option-tab-3 {
  text-align: center;
  margin-top: 0;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  line-height: 30px;
  position: static;
}

.form-5 {
  flex-flow: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 5px;
  margin-bottom: -10px;
  padding-top: 0;
  display: flex;
}

.inputdrawfield {
  width: 33%;
  height: 40px;
  margin-top: 5px;
  margin-right: 5px;
  display: block;
}

.draw-tools {
  border-right: 1px solid #d3d3d3;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.colordraw {
  background-color: #fff;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 6px;
}

.colordraw.colordrawbotright {
  background-color: purple;
}

.colordraw.colordrawtopmid {
  background-color: green;
}

.colordraw.colordrawtopleft {
  background-color: #0047ff;
  overflow: visible;
}

.colordraw.colordrawtopright {
  background-color: #ff0;
}

.colordraw.colordrawbotleft {
  background-color: gray;
}

.colordraw.colordrawbotmid {
  background-color: red;
}

.drawinputlabel {
  width: 90%;
  height: 100%;
  display: block;
}

.grid-header {
  border: 1px solid #000;
  display: flex;
}

.grid-header.table-header {
  background-color: #fa3543;
  border-style: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 0 6px #fff;
}

.div-block-56 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.image-5 {
  width: 300px;
  height: 300px;
}

.text-block-66 {
  color: #fa3543;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 130px;
  display: flex;
}

.container-overlay {
  flex-direction: column;
  display: flex;
}

.container-overlay.hidden {
  display: none;
}

.container-overlay-indication {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 10px;
  font-size: 20px;
  display: flex;
}

.grid-header-title {
  color: #f2f2f2;
}

.div-block-57 {
  display: flex;
}

.container-overlay-indication-text {
  padding: 10px;
}

.container-overlay-error {
  color: #fa3543;
  padding: 10px;
  font-size: 20px;
}

.div-block-58 {
  flex: 0 auto;
  display: flex;
}

.div-block-58.declared-returned-controls {
  flex-flow: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}

.declared-returned-number {
  color: #fa3543;
  text-align: center;
  flex: 0 auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  font-weight: 700;
}

.div-block-59 {
  display: flex;
}

.text-block-68, .text-block-69 {
  margin-left: 5px;
}

.number-of-declared-returned {
  padding-left: 5px;
  padding-right: 5px;
}

.name-search {
  display: flex;
}

.date-search {
  flex-direction: row;
  display: flex;
}

.status-search {
  display: flex;
}

.shadow-box {
  box-shadow: 0 0 6px #999;
}

.div-block-60 {
  padding-right: 20px;
}

.text-block-70 {
  color: #fa3543;
  margin: 11px;
  font-size: 20px;
  font-weight: 700;
}

.textarea, .textarea-2 {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.print-label-list {
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.print-label {
  justify-content: center;
  width: 50%;
  height: 5.94cm;
  display: flex;
}

.print-label.mission-label, .print-label.tray-label {
  justify-content: flex-start;
  padding-left: .5cm;
  padding-right: .5cm;
}

.print-label-left {
  flex: none;
  justify-content: center;
  align-items: center;
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
}

.print-label-right {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.print-label-qrcode {
  width: 3cm;
  height: 3cm;
}

.print-label-line {
  margin-bottom: 6px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.print-label-line.print-label-header {
  font-size: 24px;
  font-weight: 700;
}

.print-label-line.print-label-orderid {
  font-size: 20px;
  font-weight: 700;
}

.bold-text-2 {
  line-height: 15px;
}

.bo-checkbox {
  float: left;
  font-family: Fontawesome webfont, sans-serif;
}

.form-6 {
  flex-flow: column wrap;
  justify-content: flex-start;
  margin-top: 1px;
  display: flex;
}

.mission-collect-grid {
  min-width: 1000px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.search-icon {
  color: #fff;
  padding-left: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 18px;
  display: inline-block;
}

.row-header {
  border: 1px solid #d1d1d1;
  flex-direction: row;
  display: flex;
  box-shadow: 1px 1px 3px #000;
}

.pagination-div {
  justify-content: flex-start;
  display: flex;
}

.label-form-name {
  flex: none;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  margin-right: 5px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 10px;
  display: flex;
}

.header-titles {
  text-align: center;
  flex: 0 auto;
  justify-content: center;
  margin-right: 0;
  font-size: 18px;
}

.nb-elem-select {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  width: 75px;
  height: 35px;
  margin-bottom: 0;
  margin-right: 5px;
}

.col {
  cursor: default;
  border-style: none solid none none;
  border-width: 0 1px 0 0;
  border-right-color: #dedede;
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: stretch;
  width: 14%;
  min-height: 50px;
  padding: 3px 2px;
  display: flex;
  overflow: hidden;
}

.col.no-expend {
  flex: none;
  align-items: stretch;
  width: 50px;
}

.col.cursor {
  cursor: pointer;
}

.col.cursor.no-expend {
  justify-content: center;
  width: 50px;
  margin-left: 0;
  display: flex;
}

.col.cursor.no-expend.degrade-header, .col.cursor.no-expend.degrade-col {
  width: 120px;
}

.col.cursor.order-name-col.mission-collect-default-cell {
  width: 23%;
}

.col.cursor.mission-collect-default-cell.order-products-col {
  width: 10%;
}

.col.cursor.mission-collect-default-cell.order-status-col {
  width: 14%;
}

.col.cursor.mission-collect-default-cell.order-type-col {
  width: 8%;
}

.col.cursor.mission-expedition-default-cell.order-name-col {
  width: 23%;
}

.col.cursor.missions-all-default-cell.order-status-col {
  width: 10%;
}

.col.cursor.mission-delivery-default-cell.order-name-col, .col.cursor.mission-delivery-default-cell.order-mad-col, .col.cursor.mission-delivery-default-cell.order-name-col {
  width: 13%;
}

.col.cursor.mission-delivery-default-cell {
  flex: none;
}

.col.cursor.mission-delivery-default-cell.order-status-col {
  flex: 1;
  width: 13%;
}

.col.cursor.mission-delivery-default-cell.order-name-col, .col.cursor.mission-delivery-default-cell.order-mad-col, .col.cursor.mission-restock-default-cell.order-status-col, .col.cursor.mission-restock-default-cell.order-name-col, .col.cursor.mission-restock-default-cell.order-mad-col {
  width: 13%;
}

.col.cursor.mission-restock-default-cell {
  flex: none;
}

.col.cursor.mission-restock-default-cell.order-mad-col {
  flex: 1;
}

.col.cursor.mission-restock-default-cell.order-status-col, .col.cursor.mission-restock-default-cell.order-name-col {
  width: 13%;
}

.col.cursor.mission-restock-default-cell.order-mad-col {
  flex: 1;
  width: 13%;
}

.col.cursor.mission-restock-default-cell.order-status-col, .col.cursor.mission-restock-default-cell.order-name-col, .col.cursor.mission-restock-default-cell.order-mad-col {
  width: 13%;
}

.col.col-preparator {
  justify-content: space-between;
}

.col.col-missing-number {
  flex: none;
  width: 8%;
}

.col.col-missing-number.cursor {
  width: 8%;
}

.col.col-missing-number.hidden {
  display: none;
}

.col.col-product-number.mission-collect-default-cell {
  width: 10%;
}

.col.col-bac-number.cursor.mission-expedition-default-cell {
  width: 15%;
}

.col.col-bac-number.cursor.missions-all-default-cell {
  width: 10%;
}

.col.col-bac-number.cursor.mission-delivery-default-cell, .col.col-bac-number.cursor.mission-restock-default-cell {
  width: 13%;
}

.col.col-bac-number.mission-expedition-default-cell {
  width: 15%;
}

.col.col-bac-number.missions-all-default-cell {
  width: 10%;
}

.col.prep-col {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.col.order-name-col {
  flex: none;
  align-items: stretch;
  width: 8%;
}

.col.order-name-col.cursor {
  width: 8%;
}

.col.order-name-col.mission-collect-default-cell {
  align-self: center;
  align-items: stretch;
  width: 23%;
}

.col.order-col-bac {
  flex: none;
}

.col.order-col-products {
  flex: none;
  justify-content: center;
  width: 8%;
}

.col.order-col-price {
  flex: none;
  width: 70px;
}

.col.order-col-price.cursor {
  width: 70px;
}

.col.order-col-user {
  flex-wrap: nowrap;
  flex: none;
  justify-content: center;
  width: 10%;
}

.col.buton-col {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.col.col-phone {
  flex: none;
  width: 7%;
}

.col.col-phone.cursor {
  width: 7%;
}

.col.col-type {
  flex: none;
}

.col.col-mad-date {
  flex: none;
  width: 10%;
}

.col.status {
  flex: 1;
  padding: 0;
  display: flex;
}

.col.mission-collect-default-cell {
  width: 14%;
}

.col.mission-collect-default-cell.order-type {
  width: 8%;
}

.col.mission-expedition-default-cell {
  align-items: center;
  width: 23%;
}

.col.missions-all-default-cell {
  width: 8%;
}

.col.missions-all-default-cell.mission-status {
  width: 10%;
}

.col.col-order-type.cursor.mission-expedition-default-cell, .col.col-order-type.mission-expedition-default-cell {
  width: 8%;
}

.col.col-order-type.cursor.missions-all-default-cell {
  width: 12%;
}

.col.col-order-type {
  flex: none;
  width: 12%;
}

.col.mission-delivery-default-cell {
  width: 13%;
}

.col.mission-delivery-default-cell.mission-state {
  flex: 1;
}

.col.mission-restock-default-cell {
  flex: none;
  width: 13%;
}

.col.mission-restock-default-cell.product-date {
  flex: 1;
}

.col.mission-restock-default-cell {
  width: 13%;
}

.col.col-univers {
  flex: none;
}

.col.col-univers.cursor {
  width: 6%;
}

.col.col-univers {
  flex: none;
  width: 6%;
}

.col.col-order-univers.cursor.mission-delivery-default-cell, .col.col-order-univers.mission-delivery-default-cell, .col.col-order-univers.cursor.mission-restock-default-cell, .col.col-order-univers.mission-restock-default-cell {
  width: 9%;
}

.col.preparers {
  flex-direction: column;
  flex: 1;
  padding: 0;
  display: flex;
}

.col.order-status {
  width: 8%;
}

.col.preparers {
  flex: 1;
  padding: 0;
  display: flex;
}

.col.order-col-presta {
  flex-wrap: nowrap;
  flex: none;
  justify-content: center;
  width: 6%;
}

.button-text {
  color: #fff;
  display: flex;
}

.button-text.action-button-grid {
  font-size: 16px;
}

.tab-wrapper-2 {
  justify-content: space-around;
  margin-bottom: 15px;
  padding-bottom: 0;
  display: flex;
}

.select-status {
  flex: none;
  width: 300px;
  margin-bottom: 0;
  margin-left: 5px;
}

.input-globing {
  flex: 1;
  margin-right: 15px;
  display: flex;
}

.interaction-div {
  display: flex;
}

.paginate-container {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.table-container {
  border: 1px solid rgba(0, 0, 0, .12);
  margin-top: 10px;
}

.searching-div {
  flex: 0 auto;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 5px;
  display: flex;
}

.grid-action-button {
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  padding: 9px 15px;
  display: flex;
}

.grid-action-button:hover {
  background-color: rgba(250, 53, 67, .9);
}

.grid-action-button.impression-degrade {
  margin-right: 10px;
}

.grid-action-button.paginate-actions {
  margin-left: 10px;
}

.grid-action-button.paginate-action {
  margin-top: 5px;
  margin-bottom: 5px;
}

.grid-action-button.paginate-action.missions-button:hover {
  background-color: rgba(34, 112, 146, .9);
}

.grid-action-button.missions-button.clear-date-btn {
  flex: 0 auto;
  width: 40px;
  min-width: auto;
  height: 38px;
  margin-left: 0;
  padding-left: 17px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
  line-height: 20px;
}

.grid-action-button.clear-date-button {
  width: 40px;
  min-width: 40px;
  height: 38px;
  margin-left: 0;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.grid-action-button.clear-date-button.missions-button:hover {
  background-color: rgba(34, 112, 146, .9);
}

.grid-action-button.clear-date-button.orders-button {
  min-width: 40px;
}

.grid-action-button.orders-search-button {
  margin-left: 10px;
}

.grid-action-button.list-missingp-button {
  flex: 0 auto;
  margin-left: 10px;
}

.grid-action-button.clear-date-from {
  width: 40px;
  min-width: 40px;
  height: 38px;
  margin-left: 0;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.grid-action-button.clear-date-from.orders-button {
  min-width: 40px;
}

.grid-action-button.clear-date-to {
  width: 40px;
  min-width: 40px;
  height: 38px;
  margin-left: 0;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.grid-action-button.clear-date-to.orders-button {
  min-width: 40px;
}

.grid-action-button.list-missing-button {
  flex: 0 auto;
  margin-left: 10px;
}

.grid-action-button.printslip-print-btn {
  margin-bottom: 10px;
}

.grid-action-button.printslip-print-btn.not-printed {
  margin-top: 10px;
}

.grid-action-button.printsub-print-btn {
  margin-bottom: 10px;
}

.grid-action-button.print-order-print-btn, .grid-action-button.mission-labels-print-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.grid-action-button.formation-reset-button {
  min-width: auto;
}

.grid-action-button.add-order-button {
  border-radius: 19px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.grid-action-button.orders-create-button {
  margin-left: 10px;
}

.search-text {
  display: inline-block;
}

.next-page {
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 0;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  display: flex;
}

.row {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  align-items: stretch;
  margin-top: 0;
  display: flex;
}

.row.selected-row {
  background-color: #f2f2f2;
}

.row.selected-row.selected-imminent {
  background-color: rgba(232, 144, 45, .69);
}

.row.selected-row.selected-late {
  background-color: rgba(250, 53, 67, .9);
}

.row.imminent {
  background-color: #f5d24c;
}

.row.late {
  background-color: rgba(250, 53, 67, .69);
}

.row.control-warning {
  background-color: #ff6971;
}

.row.imminent-mode {
  color: var(--white);
  background-color: #dd314e;
}

.row.blue-mode {
  color: var(--white);
  background-color: #2a8db8;
}

.search-client-input {
  flex: none;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 0;
}

.col-field {
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.col-field.fa {
  font-family: Fontawesome webfont, sans-serif;
}

.col-field.product-number {
  width: 70%;
}

.col-field.product-date {
  flex-direction: column;
}

.col-field.product-date.hidden {
  display: none;
}

.col-field.id-mission {
  flex-direction: row;
  justify-content: center;
}

.col-field.id-picker {
  flex: 1;
}

.col-field.id-picker.hidden {
  display: none;
}

.col-field.bac-number {
  color: var(--black);
  font-weight: 400;
}

.col-field.mission-state {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.col-field.mission-state.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.col-field.missing-number {
  width: 70%;
}

.col-field.check-all {
  font-size: 30px;
}

.col-field.order-customer {
  text-align: center;
  flex: none;
  width: 70%;
}

.col-field.order-type {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.col-field.order-type.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.col-field.order-type {
  color: #000;
  width: 70%;
  font-weight: 400;
}

.col-field.bac-number-copy, .col-field.order-univers {
  color: #000;
  font-weight: 400;
}

.col-field.status.hidden {
  display: none;
}

.prev-page {
  justify-content: center;
  align-items: center;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
  display: flex;
}

.checkbox-wrapper-2 {
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 20px;
}

.search-button {
  color: #fff;
}

.search-button.search-submit-button {
  background-color: #0098ff;
  margin-left: 0;
  padding-top: 9px;
  padding-bottom: 8px;
}

.search-button.search-submit-button.cursor {
  background-color: #fa3543;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  padding-top: 9px;
  padding-bottom: 8px;
  display: flex;
}

.input-search-fields {
  flex-wrap: wrap;
  flex: 1;
  display: flex;
}

.header-title-div {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  display: flex;
  position: static;
}

.logout {
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  display: block;
}

.logout.cursor {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Materialicons, sans-serif;
  font-size: 23px;
  display: block;
}

.header-tab-title {
  cursor: pointer;
  align-items: center;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

.header-tab-title.title-selected {
  border-bottom: 3px solid #000;
  padding-bottom: 10px;
}

.header-tab-title.command-header-title:hover {
  border-bottom: 3px solid rgba(255, 255, 255, .5);
  padding-top: 3px;
}

.header-tab-title.command-header-title.tab-selected {
  border-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.header-tab-title.command-header-title.tab-selected:hover {
  padding-top: 0;
}

.header-tab-title.command-header-title.admin-tile {
  flex-direction: column;
  justify-content: flex-start;
  width: 120px;
  height: 60px;
  margin-left: 0;
  padding-top: 20px;
  position: relative;
}

.header-tab-title.command-header-title.admin-tile:hover {
  border-bottom-style: none;
}

.header-tab-title.command-header-title.admin-tile.hidden {
  display: none;
}

.header-tab-title.command-header-title.kpi-title {
  height: 60px;
  position: relative;
}

.header-tab-title.command-header-title.kpi-title:hover {
  border-bottom-style: none;
  padding-top: 0;
}

.header-tab-title.pp-hidden {
  display: none;
}

.livraison-date, .command-name-field {
  margin-bottom: 0;
}

.select-field {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
  margin-right: 10px;
}

.div-block-61, .assignation-div {
  display: flex;
}

.collect-div {
  margin-right: 0;
  display: flex;
}

.no-result {
  background-color: #f2f2f2;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
  font-size: 20px;
  line-height: 30px;
  display: flex;
}

.no-result.hidden {
  display: none;
}

.print-order-dispatch {
  justify-content: space-around;
  margin-top: 13px;
  display: flex;
}

.print-order-logoimg {
  max-width: 100%;
  height: 50px;
  margin-top: 19px;
  margin-left: 6px;
}

.print-ordercust-delivery {
  width: 33%;
  margin-right: 19px;
}

.print-ordertab-header {
  background-color: #f1ebed;
  justify-content: space-between;
  padding: 13px 8px;
  display: flex;
}

.print-dispatch-right {
  width: 47%;
}

.print-order-bacs {
  flex: 1;
  margin-right: 11px;
}

.print-ordertab-h4 {
  width: 5cm;
  display: flex;
}

.print-order-comment {
  background-color: #f1ebed;
  border: 1px solid #8b445c;
  border-radius: 8px;
  height: 3cm;
}

.print-ordertab-h1 {
  flex-direction: column;
  width: 5cm;
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
}

.print-ordercust-line {
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  margin-bottom: 1px;
  display: flex;
}

.print-order {
  padding: 0 11px;
  font-size: 9pt;
}

.print-ordercust-cust {
  width: 33%;
}

.print-ordercust-title {
  color: #8b445c;
  margin-bottom: 1px;
  font-weight: 700;
}

.print-ordercust-billing {
  width: 33%;
  margin-right: 19px;
}

.print-ordertab-subinput {
  border-bottom: 1px solid #f1ebed;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.print-ordertab-subinput.lastsubinput {
  border-bottom-style: none;
}

.print-orderdesc-title {
  color: #8b445c;
  font-weight: 700;
}

.print-order-logo {
  flex-shrink: 0;
  flex-basis: 271px;
}

.print-order-logowrap {
  align-items: flex-start;
  width: 100%;
}

.print-headtitle {
  color: #8b445c;
  font-weight: 700;
}

.print-order-header {
  justify-content: space-between;
  display: flex;
}

.print-ordertab-h2 {
  width: 3.7cm;
  padding-left: 7px;
  padding-right: 7px;
}

.print-order-customer {
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 27px;
  display: flex;
}

.print-order-bacswrapper {
  justify-content: space-between;
  display: flex;
}

.print-order-imgwrapper {
  width: 100%;
}

.print-ordercust-subtitle {
  color: #8b445c;
}

.print-ordertab-h3 {
  width: 5cm;
  padding-left: 7px;
  padding-right: 7px;
}

.print-order-img {
  max-width: 100%;
}

.print-ordertab-inputright {
  border: 1px solid #949494;
  flex: none;
  width: 1.5cm;
  height: .6cm;
  margin-top: 2px;
  margin-bottom: 2px;
}

.print-dispatch-title {
  color: #8b445c;
  margin-bottom: 9px;
  font-size: 13pt;
  font-weight: 700;
}

.print-ordertab-comment {
  background-color: #f1ebed;
  border: 1px solid #8b445c;
  border-radius: 8px;
  width: 100%;
  margin-left: 7px;
}

.print-ordertab-line {
  border-bottom: 2px solid #f1ebed;
  justify-content: space-between;
  padding: 7px 8px;
  display: flex;
}

.print-ordertab-h0 {
  align-items: center;
  width: 3cm;
  display: flex;
}

.print-dispatch-left {
  width: 47%;
}

.print-ordercust-lineblk {
  margin-right: 10px;
}

.print-ordercust-lineblk.print-cust-name, .print-ordercust-lineblk.print-addr-street, .print-ordercust-lineblk.print-cust-email {
  max-width: 75%;
}

.search-row {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.search-row.states-row, .search-row.types-row {
  align-content: stretch;
  margin-bottom: 10px;
}

.search-row.types-row {
  align-content: stretch;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.search-col {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.search-col.search-col-left {
  margin-left: 20px;
}

.search-line {
  flex: none;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.search-line.status-line.hidden {
  display: none;
}

.state-select {
  margin-bottom: 0;
}

.search-label {
  color: rgba(48, 48, 48, .95);
  flex: none;
  width: 130px;
  margin-right: 5px;
  font-weight: 700;
}

.search-actions {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.search-pagination {
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.nb-elem-select-wrapper {
  margin-bottom: 0;
  margin-left: 15px;
}

.text-block-71 {
  justify-content: center;
  width: 30px;
  display: flex;
}

.paginate-page {
  color: #303030;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
}

.paginate-page.active {
  color: #fff;
  cursor: default;
  background-color: #303030;
  border-radius: 4px;
}

.pagination-content {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paginate-arrow {
  color: #303030;
  cursor: pointer;
  padding: 8px 5px;
  font-family: Fontawesome, sans-serif;
  font-size: 16px;
  text-decoration: none;
}

.paginate-arrow.last {
  font-size: 30px;
}

.paginate-arrow.simple-right {
  justify-content: center;
  align-items: center;
  line-height: 10px;
}

.paginate-arrow.double-right {
  justify-content: center;
  align-items: center;
}

.div-block-63 {
  display: flex;
  position: relative;
}

.pagination-button-wrapper {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 33%;
  display: flex;
}

.pagination-actions {
  flex-flow: wrap;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  display: flex;
}

.print-labelmission-left {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin-right: 13px;
  display: flex;
}

.print-labelmission-right {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.print-labelmission-line {
  margin-bottom: 6px;
  font-size: 16px;
}

.print-labelmission-line.print-labelheader {
  font-size: 22px;
  font-weight: 700;
}

.print-label-trayid {
  font-size: 1.25em;
}

.print-label-delivery {
  font-size: 1.05em;
}

.right-checkbox-wrapper {
  cursor: default;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.paginate-number-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.pagination-pages {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.pagination-pages.reversed {
  flex-direction: row-reverse;
}

.search-form-wrapper {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.paginate-action {
  flex: none;
  margin-right: 10px;
}

.printslip-comment-col24 {
  width: 4cm;
}

.printslip-tray-total {
  border: 2px solid #8b445c;
  width: 7cm;
  margin-top: 5px;
}

.printslip-logo {
  margin-left: -18px;
}

.printslip-right {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 60%;
  margin-left: 20px;
  display: flex;
}

.printslip-header-left {
  width: 50%;
  padding-right: 0;
  display: flex;
}

.printslip-content {
  margin-left: 48px;
  margin-right: 48px;
}

.printslip-fieldkey {
  color: #8b445c;
}

.printslip-body {
  justify-content: space-between;
  margin-top: 21px;
  display: flex;
}

.printslip-footer1 {
  color: #fff;
  text-align: center;
  background-color: #8b445c;
  padding-top: 4px;
  padding-bottom: 4px;
}

.printslip-customerblock {
  border: 2px solid #8b445c;
  padding: 15px;
}

.printslip-catline {
  border-bottom: 1px solid rgba(139, 68, 92, .36);
  justify-content: space-between;
  display: flex;
}

.printslip-orderline-title {
  color: #8b445c;
  font-weight: 700;
}

.printslip-commen-col21 {
  color: #8b445c;
  border-right: 2px solid #8b445c;
  align-items: center;
  width: 3.4cm;
  padding-left: 12px;
  display: flex;
}

.printslip-comment-col22 {
  width: 4cm;
}

.printslip-traytab {
  border: 2px solid #8b445c;
  width: 7cm;
  margin-top: 16px;
}

.printslip-fieldline {
  justify-content: space-between;
  margin-bottom: 5px;
  display: flex;
}

.printslip-customerblock2 {
  flex: 1;
  margin-top: 7px;
}

.printslip-header-qrimg {
  width: 4cm;
}

.printslip-cattype {
  margin-left: 6px;
  font-weight: 700;
}

.printslip-footer2 {
  margin-top: 11px;
  font-size: 12px;
  line-height: 14px;
}

.printslip-fieldval {
  text-align: left;
  flex: 1;
  margin-left: 6px;
}

.printslip-customer-address {
  font-size: 18px;
}

.printslip-comment-line2 {
  justify-content: space-between;
  height: 1.6cm;
  display: flex;
}

.printslip-logoimg {
  width: 230px;
}

.printslip-orderline {
  display: flex;
}

.printslip-customername {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
}

.printslip-fieldcol {
  flex: 1;
  justify-content: space-between;
  margin-right: 11px;
  display: flex;
}

.printslip-catleft {
  color: #8b445c;
  border-right: 1px solid #8b445c;
  flex: 1;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 16px;
  display: flex;
}

.printslip-comment-line1 {
  border-bottom: 2px solid #8b445c;
  justify-content: space-between;
  height: 1.6cm;
  display: flex;
}

.printslip-comment-col23 {
  color: #8b445c;
  border-left: 2px solid #8b445c;
  border-right: 2px solid #8b445c;
  flex: 1;
  align-items: center;
  padding-left: 12px;
  display: flex;
}

.printslip-commentblock {
  border: 2px solid #8b445c;
  margin-top: 36px;
  margin-bottom: 26px;
}

.printslip-header {
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  display: flex;
}

.printslip-comment-col11 {
  color: #8b445c;
  border-right: 2px solid #8b445c;
  align-items: center;
  width: 3.4cm;
  padding-left: 12px;
  display: flex;
}

.printslip-title {
  color: #8b445c;
  text-align: center;
  border: 5px solid #8b445c;
  margin-top: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 24px;
  display: flex;
}

.printslip-catright {
  justify-content: center;
  align-items: center;
  width: 1.5cm;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
}

.version-menu {
  padding-right: 10px;
  position: absolute;
  top: 20px;
  right: 0;
}

.version-number {
  margin-left: 15px;
}

.brand {
  text-transform: uppercase;
  flex: 1;
  align-items: center;
  display: flex;
}

.substitution-wrapper {
  flex-direction: column;
  flex: 0 auto;
  order: 0;
  justify-content: center;
  align-self: auto;
  align-items: center;
  max-width: 33%;
  margin-bottom: 0;
  padding-bottom: 78px;
  display: flex;
}

.mission-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-72 {
  object-fit: fill;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  font-size: 16px;
  font-weight: 700;
  display: block;
  position: static;
}

.text-block-73 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
}

.text-block-74 {
  margin-bottom: 5px;
  font-weight: 700;
}

.div-block-64 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  display: flex;
}

.visualisation-row {
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.total {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 130px;
  height: 80px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.total.total-all {
  background-color: #f2f2f2;
  flex-direction: row;
  justify-content: space-around;
}

.total.total-imminent {
  background-color: rgba(232, 144, 45, .47);
  justify-content: space-around;
}

.total.total-late {
  background-color: rgba(250, 53, 67, .69);
  justify-content: center;
}

.total-number {
  text-align: center;
  width: 60px;
  font-size: 30px;
  font-weight: 700;
}

.total-text {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
}

.total-text.font-awesome {
  margin-bottom: 5px;
  font-family: Fontawesome, sans-serif;
  font-size: 22px;
}

.col-total {
  width: 60px;
}

.tab-wrapper-top {
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.printslip-address {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 12px;
}

.monop-data {
  margin-left: 20px;
}

.printslip-info {
  margin-left: 20px;
  font-size: 12px;
}

.text-block-75 {
  color: #8b445c;
  font-size: 37px;
  line-height: 45px;
}

.client-address {
  background-color: #f2e9ec;
  border: 2px solid #8b445c;
  border-radius: 20px;
  flex: 0 auto;
  padding: 9px;
}

.livraison-address {
  border-bottom: 2px solid #f2f2f2;
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 0;
}

.facturation-address {
  margin-top: 0;
}

.livraison-address-title {
  color: #8b445c;
  margin-top: 2px;
  font-size: 15px;
  font-weight: 500;
}

.text-block-76 {
  margin-top: 0;
  margin-left: 20px;
}

.client-name {
  margin-top: 0;
  margin-left: 14px;
  line-height: 25px;
}

.client-location, .client-additional-data {
  margin-left: 14px;
  line-height: 25px;
}

.client-data {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.printslip-additional-info {
  flex: 1;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 25px;
  display: flex;
}

.text-block-77 {
  margin-bottom: 20px;
  font-size: 15px;
}

.printslip-tranquillty-field {
  flex: 0 auto;
  margin-top: 16px;
  line-height: 1.5em;
}

.substitution-table-wrapper {
  flex-wrap: wrap;
  padding-bottom: 15px;
  display: block;
}

.div-block-65 {
  display: flex;
}

.missing-table {
  border: 3px solid red;
  flex: 0 auto;
  width: 39%;
}

.substitution-table {
  border: 3px solid #00f;
  flex-flow: column wrap;
  flex: 0 auto;
  width: 39%;
  display: flex;
}

.facturation-table {
  border: 3px solid green;
  flex: 0 auto;
  width: 20%;
}

.printslip-row {
  background-color: #dbdbdb;
  border-bottom: 1px solid #000;
  flex-flow: wrap;
  flex: 0 auto;
  align-content: stretch;
  justify-content: flex-start;
  display: flex;
}

.table-title {
  color: #8b445c;
  background-color: #f2e9ec;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 15px;
  display: flex;
}

.table-title.tab-missing {
  border-bottom: 3px solid #fd323d;
}

.table-title.tab-substitued {
  border-bottom: 3px solid #00f;
}

.table-title.tab-facturation {
  border-bottom: 3px solid green;
}

.printsub-table {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 31px;
  display: flex;
}

.printslip-col {
  text-align: center;
  border-width: 1px;
  border-right-style: solid;
  border-right-color: #000;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
}

.printslip-col.brand-sub {
  width: 35%;
}

.printslip-col.article-miss {
  width: 30%;
}

.printslip-col.price-miss {
  width: 16%;
}

.printslip-col.qty-ordered-miss {
  width: 12%;
}

.printslip-col.qty-delivered-miss {
  border-right-style: none;
  border-right-width: 0;
  width: 12%;
}

.printslip-col.sub-article {
  width: 30%;
}

.printslip-col.brand-miss {
  border-bottom-style: none;
  border-bottom-color: #000;
  width: 30%;
}

.printslip-col.article-sub {
  width: 35%;
}

.printslip-col.price-sub {
  width: 16%;
}

.printslip-col.qty-ordered-sub {
  border-right-style: none;
  width: 14%;
}

.printslip-col.qty-delivered-sub {
  width: 20%;
}

.printslip-col.total-sub {
  width: 30%;
}

.printslip-col.decline-sub, .printslip-col.accept-sub {
  width: 25%;
}

.printslip-header-row {
  background-color: #f2e9ec;
  border-bottom: 1px solid #000;
  flex-wrap: nowrap;
  justify-content: flex-start;
  display: flex;
}

.printslip-col-content.tab-square {
  background-color: #fff;
  border: 1px solid #000;
  width: 35px;
  height: 35px;
}

.printslip-col-content.qty-delivered-sub {
  width: 20%;
}

.printslip-col-content.total-sub {
  width: 30%;
}

.printslip-col-content.accept-sub, .printslip-col-content.decline-sub {
  width: 25%;
}

.printsub-footer {
  flex-direction: column;
  display: flex;
}

.complet-sign {
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
}

.text-block-78 {
  font-size: 15px;
}

.total-products-to-complet {
  color: #8b445c;
  font-weight: 400;
  line-height: 25px;
}

.total-product-rejected {
  color: #8b445c;
  line-height: 25px;
}

.date-to-complete {
  color: #8b445c;
  background-color: #f2e9ec;
  border: 2px solid #8b445c;
  border-radius: 20px;
  width: 465px;
  height: 80px;
  margin-left: 0;
  margin-right: 20px;
  padding-top: 20px;
  padding-left: 15px;
  font-size: 15px;
}

.sign-feild {
  color: #8b445c;
  background-color: #f2e9ec;
  border: 2px solid #8b445c;
  border-radius: 20px;
  width: 710px;
  height: 130px;
  padding-top: 20px;
  padding-left: 15px;
  font-size: 15px;
}

.missing-table-list {
  border-top: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
}

.substitutions-table-list {
  flex-flow: column wrap;
  flex: 0 auto;
  display: flex;
}

.div-block-66 {
  justify-content: center;
  display: flex;
}

.return-qty-button {
  color: #fff;
  background-color: #fd323d;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 40px;
  display: flex;
}

.return-qty-button.plus-button {
  cursor: pointer;
  width: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 40px;
}

.done-qty {
  color: #fd323d;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 25px;
  display: flex;
}

.form-block-8 {
  flex: 1;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.text-field-5, .textarea-3 {
  margin-bottom: 0;
}

.div-block-67 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.ean-list {
  padding-top: 10px;
  padding-bottom: 10px;
}

.return-ean {
  padding: 5px 10px;
  display: flex;
}

.return-ean-text {
  font-size: 20px;
}

.return-ean-remove {
  cursor: pointer;
  padding-left: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
}

.select-field-2 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  flex: none;
  max-width: 70px;
  margin-top: 5px;
  margin-bottom: 0;
}

.div-block-68 {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.div-block-69 {
  flex-direction: row;
  display: flex;
}

.option-tab-2 {
  margin-top: 0;
}

.product-button-container {
  border: 0 solid #e8902d;
  border-radius: 0;
  justify-content: flex-end;
  margin-top: 0;
  display: flex;
}

.scalediv {
  z-index: 1;
  background-color: #dedede;
  border: 1px #000;
  width: 400px;
  margin-bottom: 40px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 6px #000;
}

.scalediv.section-list {
  background-color: #fff;
  width: 400px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 4px #000;
}

.scalediv.section-list.hidden {
  display: none;
  top: 60px;
}

.scalediv.path-list {
  background-color: #fff;
  flex-direction: column;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
}

.scalediv.aisle-list {
  background-color: #fff;
  width: 400px;
  top: 60px;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 6px #000;
}

.scalediv.aisle-list-container {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.scalediv.transition {
  right: -400px;
}

.scalediv.ug-list {
  background-color: #fff;
  width: 400px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 1px 1px 4px #000;
}

.scalediv.ug-list.hidden {
  display: none;
  top: 60px;
}

.scaletitle {
  color: #fff;
  background-color: rgba(250, 53, 67, .69);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.scaletitle.aisle {
  background-color: #fa3543;
  flex-wrap: wrap;
  height: auto;
  min-height: 50px;
  padding: 10px;
}

.scaletitle.aisle.path-title-container {
  flex-flow: wrap;
  flex: none;
  justify-content: space-around;
  align-items: center;
  height: auto;
  min-height: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.scaletitle.aisle.path-title-container.description {
  min-height: 10px;
}

.scalelevel.subaisle {
  text-align: center;
  flex: 1;
  font-weight: 700;
}

.scalelevel.aisle {
  border-style: none;
  flex: 1;
  height: auto;
  max-height: 100%;
  font-size: 18px;
}

.scalelevel.aisle.aisle-title {
  justify-content: center;
}

.scalelevel.aisle.parcours-name {
  flex: 1;
  justify-content: center;
  height: auto;
  max-height: 100%;
}

.div-block-70 {
  height: 40px;
}

.sub-aisle-title {
  color: #fff;
  background-color: rgba(250, 53, 67, .69);
  border-style: none;
  border-color: #333;
  border-top-width: 1px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px;
  display: flex;
}

.aisle {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  display: flex;
}

.nexticon-copy {
  width: 30px;
  height: 30px;
}

.aisle-2 {
  border: 1px solid #000;
  border-right-style: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  display: flex;
}

.sub-aisle-2 {
  color: #ff7b82;
  border: 1px solid #000;
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.rayonugtext {
  font-size: 15px;
}

.rayonugtext.aisle {
  border-style: none;
  font-size: 15px;
}

.modifyicon {
  width: 30px;
  height: 30px;
}

.id {
  font-size: 15px;
}

.id.aisle {
  border-style: none;
  font-size: 20px;
}

.aisle-row {
  background-color: #fff;
  border-top: 1px solid #d1d1d1;
  justify-content: space-between;
  height: 50px;
  display: flex;
}

.aisle-row:hover {
  background-color: #f2f2f2;
}

.aisle-row.add-shape-button {
  cursor: pointer;
  background-color: #20a757;
}

.aisle-row.add-shape-button:hover {
  background-color: #18bf5c;
}

.aisle-col {
  border-right: 1px #000;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  width: 0;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.aisle-col.edit-button {
  background-color: #e8902d;
  padding-left: 5px;
}

.aisle-col.suppr-button {
  background-color: #a3040e;
}

.aisle-col.next-button {
  background-color: #227092;
}

.aisle-col.add-shape-button {
  cursor: pointer;
  background-color: #20a757;
  margin-bottom: 0;
}

.aisle-col.add-shape-button:hover {
  background-color: #65ca8e;
}

.aisle-col.path-number {
  border-right-style: solid;
  border-right-color: #999;
  flex: 0 auto;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}

.del-tab {
  color: #fff;
  flex: 0 auto;
  justify-content: center;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
  display: flex;
}

.del-tab.black {
  color: #333;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
}

.del-tab.black:hover {
  color: #fd323d;
}

.del-tab.black.hidden {
  display: none;
}

.text-block-79 {
  font-family: Fontawesome webfont, sans-serif;
  font-size: 32px;
  line-height: 30px;
}

.select-aisle {
  color: #fff;
  flex: 0 auto;
  justify-content: flex-end;
  margin-right: 0;
  padding-right: 0;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 32px;
  line-height: 30px;
  display: flex;
}

.select-aisle.black {
  color: #303030;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
}

.select-aisle.black:hover {
  color: #fd323d;
}

.edit-option {
  color: #fff;
  font-family: Materialicons, sans-serif;
  font-size: 30px;
}

.edit-option.black {
  color: #303030;
  cursor: pointer;
  padding: 5px;
  font-size: 25px;
}

.edit-option.black:hover {
  color: #fd323d;
}

.edit-button-text {
  color: #fff;
  margin-right: 5px;
  font-size: 20px;
}

.add-shape-text {
  color: #fff;
}

.aisle-list {
  height: 100%;
}

.sub-aisle {
  flex: 1;
  overflow: auto;
}

.text-block-80 {
  position: absolute;
  left: 10px;
}

.go-back {
  cursor: pointer;
  flex: none;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
}

.go-back:hover {
  color: #dedede;
}

.list-tools {
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  top: 8px;
  right: 401px;
}

.tool-list-icon {
  background-color: #fff;
  border: 1px #d1d1d1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-bottom: 3px;
  box-shadow: -1px -1px 3px #d1d1d1;
}

.map-list-icon {
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px;
  font-family: Materialicons, sans-serif;
  font-size: 32px;
  line-height: 32px;
}

.map-list-icon:hover {
  color: #333;
  background-color: #f2f2f2;
}

.map-list-icon.aisle-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.map-list-icon.aisle-icon.mode-selected:hover {
  color: #f2f2f2;
  background-color: #fd323d;
}

.map-list-icon.paint-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.map-list-icon.paint-icon.mode-selected:hover {
  color: #f2f2f2;
  background-color: #fd323d;
}

.map-list-icon.close-menu-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: block;
}

.map-list-icon.close-menu-icon.hidden {
  display: none;
}

.map-list-icon.open-menu-icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: block;
}

.map-list-icon.open-menu-icon.hidden {
  display: none;
}

.map-list-icon.list-ug-icon {
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.map-list-icon.list-ug-icon.mode-selected:hover {
  color: #f2f2f2;
  background-color: #fd323d;
}

.drawing-tools {
  background-color: #ff7b82;
  flex-flow: wrap;
  flex: none;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  display: flex;
}

.draw-tool-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
  display: flex;
}

.draw-tool-block.hidden {
  display: none;
}

.tool-selected {
  color: #fff;
  background-color: #20a757;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, .5);
}

.modification-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  min-width: 500px;
  padding-left: 30px;
  padding-right: 30px;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.form-7 {
  display: flex;
}

.field-label-2, .field-label-3 {
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: flex;
}

.ug-modification {
  width: 150px;
}

.id-modification {
  width: 150px;
  margin-right: 15px;
}

.colid {
  font-weight: 700;
}

.colid.aisle {
  border-style: none;
  font-size: 20px;
}

.colrayonug {
  text-align: center;
  font-weight: 700;
}

.colrayonug.aisle {
  border-style: none;
  font-size: 15px;
}

.colshelf {
  font-weight: 700;
}

.colshelf.aisle {
  border-style: none;
  font-size: 15px;
}

.hide {
  cursor: pointer;
  flex: none;
  margin-left: 5px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
}

.show {
  cursor: pointer;
  flex: none;
  margin-left: 5px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
  display: block;
}

.title-row {
  height: 50px;
  display: flex;
}

.bottom-menu {
  z-index: 2;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  box-shadow: 1px 1px 3px #000;
}

.save-button {
  cursor: pointer;
  background-color: #20a757;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.save-button:hover {
  background-color: #18bf5c;
}

.open-menu {
  cursor: pointer;
  background-color: #227092;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 40px;
  display: flex;
}

.open-menu:hover {
  background-color: #2181ab;
}

.save-button-text {
  color: #fff;
}

.open-menu-text {
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: Materialicons, sans-serif;
  font-size: 35px;
  display: flex;
}

.map-button-menu {
  z-index: 2;
  background-color: #f2f2f2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 115px;
  position: fixed;
  bottom: 40px;
}

.white-text {
  color: #fff;
}

.section-number {
  font-size: 15px;
}

.title-subaisle {
  background-color: #e1dfdf;
  height: 50px;
  display: flex;
}

.pck-logo-img {
  width: 300px;
  display: block;
}

.headerlogo-img {
  margin-left: 10px;
}

.row-slip-selection {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: row;
  height: 50px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
}

.modal-2 {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.modal-2.checkbox-modal.hidden {
  display: none;
}

.slip-container {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.slip-checkbox {
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-size: 30px;
  line-height: 30px;
  display: flex;
}

.slip-name {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  display: flex;
}

.printsub-content {
  margin-top: 12px;
  margin-left: 48px;
  margin-right: 48px;
  font-size: 13px;
  line-height: 1.3em;
}

.printsub-header-left-copy, .printsub-header-left {
  padding-right: 0;
  display: flex;
}

.printsub-address {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 12px;
}

.monop-data-sub {
  margin-left: 20px;
}

.printsub-info {
  margin-left: 20px;
  font-size: 12px;
}

.printsub-logoimg {
  width: 230px;
}

.printsub-header-qrimg {
  width: 4cm;
}

.printsub-fieldkey {
  color: #8b445c;
}

.printsub-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.printsub-footer1 {
  color: #fff;
  text-align: center;
  background-color: #8b445c;
  padding-top: 4px;
  padding-bottom: 4px;
}

.printsub-footer2 {
  margin-top: 11px;
  font-size: 12px;
  line-height: 14px;
}

.printsub-col-content.tab-square {
  background-color: #fff;
  border: 1px solid #000;
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
}

.printsub-col-content.qty-delivered-sub {
  width: 20%;
}

.printsub-col-content.total-sub {
  width: 30%;
}

.printsub-col-content.accept-sub, .printsub-col-content.decline-sub {
  width: 25%;
}

.printsub-col {
  text-align: center;
  border-width: 1px;
  border-right-style: solid;
  border-right-color: #000;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
}

.printsub-col.brand-sub {
  width: 35%;
}

.printsub-col.article-miss {
  width: 30%;
}

.printsub-col.price-miss {
  width: 16%;
}

.printsub-col.qty-ordered-miss {
  width: 12%;
}

.printsub-col.qty-delivered-miss {
  border-right-style: none;
  border-right-width: 0;
  width: 12%;
}

.printsub-col.sub-article {
  width: 30%;
}

.printsub-col.brand-miss {
  border-bottom-style: none;
  border-bottom-color: #000;
  width: 30%;
}

.printsub-col.article-sub {
  width: 35%;
}

.printsub-col.price-sub {
  width: 16%;
}

.printsub-col.qty-ordered-sub {
  border-right-style: none;
  width: 14%;
}

.printsub-col.qty-delivered-sub {
  width: 20%;
}

.printsub-col.total-sub {
  width: 30%;
}

.printsub-col.decline-sub, .printsub-col.accept-sub {
  width: 25%;
}

.printsub-header-row {
  background-color: #f2e9ec;
  border-bottom: 1px solid #000;
  flex-wrap: nowrap;
  justify-content: flex-start;
  display: flex;
}

.printsub-row {
  background-color: #dbdbdb;
  border-bottom: 1px solid #000;
  flex-flow: wrap;
  flex: 0 auto;
  align-content: stretch;
  justify-content: flex-start;
  display: flex;
}

.shelf-number {
  font-size: 15px;
}

.shelf-number.aisle {
  border-style: none;
  font-size: 15px;
}

.section-row {
  background-color: #fff;
  border-top: 1px solid #d1d1d1;
  justify-content: space-between;
  height: 50px;
  display: flex;
}

.section-row:hover {
  background-color: #f2f2f2;
}

.section-row.add-shape-button {
  background-color: #20a757;
}

.shelf-list {
  background-color: #fff;
  border: 1px solid #000;
}

.shelf-row {
  height: 40px;
  display: flex;
}

.add-shelf {
  background-color: #20a757;
  justify-content: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.shelf-del {
  justify-content: center;
  align-items: center;
  display: flex;
}

.shelf {
  background-color: #fff;
  border-top: 1px solid #d1d1d1;
  justify-content: space-between;
  height: 50px;
  display: flex;
}

.shelf.add-shape-button {
  background-color: #20a757;
}

.text-field-6, .ug-field {
  margin-bottom: 0;
}

.shelf-col {
  border-right: 1px #000;
  flex: 0 auto;
  justify-content: space-around;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.shelf-col.edit-button {
  background-color: #e8902d;
  padding-left: 5px;
}

.shelf-col.suppr-button {
  background-color: #a3040e;
}

.shelf-col.next-button {
  background-color: #227092;
}

.shelf-col.add-shape-button {
  background-color: #20a757;
  margin-bottom: 0;
}

.shelf-col.path-number {
  border-right-style: solid;
  border-right-color: #999;
  flex: 0 auto;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}

.ug-input {
  flex: 0 auto;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
}

.align-div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.carto-input-container {
  justify-content: center;
  align-items: center;
  margin-left: 0;
  display: flex;
}

.carto-input-container.ug-container {
  margin-top: 5px;
  margin-left: 0;
}

.id-input {
  flex: 0 auto;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
}

.shelf-number-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.mission_id {
  text-align: center;
}

.aisle-row-addsection {
  background-color: #fff;
  border-top: 1px solid #d1d1d1;
  justify-content: space-between;
  height: 50px;
  display: flex;
}

.aisle-row-addsection.add-shape-button {
  background-color: #20a757;
}

.go-next {
  cursor: pointer;
  flex: none;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
}

.go-next:hover {
  color: #dedede;
}

.zoom-in {
  color: #fff;
  transform-style: preserve-3d;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 0;
  font-family: Materialicons, sans-serif;
  font-size: 40px;
  line-height: 40px;
  position: static;
  top: 60px;
  left: 10px;
  transform: rotateX(0)rotateY(181deg)rotateZ(0);
}

.zoom-out {
  color: #fff;
  border-left: 1px solid #fff;
  padding-bottom: 3px;
  padding-left: 5px;
  font-family: Materialicons, sans-serif;
  font-size: 40px;
  line-height: 40px;
  position: static;
  top: 60px;
  left: 60px;
  transform: rotate(0);
}

.zoom-option {
  background-color: #227092;
  border: 1px #000;
  border-top-color: #999;
  border-radius: 0 0 5px 5px;
  padding: 3px 3px 0;
  display: flex;
  position: fixed;
  top: 60px;
  left: 10px;
  transform: rotate(0);
  box-shadow: 1px 1px 3px #000;
}

.text-block-81 {
  color: #fff;
  font-size: 15px;
}

.div-block-71 {
  flex: 1;
}

.states-col {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.states-element {
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}

.states-select {
  border: 1px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.states-select.selected {
  background-color: #fd323d;
}

.states-select.selected.mission {
  background-color: #227092;
}

.states-select.mission {
  border-color: #227092;
}

.label {
  margin-left: 10px;
}

.states-element-wrapper.formation-form {
  margin-left: 20px;
  margin-right: 20px;
}

.select-assignation, .form-select-assignation {
  margin-bottom: 0;
}

.collected-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 33%;
  margin-left: 15px;
}

.product-substitutions-wrapper {
  justify-content: flex-start;
  display: flex;
}

.text-block-82 {
  margin-right: 10px;
}

.text-block-83 {
  margin-left: 5px;
  font-family: Fontawesome, sans-serif;
  display: inline-block;
}

.text-block-84 {
  display: inline-block;
}

.missing-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 33%;
  margin-left: 15px;
}

.div-block-72 {
  justify-content: flex-start;
  display: flex;
}

.div-block-73 {
  flex: 1;
}

.div-block-74 {
  margin-top: 15px;
}

.text-block-85 {
  margin-bottom: 5px;
  margin-right: 10px;
  font-weight: 500;
  display: inline-block;
}

.text-block-86 {
  background-color: #dedede;
  padding: 5px;
  display: inline-block;
}

.sub-header-row {
  background-color: #f2e9ec;
  justify-content: space-around;
  display: flex;
}

.sub-header-col {
  color: #8b445c;
  text-align: center;
  border-top: 1px solid #000;
  border-bottom: 2px solid #000;
  border-left: 1px solid #000;
  border-right: 1px #000;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-weight: 500;
  display: flex;
}

.sub-header-col.ean-code, .sub-header-col.brand {
  width: 15%;
}

.sub-header-col.sub-description {
  width: 30%;
}

.sub-header-col.sub-ord-qty, .sub-header-col.sub-del-qty {
  width: 6%;
}

.sub-header-col.sub-unit-price, .sub-header-col.sub-billed-price {
  width: 10%;
}

.sub-header-col.accept-sub, .sub-header-col.decline-sub {
  width: 5%;
}

.sub-header-col.sub-brand {
  width: 15%;
}

.sub-header-col.sub-accepted, .sub-header-col.sub-declined {
  width: 4%;
}

.sub-table {
  border: 1px solid #000;
  border-right-width: 2px;
  font-size: .9em;
}

.sub-row {
  border-bottom: 1px solid #000;
  flex-direction: row;
  min-height: 60px;
  display: block;
}

.sub-row.row-missing {
  background-color: #dedede;
}

.sub-col {
  text-align: center;
  border-top: 1px #000;
  border-left: 1px solid #000;
  border-right: 1px #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 3px;
  display: flex;
}

.sub-col.sub-ean-code {
  border-top-style: none;
  border-bottom-style: none;
  flex-direction: column;
  align-items: center;
  width: 15%;
}

.sub-col.sub-brand {
  width: 15%;
}

.sub-col.sub-description {
  width: 30%;
}

.sub-col.sub-ord-qty, .sub-col.sub-del-qty {
  width: 6%;
}

.sub-col.sub-unit-price, .sub-col.sub-billed-price {
  width: 10%;
}

.sub-col.sub-accepted, .sub-col.sub-declined {
  width: 4%;
}

.div-block-76 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  min-height: 60px;
  display: flex;
  overflow: hidden;
}

.picker-checkbox-icon {
  color: #fa3543;
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
}

.modal-content-2 {
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

.modal-actions-2 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 5px 20px 20px;
  display: flex;
}

.picker-checkbox-wrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.checkbox-item {
  align-items: center;
  padding-top: 8px;
  display: flex;
}

.checkbox-item-label {
  padding-left: 15px;
  font-size: 16px;
}

.checkbox-items {
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  display: block;
}

.unassign-button {
  color: #fa3543;
  cursor: pointer;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 20px;
  display: flex;
}

.unassign-button.hidden {
  display: none;
}

.sub-header-title {
  color: var(--mpx_print_title);
}

.sub-col-value {
  width: 100%;
}

.preparer-select {
  margin-bottom: 0;
}

.print-label-provision {
  font-size: 1.05em;
}

.list-missing-content, .body-3 {
  height: 100%;
}

.heading-9 {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
}

.list-missing-table {
  margin-top: 20px;
  font-size: .8em;
}

.missing-table-header {
  background-color: rgba(222, 222, 222, .5);
  border-top: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 15px;
  display: flex;
}

.col-header {
  text-align: center;
  border: 1px solid #000;
  flex: 1;
  padding: 10px;
}

.col-header-value {
  flex: 0 auto;
  font-weight: 600;
}

.col-header-value.header-stock-date, .col-header-value.header-promo-date {
  margin-left: 5px;
}

.list-missing-info {
  justify-content: space-between;
  display: flex;
}

.div-block-77 {
  height: 100%;
  padding: 0 20px 20px;
}

.div-block-78 {
  margin-bottom: 10px;
}

.missing-table-row {
  justify-content: space-around;
  min-height: 30px;
  display: block;
}

.col-table-value {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.col-table-value.missing-img-value {
  width: 75px;
  max-height: 100px;
  overflow: hidden;
}

.col-table-value.missing-img-value.hidden {
  width: 0;
  height: 0%;
}

.col-table-value.hidden {
  display: none;
}

.col-table-value.half-column {
  height: 25%;
}

.col-table-value.half-column.missingp-ean-bar-code {
  height: 75%;
  min-height: 70px;
  font-family: Ean13, sans-serif;
  font-size: 5em;
  line-height: 1.5px;
}

.missing-col {
  text-align: center;
  border-bottom: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  flex-flow: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 1px;
  display: flex;
}

.missing-col.missing-img {
  width: 9%;
}

.missing-col.missing-ean {
  flex: 0 auto;
  width: 11%;
}

.missing-col.missing-brand {
  flex: 0 auto;
  width: 10%;
}

.missing-col.missing-desc {
  flex: 0 auto;
  width: 15%;
}

.missing-col.missing-usage {
  width: 15%;
}

.missing-col.missing-localization {
  width: 8%;
}

.missing-col.missing-qty, .missing-col.missing-stock {
  width: 5%;
}

.missing-col.missing-promo {
  width: 6%;
}

.missing-col.missing-substitutable {
  flex: 0 auto;
  width: 9%;
}

.missing-col.missing-substituted {
  flex: 0 auto;
  width: 7%;
}

.missing-table-row-wrap {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  min-height: 30px;
  display: flex;
}

.missing-product-im {
  width: 100%;
  height: 75px;
}

.div-block-80 {
  flex: 0 auto;
  width: 75px;
}

.missing-missionid {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.missing-order-list {
  margin-top: 5px;
}

.list-missingp-content {
  height: 100%;
}

.list-missingp-logoimg {
  max-width: 100%;
  height: 50px;
  margin-top: 19px;
  margin-left: 6px;
}

.div-block-81 {
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
}

.list-missingp-date-from {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
}

.missingp-col {
  text-align: center;
  border-bottom: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  flex-flow: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1px;
  display: flex;
}

.missingp-col.missing-ean {
  flex: 0 auto;
  width: 11%;
}

.missingp-col.missing-brand {
  flex: 0 auto;
  width: 10%;
}

.missingp-col.missing-desc, .missingp-col.missing-usage {
  flex: 0 auto;
  width: 15%;
}

.missingp-col.missing-localization {
  flex: 0 auto;
  width: 8%;
}

.missingp-col.missing-qty, .missingp-col.missing-stock {
  flex: 0 auto;
  width: 5%;
}

.missingp-col.missing-promo {
  flex: 0 auto;
  width: 6%;
}

.missingp-col.missing-substitutable {
  flex: 0 auto;
  width: 9%;
}

.missingp-col.missing-substituted {
  flex: 0 auto;
  width: 7%;
}

.missingp-col.missingp-img {
  flex: none;
  width: 11%;
}

.missingp-col.missingp-ean {
  flex: 0 auto;
  width: 13%;
}

.missingp-col.missingp-desc {
  flex: 0 auto;
  width: 15%;
}

.missingp-col.missingp-usage {
  flex: 0 auto;
  width: 12%;
}

.missingp-col.missingp-rayonug {
  flex: 0 auto;
  width: 8%;
}

.missingp-col.missingp-promo {
  flex: 0 auto;
  width: 7%;
}

.missingp-col.missingp-substitutable {
  flex: 0 auto;
  width: 9%;
}

.missingp-col.missingp-substituted {
  flex: 0 auto;
  width: 4%;
}

.missingp-col.missingp-qty {
  flex-direction: column;
  flex: 0 auto;
  width: 6%;
}

.missingp-col.missingp-stock {
  flex: 0 auto;
  width: 10%;
}

.missingp-col.missingp-promo {
  width: 10%;
}

.missingp-col.missingp-brand {
  flex: 0 auto;
  width: 11%;
}

.missingp-col.missingp-subs {
  flex: 0 auto;
  width: 4%;
}

.missingp-col.missingp-ean-barcode {
  flex: 0 auto;
  width: 10%;
  font-family: Ean13, sans-serif;
  font-size: 4.6em;
}

.missingp-col.missingp-ean-code {
  flex: 0 auto;
  width: 12%;
}

.missingp-col.missingp-ean-bar-code {
  flex: 0 auto;
  width: 12%;
  font-family: Ean13, sans-serif;
  font-size: 5em;
  line-height: 1.5em;
}

.list-missingp-info {
  justify-content: space-between;
  margin-bottom: -1px;
  display: flex;
}

.text-block-87 {
  display: inline-block;
}

.missing-info {
  width: 50%;
  line-height: 26px;
  display: inline-block;
}

.missing-info.missing-info-label {
  flex: 0 auto;
  width: 50%;
  font-weight: 600;
}

.missing-info.missing-info-date {
  margin-left: 15px;
}

.missing-info.missing-info-value {
  flex: 1;
  margin-left: 15px;
}

.missingp-info {
  flex: 0 auto;
  line-height: 26px;
}

.missingp-info.missingp-info-value {
  flex: 1;
  margin-left: 10px;
}

.missingp-info.missingp-info-label {
  width: 60%;
  font-weight: 700;
}

.div-block-83 {
  display: flex;
}

.missing-info-line {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.del {
  border: 1px solid #000;
  margin-bottom: -1px;
  padding: 5px;
  box-shadow: 1px 1px 3px #000;
}

.missingp-info-line {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.missingp-info-box {
  width: 50%;
}

.missingp-table-row-wrap {
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  min-height: 30px;
  display: flex;
}

.missingp-table-row {
  justify-content: space-around;
  min-height: 30px;
  display: block;
}

.missingp-table-list {
  border-top: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
}

.missingp-order-list {
  margin-top: 15px;
}

.missingp-table-header {
  background-color: rgba(222, 222, 222, .5);
  border-top: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 15px;
  display: flex;
}

.list-missingp-table {
  margin-top: 20px;
  font-size: .8em;
}

.missing-info-wrapper {
  border: 1px solid #000;
  margin-bottom: -1px;
  padding: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 3px rgba(0, 0, 0, .24);
}

.search-form-actions {
  flex-wrap: wrap;
  flex: 1;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.slot-menu {
  z-index: 0;
  background-color: #fff;
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.div-block-84 {
  z-index: 10;
  background-color: #fff;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 60px;
  right: -58px;
  box-shadow: 5px 0 10px rgba(51, 51, 51, .5);
}

.mpx-view-head {
  padding: 15px;
  display: flex;
}

.mpx-view-head.stores-rights {
  padding-left: 0;
}

.text-field-7 {
  margin-bottom: 0;
}

.form-block-9 {
  justify-content: flex-start;
  width: 400px;
  margin-bottom: 0;
  display: flex;
}

.form-block-9.select-form {
  flex: 1;
  justify-content: flex-end;
}

.div-block-85 {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.form-8 {
  flex: 1;
}

.select-field-3 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  width: 100px;
  margin-bottom: 0;
}

.select-field-4 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
}

.form-block-10 {
  flex: 0 auto;
  justify-content: flex-end;
  margin-bottom: 0;
  display: flex;
}

.store-table-container {
  max-width: 100%;
  max-height: 375px;
  position: relative;
  overflow: auto;
}

.store-pagination-form {
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  display: flex;
  position: relative;
}

.text-block-88 {
  align-items: center;
  display: flex;
  left: 0;
}

.text-block-88.stores-total {
  width: 33%;
}

.store-table-header {
  margin-bottom: 10px;
  display: flex;
  box-shadow: 0 1px 5px rgba(51, 51, 51, .5);
}

.div-block-86 {
  flex-basis: 25%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-89 {
  height: 100%;
  padding: 15px;
  font-size: 18px;
}

.div-block-87 {
  color: #fff;
  background-color: #fa3543;
  border-left: 1px solid #dedede;
  border-right: 1px #fff;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  position: static;
  top: 0;
}

.div-block-87.last {
  border-right-width: 0;
}

.div-block-88 {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dddede;
  flex-direction: column;
  flex: 1;
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 1px 1px 3px #dedede;
}

.div-block-88.header {
  z-index: 2;
  background-color: #fff;
  border-right: 1px solid #dedede;
  justify-content: center;
  width: 150px;
  height: 82px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  box-shadow: 1px 1px 3px #dcdede;
}

.div-block-89 {
  display: flex;
}

.div-block-90 {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px #dedede;
  border-left: 1px #dedede;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.div-block-90.left {
  border-left: 1px solid #dedede;
}

.store-table-body {
  position: relative;
}

.store-table-body-row {
  border: 1px solid #dedede;
  display: flex;
}

.div-block-91 {
  border-bottom: 1px solid #dddede;
  border-left: 1px solid #dddede;
  border-right: 1px #dedede;
  flex: 0 25%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-91.store {
  background-color: #fff;
  border-right-style: solid;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  box-shadow: 1px 1px 3px #dcdedf;
}

.store-name {
  cursor: pointer;
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-decoration: underline;
}

.store-table-date-cell {
  flex-basis: 25%;
  display: flex;
}

.div-block-92 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-93 {
  border-left: 1px solid #dddede;
  border-right: 1px #000;
  flex-basis: 25%;
  display: flex;
}

.div-block-94 {
  border-bottom: 1px solid #dedede;
  border-left: 1px #dcdede;
  border-right: 1px #dedede;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  display: flex;
}

.div-block-94.left {
  border-left-style: solid;
}

.service-date {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
}

.service-date-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.lock {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 24px;
  display: flex;
}

.lock.lock-open {
  color: #20a757;
}

.lock.lock-open.hidden {
  display: none;
}

.lock.lock-closed {
  color: #fd323d;
}

.lock.lock-closed.hidden {
  display: none;
}

.text-block-92 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
}

.text-block-92.store-title {
  font-weight: 700;
}

.store-info-section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.section-title {
  padding-bottom: 10px;
  font-size: 18px;
  text-decoration: underline;
}

.form-9 {
  width: 200px;
}

.form-9.form-add-email {
  display: flex;
}

.text-field-8 {
  width: 300px;
  margin-bottom: 0;
}

.text-field-8.opening-date, .text-field-8.closing-date {
  width: 200px;
}

.text-field-8.store-cce-capacity, .text-field-8.store-cce-delay {
  margin-bottom: 10px;
}

.clean-input {
  flex: none;
  margin-bottom: 0;
}

.clean-input.clean-input-opening-date {
  flex: 0 auto;
  padding-right: 30px;
}

.div-block-96 {
  align-items: center;
  padding-right: 30px;
  display: flex;
}

.div-block-96.adresse-field {
  padding-bottom: 5px;
}

.div-block-96.bemax-opening, .div-block-96.cce-opening, .div-block-96.hop-opening {
  margin-bottom: 15px;
}

.store-info-label {
  align-items: center;
  width: 300px;
  font-weight: 500;
  display: flex;
}

.textarea-4 {
  width: 300px;
  margin-bottom: 0;
}

.div-block-97 {
  padding-left: 20px;
}

.div-block-98 {
  font-size: 22px;
}

.div-block-98.disabled {
  color: rgba(51, 51, 51, .5);
  cursor: default;
}

.email-add-button {
  color: #fa3543;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
  display: flex;
}

.email-add-button.disabled {
  color: rgba(250, 53, 67, .5);
  cursor: not-allowed;
}

.div-block-99 {
  display: flex;
}

.email-list-container {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.email-list-item {
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.email-remove {
  color: #fa3543;
  cursor: pointer;
  font-family: Fontawesome, sans-serif;
  font-size: 26px;
}

.text-block-93 {
  text-decoration: underline;
}

.alert-email {
  padding-left: 20px;
  text-decoration: underline;
}

.div-block-102 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-field-9 {
  text-align: center;
  width: 100px;
  margin-bottom: 0;
}

.text-field-9.bemax-opening-from.failed, .text-field-9.bemax-opening-to.failed {
  border: 1px solid #fd323d;
}

.form-block-11 {
  margin-bottom: 0;
}

.text-block-95 {
  padding-left: 15px;
  padding-right: 15px;
}

.slot-save-button {
  z-index: 1;
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  padding: 10px 15px;
  font-size: 25px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.slot-save-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.slot-save-button:active {
  background-color: #a3040e;
}

.slot-save-button.disabled {
  cursor: default;
  background-color: rgba(250, 53, 67, .5);
}

.select-shop-field {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
  display: flex;
}

.select-shop-field.disabled {
  color: rgba(51, 51, 51, .5);
}

.select-shop-field.zone-zone-selector.disabled {
  background-image: none;
}

.select-shop-field.type-selector {
  height: 42px;
}

.form-block-12 {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.zone-header {
  align-items: flex-end;
  padding-bottom: 10px;
  display: flex;
}

.select-shop-text {
  padding-right: 15px;
  display: flex;
}

.text-block-98 {
  color: #fa3543;
  padding: 10px 20px 8px;
  font-family: Fontawesome, sans-serif;
  font-size: 35px;
}

.select-shop {
  flex: 1;
}

.select-shop.slot-form {
  flex: 0 auto;
  margin-right: 20px;
}

.text-block-99 {
  color: #fa3543;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 35px;
  display: flex;
}

.text-block-99.disabled {
  color: rgba(250, 53, 67, .5);
  cursor: default;
}

.div-block-105 {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.div-block-106 {
  border: 1px solid #999;
  flex-direction: column;
  flex-basis: 20%;
  min-width: 250px;
  padding-top: 5px;
  padding-left: 0;
  display: flex;
  position: relative;
}

.div-block-106.hidden {
  display: none;
}

.div-block-106.zone-stuff {
  flex-basis: 30%;
  padding-bottom: 200px;
  padding-left: 10px;
}

.div-block-107 {
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.div-block-107.browsing-map {
  flex: 1;
}

.text-block-100 {
  padding-bottom: 5px;
  padding-right: 20px;
  font-size: 20px;
}

.text-block-101 {
  font-size: 18px;
}

.text-field-10 {
  margin-bottom: 0;
}

.form-block-13 {
  flex: none;
  margin-bottom: 0;
}

.text-block-102 {
  background-color: rgba(166, 166, 166, .35);
  align-items: center;
  height: 40px;
  padding-left: 10px;
  font-size: 18px;
  display: flex;
}

.div-block-108 {
  width: 100%;
  padding-top: 10px;
}

.div-block-109 {
  align-items: center;
  display: flex;
}

.select-field-6 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
}

.text-block-103 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.div-block-110 {
  margin-bottom: 5px;
}

.text-field-11 {
  margin-bottom: 0;
}

.div-block-111 {
  padding: 3px 5px;
  display: block;
}

.div-block-111.modal-iris-list {
  max-height: 400px;
  overflow: scroll;
}

.bubulle {
  color: #fff;
  background-color: #fa3543;
  border-radius: 10px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 3px 5px;
  display: inline-block;
}

.bubulle.disabled {
  background-color: rgba(166, 166, 166, .6);
}

.text-block-105 {
  cursor: pointer;
  padding-right: 5px;
  font-family: Fontawesome, sans-serif;
}

.div-block-112 {
  align-items: center;
  display: flex;
}

.div-block-113 {
  justify-content: space-between;
  display: flex;
}

.div-block-113.iris-titile {
  padding-bottom: 10px;
}

.text-block-106 {
  color: #fa3543;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 25px;
}

.div-block-114 {
  background-color: #fff;
  position: fixed;
  top: 500px;
  left: 500px;
}

.div-block-115 {
  border-bottom: 20px solid #fff;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: -20px;
  left: 0;
}

.div-block-116 {
  padding: 10px;
  position: relative;
}

.text-block-107 {
  justify-content: center;
  padding-bottom: 5px;
  font-size: 20px;
  display: flex;
}

.text-block-107.zone-title {
  padding-top: 5px;
}

.text-block-107.zone-title.hidden {
  align-items: center;
  height: 26px;
  display: none;
}

.text-field-12 {
  margin-bottom: 0;
}

.text-block-108 {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  display: flex;
}

.div-block-117 {
  padding-left: 5px;
}

.capa-prep-wrapper {
  border-bottom: 5px solid #fff;
  padding-left: 5px;
  padding-right: 5px;
}

.capa-prep-wrapper.highlighted {
  background-color: rgba(253, 50, 61, .5);
}

.capa-prep-wrapper.selected {
  color: #fff;
  background-color: #227092;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.text-block-109 {
  color: #fff;
  justify-content: center;
  font-weight: 500;
  display: flex;
}

.div-block-119 {
  cursor: pointer;
  background-color: #65ca8e;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  position: relative;
}

.div-block-119.selected {
  background-color: #227092;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.div-block-119.empty-slot {
  background-color: #65ca8e;
  height: 200px;
}

.div-block-119.slot-calendar-slot {
  background-color: #65ca8e;
}

.div-block-119.slot-calendar-slot.selected {
  position: relative;
}

.div-block-120 {
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  padding: 5px;
}

.div-block-120.prep-calendar-content {
  min-height: 100px;
}

.div-block-121 {
  position: relative;
}

.div-block-121.prep-capacity-container.selected {
  color: #fff;
  background-color: #227092;
}

.div-block-122 {
  flex: 1;
  padding-right: 5px;
}

.text-span, .text-span-2, .text-span-3, .text-span-4, .text-span-5, .text-block-110, .text-span-6, .text-span-7, .text-span-8 {
  font-weight: 500;
}

.text-block-111 {
  cursor: pointer;
  padding: 5px;
  font-family: "Fa solid 900", sans-serif;
  position: absolute;
  right: 0;
}

.text-block-111.prep-edit-button:hover {
  color: #fd323d;
}

.select-field-7 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
}

.form-block-14 {
  flex: 1;
  margin-bottom: 0;
}

.text-block-112, .text-block-113 {
  padding-right: 10px;
}

.text-field-13, .form-block-15 {
  margin-bottom: 0;
}

.text-field-14 {
  text-align: left;
  width: 100px;
  margin-bottom: 0;
}

.form-block-16 {
  margin-bottom: 0;
}

.div-block-123 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.div-block-124 {
  align-items: center;
  display: flex;
  position: relative;
}

.text-block-114 {
  color: #9a9a9a;
  padding-right: 10px;
  font-size: 20px;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
}

.div-block-125 {
  display: flex;
}

.select-wrapper {
  cursor: pointer;
  display: flex;
}

.text-block-115 {
  font-weight: 500;
}

.text-field-15, .form-block-17 {
  margin-bottom: 0;
}

.div-block-126 {
  align-items: center;
  padding-bottom: 5px;
  display: flex;
}

.text-block-116 {
  width: 200px;
  padding-right: 10px;
}

.text-block-117 {
  font-weight: 500;
}

.div-block-127 {
  padding-top: 10px;
}

.batch-store-list-item {
  padding-left: 10px;
  display: flex;
}

.text-block-118 {
  align-items: center;
  padding-left: 10px;
  display: flex;
}

.div-block-129 {
  height: 130px;
  overflow: auto;
}

.div-block-130 {
  flex: 1;
  display: flex;
}

.div-block-131 {
  justify-content: flex-end;
  align-items: center;
  width: 33%;
  display: flex;
  right: 0;
}

.text-block-119 {
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  font-family: "Fa solid 900", sans-serif;
  display: flex;
}

.text-block-119:hover {
  background-color: #ff7b82;
}

.text-block-119:active {
  background-color: #a3040e;
}

.text-block-119.disabled {
  cursor: default;
  background-color: rgba(250, 53, 67, .5);
}

.text-block-119.batch-edit-button {
  margin-right: 30px;
}

.text-block-119.store-shelves-add-group-button, .text-block-119.store-shelves-add-button {
  width: auto;
  padding-left: 3px;
  padding-right: 3px;
  font-family: Roboto, sans-serif;
}

.div-block-132 {
  padding-bottom: 10px;
  font-weight: 500;
}

.div-block-133 {
  align-items: center;
  padding-left: 20px;
  display: flex;
}

.text-block-120 {
  padding-right: 10px;
}

.text-block-121 {
  font-size: 18px;
  font-weight: 200;
}

.div-block-134 {
  align-items: center;
  padding-bottom: 10px;
  display: flex;
}

.div-block-135 {
  color: #333;
  border-radius: 10px;
  align-items: center;
  height: 40px;
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: underline;
  display: flex;
}

.div-block-135.active {
  color: #fff;
  background-color: #fa3543;
  text-decoration: none;
  box-shadow: 0 1px 10px rgba(51, 51, 51, .5);
}

.div-block-136 {
  display: flex;
}

.div-block-137 {
  width: 20px;
}

.div-block-138 {
  display: flex;
}

.slot-prep {
  width: 20px;
}

.div-block-139 {
  display: flex;
}

.div-block-141 {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  padding-right: 5px;
  display: flex;
}

.div-block-142 {
  border-bottom: 2px solid #333;
  flex: 1;
  height: 0;
}

.text-block-122 {
  padding-left: 5px;
  padding-right: 5px;
}

.div-block-143 {
  width: 15px;
  margin-bottom: 5px;
}

.div-block-143.highlighted {
  background-color: rgba(253, 50, 61, .5);
}

.div-block-144 {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.div-block-145 {
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.text-block-123 {
  padding-left: 10px;
  padding-right: 10px;
}

.div-block-146 {
  align-items: flex-start;
  display: flex;
}

.div-block-147 {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.div-block-148 {
  display: flex;
}

.select-field-8 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
}

.form-block-18 {
  margin-bottom: 0;
}

.text-block-124 {
  color: #fff;
  background-color: #fd323d;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.text-block-124.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.slots-modal {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.slots-modal.hidden {
  display: none;
}

.div-block-149 {
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.text-block-125 {
  color: #fd323d;
  cursor: pointer;
  justify-content: flex-end;
  width: 20px;
  height: 20px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.text-block-125.delete-slot:hover {
  color: rgba(250, 53, 67, .69);
}

.text-block-125.delete-slot:active {
  color: #a3040e;
}

.text-block-126 {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.text-block-126.slot-edit-panel-close {
  cursor: pointer;
}

.div-block-150 {
  flex-direction: column;
  min-width: 250px;
  min-height: 300px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 5px;
  display: flex;
}

.div-block-151 {
  justify-content: flex-end;
  display: flex;
}

.div-block-152 {
  flex-basis: 20%;
}

.div-block-152.minimize {
  flex: 0 auto;
}

.div-block-153 {
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  display: flex;
}

.text-block-127 {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 16px;
  display: flex;
}

.text-span-9, .text-span-10 {
  font-weight: 500;
}

.div-block-154 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-128 {
  color: #fff;
  cursor: pointer;
  background-color: rgba(253, 50, 61, .5);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 3px 8px;
  display: flex;
}

.text-block-128.selected {
  background-color: #fd323d;
  font-weight: 400;
}

.div-block-155 {
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  display: flex;
}

.text-field-16 {
  margin-bottom: 0;
}

.form-block-19 {
  flex: 1;
  margin-bottom: 0;
}

.text-block-129 {
  padding-right: 10px;
}

.text-block-130 {
  padding-left: 10px;
  padding-right: 10px;
}

.div-block-156 {
  align-items: center;
  display: flex;
}

.div-block-157 {
  padding-bottom: 20px;
}

.div-block-158 {
  z-index: 1;
  background-color: #fff;
  padding: 10px 5px;
  display: flex;
  position: absolute;
  top: 320px;
  left: 420px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.div-block-160 {
  border-bottom: 15px solid #fff;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: -15px;
  left: 0;
}

.div-block-161 {
  padding-left: 20px;
}

.text-block-131 {
  padding-bottom: 10px;
}

.div-block-162 {
  display: flex;
}

.div-block-163 {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  display: flex;
}

.text-block-132 {
  color: #fd323d;
  cursor: pointer;
  font-family: "Fa solid 900", sans-serif;
  font-size: 30px;
}

.div-block-164 {
  padding-right: 10px;
  display: flex;
}

.mission-preparer-select, .search-input {
  margin-bottom: 0;
}

.missions-content-wrapper {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.missions-tabs {
  justify-content: center;
  align-items: stretch;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.missions-button {
  color: #fff;
  background-color: #227092;
}

.missions-button.grid-action-button.missions-search-button {
  margin-left: 10px;
}

.missions-button.grid-action-button.missions-search-button:hover {
  background-color: rgba(34, 112, 146, .9);
}

.missions-button.grid-action-button.clear-date-button {
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: auto;
  height: 38px;
  margin-left: 0;
  padding: 0 0 0 2px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
  display: flex;
}

.missions-button.grid-action-button.missions-reset-button:hover {
  background-color: rgba(34, 112, 146, .9);
}

.missions-button.grid-action-button.control-report-btn {
  flex: 1;
}

.missions-button.grid-action-button.control-report-btn:hover {
  background-color: rgba(34, 112, 146, .9);
}

.search-form-inputs {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.missions-input-wrapper {
  flex: none;
  align-items: center;
  width: 48%;
  margin-bottom: 10px;
  display: flex;
}

.missions-input-wrapper.status-line.hidden {
  display: none;
}

.missions-collect-table, .missions-expedition-table, .missions-all-table {
  margin-bottom: 10px;
}

.div-block-165 {
  width: 50px;
  height: 38px;
  font-family: Fontawesome, sans-serif;
}

.clean-icon {
  color: #fff;
  padding-left: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 17px;
  display: inline-block;
}

.row-wrapper {
  flex: 1;
  justify-content: flex-start;
  min-height: 50px;
  display: flex;
}

.text-block-133 {
  font-family: "Fa solid 900", sans-serif;
}

.div-block-166 {
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  position: relative;
}

.div-block-166.capa-deliv-wrapper.selected {
  color: #fff;
  background-color: #227092;
}

.text-field-17 {
  width: 150px;
  margin-bottom: 0;
}

.text-field-17.cut-off-hour-input.failed, .text-field-17.mad-hour-input.failed {
  border: 1px solid #fd323d;
}

.form-block-20 {
  margin-bottom: 0;
}

.form-10 {
  display: flex;
}

.select-field-9 {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  margin-bottom: 0;
  margin-right: 5px;
}

.select-field-9.cut-off-day-select, .select-field-9.mad-day-select {
  flex: 1;
  height: 40px;
}

.div-block-167 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.div-block-167._1 {
  margin-top: 15px;
}

.div-block-168 {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.text-block-134 {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  align-items: center;
  margin-left: 10px;
  padding: 8px 10px;
  font-size: 18px;
  display: flex;
}

.text-block-134.slot-planning-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.slot-planning-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.text-block-134.slot-edit-validate {
  cursor: pointer;
  justify-content: center;
  margin-top: 22px;
  margin-left: 0;
}

.text-block-134.slot-edit-validate:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.slot-edit-validate:active {
  background-color: #a3040e;
}

.text-block-134.slot-edit-validate.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.text-block-134.slots-deploy-back:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.slots-deploy-back:active {
  background-color: #a3040e;
}

.text-block-134.slots-deploy-submit {
  background-color: #fd323d;
}

.text-block-134.slots-deploy-submit:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.slots-deploy-submit:active {
  background-color: #a3040e;
}

.text-block-134.slots-deploy-submit.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.text-block-134.slots-deploy:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.slots-deploy.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.text-block-134.slots-model-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.slots-model-button:active {
  background-color: #a3040e;
}

.text-block-134.slots-model-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.text-block-134.slots-prep-deploy.disabled {
  background-color: rgba(253, 50, 61, .5);
}

.text-block-134.save-button {
  cursor: pointer;
  justify-content: center;
  margin-top: 5px;
  margin-left: 0;
}

.text-block-134.save-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.save-button:active {
  background-color: #a3040e;
}

.text-block-134.save-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.text-block-134.client-view:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-134.client-view.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.div-block-169 {
  align-items: center;
  display: flex;
}

.text-block-135 {
  padding-right: 10px;
}

.text-block-136 {
  color: #fff;
  background-color: #fd323d;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 18px;
  display: flex;
}

.text-block-136.hidden {
  display: none;
}

.text-block-136.new-slot {
  cursor: pointer;
}

.text-block-136.new-slot:hover {
  background-color: rgba(250, 53, 67, .69);
}

.text-block-136.new-slot:active {
  background-color: #a3040e;
}

.text-block-137 {
  font-size: 16px;
  font-weight: 500;
}

.div-block-170 {
  align-items: center;
  display: flex;
}

.text-block-138 {
  padding-right: 20px;
}

.text-field-18, .form-block-21 {
  margin-bottom: 0;
}

.div-block-171 {
  align-items: center;
  padding-bottom: 10px;
  display: flex;
}

.div-block-171.hidden {
  display: none;
}

.div-block-172 {
  padding-left: 20px;
}

.text-block-139 {
  padding-left: 20px;
  padding-right: 20px;
}

.text-block-140 {
  text-align: center;
}

.div-block-173 {
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  display: flex;
}

.div-block-174 {
  align-items: center;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.div-block-174.hidden {
  display: none;
}

.div-block-175 {
  flex: 1;
}

.div-block-176 {
  padding-left: 10px;
  display: block;
}

.closure-list-elem {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.closure-list-elem.slots-timeline-item {
  padding-left: 15px;
}

.div-block-178 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.div-block-179 {
  background-color: #fd323d;
  flex: none;
  width: 10px;
  height: 100%;
  position: absolute;
}

.div-block-180 {
  align-items: stretch;
  min-height: 40px;
  display: flex;
}

.div-block-181 {
  background-color: #fd323d;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.div-block-182 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  position: relative;
}

.div-block-183 {
  flex: 1;
  width: 10px;
}

.div-block-184 {
  background-color: #fd323d;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
}

.slots-timeline-item-content {
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.slots-timeline-item-content.slots-timeline-header {
  font-size: 18px;
  font-weight: 700;
}

.delete-item {
  color: #fd323d;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 18px;
  display: flex;
}

.delete-item:hover {
  color: rgba(250, 53, 67, .69);
}

.closure-list {
  padding-top: 20px;
  padding-bottom: 20px;
}

.closure-list.slots-timeline {
  border: 1px solid #999;
  flex: 1;
  max-height: 150px;
  padding-bottom: 0;
  overflow: scroll;
}

.div-block-186 {
  justify-content: flex-end;
  display: flex;
}

.add-closure-button {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.add-closure-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.add-closure-button:active {
  background-color: #a3040e;
}

.add-closure-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.add-closure-button.exception {
  border-radius: 51%;
}

.text-field-19 {
  width: 300px;
  margin-bottom: 0;
}

.form-block-22 {
  margin-bottom: 0;
}

.div-block-187 {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.div-block-188 {
  margin-top: 17px;
  margin-bottom: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.text-field-20, .form-block-23 {
  margin-bottom: 0;
}

.form-block-23.date-hour {
  width: 100%;
}

.bubulle-text {
  align-items: center;
  display: flex;
}

.text-block-144 {
  padding-right: 20px;
  display: flex;
}

.text-block-145 {
  padding-left: 20px;
  padding-right: 20px;
}

.div-block-190 {
  align-items: center;
  display: flex;
}

.text-block-146 {
  padding-left: 20px;
  font-weight: 500;
}

.div-block-191 {
  border: 1px solid #ccc;
  max-width: 500px;
  max-height: 300px;
  overflow: auto;
}

.div-block-192 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.zone-block {
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  display: flex;
}

.zone-block-content {
  align-items: center;
  padding-left: 20px;
  display: flex;
}

.zone-block-content-content {
  flex-direction: column;
  max-width: 800px;
  display: flex;
}

.zone-title-text {
  color: #fff;
  background-color: #fd323d;
  border-radius: 10px;
  padding: 5px 7px;
}

.zone-title-text.disabled {
  background-color: rgba(166, 166, 166, .6);
}

.zone-title-text.all {
  margin-left: 5px;
}

.zone-title-text.all.disable {
  background-color: rgba(166, 166, 166, .6);
}

.zone-title {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
}

.div-block-197 {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.div-block-197.slot-from-hour-section.kbrhidden, .div-block-197.slot-from-hour-section.hidden {
  display: none;
}

.text-block-148 {
  padding-right: 20px;
}

.div-block-198 {
  display: flex;
}

.orders-content-wrapper {
  padding-top: 15px;
}

.orders-search-form-inputs {
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: stretch;
}

.orders-input-wrapper {
  flex: none;
  align-items: center;
  width: 48%;
  margin-bottom: 10px;
  display: flex;
}

.orders-input-wrapper.status-line.hidden {
  display: none;
}

.orders-grid {
  min-width: 1000px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.result_search {
  font-weight: 700;
  display: inline-block;
}

.form-11 {
  align-items: center;
  display: flex;
}

.text-block-149 {
  margin-right: 5px;
}

.div-block-199 {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.div-block-200 {
  background-color: #227092;
}

.missings_from {
  flex: 0 auto;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
}

.missings_to {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
}

.missings-modal-row {
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.list-missingp-date-to, .list-missingp-to {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
}

.div-block-201 {
  flex-flow: wrap;
  flex: 1;
  align-content: stretch;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.form-block-24 {
  margin-bottom: 0;
}

.menu-display-button {
  z-index: 2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  top: 67px;
  right: 401px;
}

.mode-selected {
  color: #fff;
  background-color: #fd323d;
}

.menu-container {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 0;
}

.menu-container.transition {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: -400px;
}

.block-chips {
  display: flex;
}

.chip {
  background-color: #fff;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.card-container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: 5px;
  flex-flow: wrap;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: center;
  max-height: 90px;
  margin-top: 5px;
  padding: 5px 2px;
  display: flex;
  overflow: auto;
}

.ug-content {
  margin-top: 10px;
}

.card {
  background-color: #ff7b82;
  border-radius: 10px;
  align-items: center;
  min-height: 28px;
  margin: 2px;
  padding: 2px 2px 2px 10px;
  display: flex;
}

.card:hover {
  background-color: rgba(255, 123, 130, .8);
}

.card-content {
  color: #fff;
  margin-right: 4px;
  font-size: 12px;
  font-weight: 400;
}

.card-delete-button {
  color: #fff;
  cursor: pointer;
  padding: 2px;
  font-family: Materialicons, sans-serif;
}

.card-delete-button:hover {
  color: #fd323d;
}

.qty-form-container {
  margin-top: 20px;
}

.order-preview-button {
  cursor: pointer;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 5px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.order-preview-button:hover {
  color: rgba(48, 48, 48, .8);
}

.order-preview-button.hidden {
  display: none;
}

.order-content-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1330px;
  max-height: 100vh;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.order-content-modal.hidden {
  display: none;
}

.receipt-modal-array {
  background-color: #fff;
  flex-direction: column;
  flex: auto;
  width: 100%;
  min-width: 850px;
  max-width: 1250px;
  margin-bottom: 20px;
}

.receipt-modal-array.spe-ug {
  width: 25vw;
  min-width: 400px;
  max-width: 500px;
}

.history-modal-content-header {
  color: #656565;
  background-color: rgba(0, 0, 0, .07);
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
}

.history-modal-content-header.mission-nbr {
  justify-content: flex-start;
}

.receipt-modal-array-row {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  justify-content: center;
  min-height: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
}

.receipt-modal-array-row:hover {
  background-color: rgba(0, 0, 0, .03);
}

.receipt-modal-array-row.header {
  padding-top: 14px;
}

.receipt-modal-array-col {
  text-transform: none;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  padding-left: 20px;
  font-weight: 400;
  display: flex;
}

.receipt-modal-array-col.quantity {
  flex: 1;
  width: 100px;
  min-width: 100px;
}

.receipt-modal-array-col.name {
  flex: none;
  width: 150px;
}

.receipt-modal-array-col.name.content-centered {
  justify-content: center;
  padding-left: 0;
}

.receipt-modal-array-col.image {
  flex: none;
  justify-content: flex-start;
  width: 115px;
}

.receipt-modal-array-col.brand {
  width: 150px;
}

.receipt-modal-array-col.ean {
  width: 170px;
}

.receipt-modal-array-col.spe-ug {
  padding-left: 0;
}

.receipt-modal-array-col.size {
  flex: none;
  width: 70px;
  min-width: 70px;
}

.receipt-modal-array-col.size {
  width: 75px;
  min-width: 75px;
}

.receipt-modal-array-col.color {
  width: 120px;
}

.receipt-modal-array-col.shelf {
  border-top-style: none;
  width: 120px;
  height: auto;
}

.receipt-modal-array-col.color-copy {
  width: 150px;
}

.receipt-modal-array-col.shelf {
  background-color: rgba(0, 0, 0, 0);
  width: 120px;
}

.receipt-modal-array-col.theme {
  width: 130px;
}

.receipt-modal-array-col.category, .receipt-modal-array-col.color-copy {
  width: 120px;
}

.receipt-modal-array-col.unused-ug {
  padding-left: 0;
}

.receipt-modal-array-col.unused-ug.aisle-name {
  justify-content: center;
}

.receipt-modal-array-content {
  height: 50vh;
  min-height: 50px;
  max-height: 180px;
  overflow: auto;
}

.order-image {
  max-width: 100px;
  max-height: 75px;
}

.image-container {
  flex: 1;
}

.printslip-bag-catleft, .printslip-tray-catleft {
  color: #8b445c;
  border-right: 1px solid #8b445c;
  flex: 1;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 16px;
  display: flex;
}

.printslip-tray-catline, .printslip-bag-catline {
  border-bottom: 1px solid rgba(139, 68, 92, .36);
  justify-content: space-between;
  display: flex;
}

.content-container-content {
  padding: 15px 15px 30px;
  position: relative;
}

.div-block-202 {
  justify-content: space-between;
  display: flex;
}

.div-block-202.iris-titile {
  padding-bottom: 10px;
}

.text-field-21 {
  margin-bottom: 0;
}

.form-12 {
  position: relative;
}

.store-selector-drawer {
  z-index: 1;
  background-color: #fff;
  width: 100%;
  min-height: 20px;
  max-height: 200px;
  position: absolute;
  overflow: scroll;
  box-shadow: 0 3px 10px rgba(51, 51, 51, .5);
}

.text-block-150 {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  overflow: auto;
}

.text-block-150:hover {
  color: #fff;
  background-color: rgba(250, 53, 67, .69);
}

.text-block-150.store-selector-item {
  color: #000;
  height: 30px;
}

.text-block-150.store-selector-item:hover {
  color: #fff;
}

.store-delivered-by {
  width: 400px;
}

.modal-content-order {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

.modal-content-order.hidden {
  display: none;
}

.text-block-151 {
  margin-bottom: 5px;
}

.text-block-152 {
  margin-top: 5px;
}

.result_search_top {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 700;
  display: inline-block;
}

.result_search_bottom {
  margin-top: 5px;
  font-weight: 700;
  display: inline-block;
}

.ug-row {
  background-color: #fff;
  border-top: 1px solid #d1d1d1;
  justify-content: space-between;
  height: 50px;
  display: flex;
}

.ug-row:hover {
  background-color: #f2f2f2;
}

.user-details-icon {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 5px;
  font-family: Fontawesome, sans-serif;
  font-size: 20px;
  display: flex;
}

.user-details-icon:hover {
  color: rgba(48, 48, 48, .8);
}

.div-block-203 {
  justify-content: space-between;
  display: flex;
}

.user-details-row {
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.user-details-label {
  text-align: right;
  flex: 0 auto;
  width: 35%;
  margin-right: 20px;
  font-weight: 500;
}

.text-block-154, .user-details-value {
  flex: 1;
}

.user-details-zip {
  margin-right: 15px;
  display: inline-block;
}

.user-details-city {
  display: inline-block;
}

.ug-view-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 25vw;
  min-width: 450px;
  max-width: 720px;
  max-height: 100vh;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.ug-view-modal.hidden {
  display: none;
}

.text-block-155 {
  padding-left: 20px;
}

.div-block-204 {
  max-height: 100px;
  overflow: scroll;
}

.div-block-205 {
  display: flex;
}

.text-field-22 {
  color: #000;
  margin-bottom: 0;
}

.form-block-25 {
  width: 300px;
  margin-bottom: 0;
}

.form-13 {
  display: flex;
}

.form-14 {
  position: relative;
}

.text-block-156 {
  align-items: center;
  padding-right: 3px;
  font-size: 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.modal-iris-error {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.modal-iris-error-message {
  color: #fd323d;
  padding-bottom: 10px;
  font-size: 16px;
}

.modal-iris-error-actions {
  justify-content: space-around;
  display: flex;
}

.modal-iris-error-list {
  color: #fd323d;
  font-size: 16px;
}

.modal-iris-error-list-item {
  padding-top: 2px;
  padding-bottom: 2px;
}

.div-block-206 {
  position: relative;
}

.delete-zone {
  color: #fd323d;
  cursor: pointer;
  align-items: center;
  width: 35px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.carier-name {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
}

.iris-table-header {
  color: #fff;
  background-color: #fd323d;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .24), 0 0 3px rgba(0, 0, 0, .12);
}

.iris-col {
  flex: 1;
}

.iris-col.iris-table-col-4 {
  flex: 0 0 200px;
}

.iris-col.iris-table-col-4.last {
  border-right-style: none;
}

.iris-col.iris-col-header {
  border-right: 1px solid #fff;
}

.iris-col.iris-col-1 {
  flex: none;
  width: 140px;
}

.iris-col.iris-col-4 {
  flex: none;
  width: 150px;
}

.iris-col.iris-col-0 {
  flex: none;
  width: 30px;
}

.iris-col.iris-col-5 {
  flex: 0 0 150px;
}

.iris-col.iris-col-7 {
  flex: 1;
}

.text-block-157, .iris-col-content {
  align-items: center;
  display: flex;
}

.iris-header-col-content {
  border-right: 1px solid #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.iris-header-col-content.last {
  border-right-style: none;
}

.iris-header-col-content.first {
  border-style: none;
}

.iris-table-body {
  overflow: visible;
}

.iris-table-row {
  border-bottom: 1px solid #999;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  display: flex;
}

.iris-row-col {
  border-right: 1px solid #999;
  flex: 1;
}

.iris-row-col.iris-table-col-4.last {
  border-right-style: none;
}

.iris-row-content {
  border-right: 1px solid #999;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2px 10px;
  display: flex;
  overflow: hidden;
}

.iris-row-content.last {
  border-style: none;
}

.div-block-207.iris-search {
  padding-top: 10px;
}

.text-block-158 {
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.iris-search-button {
  color: #fff;
  background-color: #fd323d;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-family: "Fa solid 900", sans-serif;
  display: flex;
}

.div-block-208 {
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
}

.iris-add-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #fd323d;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

.iris-add-button.disabled {
  cursor: default;
  background-color: rgba(253, 50, 61, .5);
  height: 100%;
}

.div-block-209 {
  display: flex;
}

.iris-search-total {
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.zone-iris-total {
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.text-block-159 {
  color: #fd323d;
  cursor: pointer;
  font-family: "Fa solid 900", sans-serif;
}

.div-block-210 {
  overflow: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.div-block-210.iris-zone-table, .div-block-210.iris-zone-search-table, .div-block-210.postcode-zone-search-table {
  overflow: auto;
}

.div-block-211 {
  height: 180px;
  overflow: visible;
}

.iris-search-loader {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.iris-search-loader.hidden {
  display: none;
}

.remove-iris {
  color: #fd323d;
  cursor: pointer;
  font-family: "Fa solid 900", sans-serif;
}

.iris-search-error {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.iris-search-error.hidden {
  display: none;
}

.iris-error {
  color: #fd323d;
}

.header-store-form {
  margin-bottom: 0;
}

.header-store {
  flex: 1;
  margin-bottom: 0;
}

.header-username {
  text-transform: uppercase;
}

.header-store-wrapper {
  flex: 1;
  margin-left: 15px;
}

.slot-calendar-container {
  flex: 1;
}

.div-block-212 {
  display: flex;
}

.text-block-160 {
  margin-left: 3px;
}

.slot-calendar-prop-title {
  font-weight: 500;
}

.div-block-213 {
  flex: 1;
}

.div-block-214, .div-block-215 {
  display: flex;
}

.div-block-215.slot-calendar-prep.hidden {
  display: none;
}

.div-block-216, .div-block-217 {
  flex: 1;
}

.div-block-218 {
  flex: 1;
  margin-bottom: 5px;
  margin-right: 5px;
}

.div-block-218.slot-calendar-prep-day {
  background-color: #b3b3b3;
}

.div-block-218.slot-calendar-prep-day.closure {
  color: rgba(51, 51, 51, .6);
  background-color: rgba(179, 179, 179, .44);
}

.div-block-219 {
  flex: 1;
  padding-right: 5px;
  display: flex;
}

.div-block-220, .div-block-221, .div-block-223 {
  display: flex;
}

.text-block-161 {
  width: 30px;
  font-weight: 500;
}

.text-block-162 {
  padding-left: 3px;
}

.div-block-224 {
  display: flex;
}

.text-block-163 {
  width: 55px;
  font-weight: 500;
}

.text-block-164 {
  padding-left: 3px;
}

.slot-exception {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}

.slot-exception:hover {
  background-color: #ff7b82;
}

.slot-exception:active {
  background-color: #a3040e;
}

.text-block-165 {
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Fa solid 900", sans-serif;
}

.slot-exception-text {
  max-width: 150px;
  overflow: hidden;
}

.text-block-166 {
  align-items: flex-end;
  display: flex;
}

.div-block-225 {
  padding-bottom: 10px;
  display: flex;
}

.div-block-226 {
  border: 1px solid #999;
  flex: 1;
  max-height: 150px;
  margin-bottom: 10px;
  overflow: scroll;
}

.div-block-227 {
  padding-top: 20px;
  padding-left: 5px;
}

.div-block-228 {
  border: 1px solid #333;
  flex-direction: column;
  flex-basis: 20%;
  padding-top: 5px;
  padding-left: 0;
  display: flex;
  position: relative;
}

.slot-calendar-wrapper {
  flex: 1;
}

.slot-edit-wrapper {
  flex-basis: 20%;
}

.div-block-229 {
  width: 50%;
  padding-right: 10px;
}

.div-block-230 {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.edit-item {
  color: #fd323d;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 18px;
  display: flex;
}

.edit-item:hover {
  color: rgba(250, 53, 67, .69);
}

.edit-item:active {
  color: #a3040e;
}

.select-service {
  flex: 1;
}

.select-service.slot-form {
  flex: 0 auto;
  margin-right: 20px;
}

.select-zone {
  flex: 1;
}

.select-zone.slot-form {
  flex: 0 auto;
  margin-right: 20px;
}

.select-service-text, .select-zone-text {
  padding-right: 15px;
}

.select-service-field {
  margin-bottom: 0;
  display: flex;
}

.select-service-field.disabled {
  color: rgba(51, 51, 51, .5);
}

.select-zone-field {
  margin-bottom: 0;
  display: flex;
}

.select-zone-field.disabled {
  color: rgba(51, 51, 51, .5);
}

.closure-wrapper {
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
}

.closure-editor-wrapper {
  border-left: 1px solid #000;
  padding: 20px;
}

.closure-services {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.from-date {
  margin-bottom: 0;
}

.from-date.failed {
  border: 1px solid #fd323d;
}

.to-date {
  margin-bottom: 0;
}

.to-date.failed {
  border: 1px solid #fd323d;
}

.from-hour {
  width: 100px;
  height: 40px;
}

.from-hour.failed {
  border: 1px solid #fd323d;
}

.to-hour {
  width: 100px;
  height: 40px;
  margin-bottom: 0;
}

.to-hour.failed {
  border: 1px solid #fd323d;
}

.closures-edit-serviceline {
  align-items: center;
  display: flex;
}

.closures-edit-serviceline-text {
  padding-left: 20px;
  font-weight: 500;
}

.input-closure-name {
  width: 300px;
  margin-bottom: 0;
  margin-left: 20px;
}

.closure-list-wrapper {
  flex: 1;
}

.dropdown-content {
  z-index: 100;
  background-color: #fd323d;
  width: 120px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  position: relative;
  top: 17px;
}

.dropdown-line {
  justify-content: center;
  width: 100px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.dropdown-line:hover {
  text-decoration: underline;
}

.closure-store {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.header-info-div {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.mass-store {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 50vw;
}

.closure-store-line {
  display: flex;
}

.closure-store-line:hover {
  background-color: #eee;
}

.closure-store-selected-line {
  justify-content: flex-start;
  display: flex;
}

.closure-store-selected-line:hover {
  background-color: #eee;
}

.closure-remove-store {
  color: #fa3543;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.closure-remove-store.hidden {
  display: none;
}

.closure-store-name {
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 15px;
  display: flex;
}

.div-block-231 {
  width: 50vw;
}

.mass-store-wrapper {
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 50vw;
  max-height: 150px;
  position: relative;
  overflow: auto;
  box-shadow: 1px 1px 3px #ccc;
}

.selected-store-wrapper {
  border: 1px solid #ccc;
  width: 50vw;
  max-height: 30vh;
  margin-top: 20px;
  overflow: auto;
}

.control-report.inline-info {
  margin-bottom: 10px;
  display: flex;
}

.control-report.info-label {
  margin-right: 10px;
  font-weight: 700;
}

.control-report.info-label.comment-label {
  margin-bottom: 0;
}

.control-report.order-id, .control-report.provision-date, .control-report.controler-name, .control-report.control-date, .control-report.mission-id {
  flex: 1;
}

.control-report.mission-info {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
}

.control-report.controler-comments {
  margin-top: 30px;
}

.control-report.control-report-wrapper {
  margin-bottom: 20px;
}

.control-report.control-comment-content {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  flex-wrap: wrap;
  min-height: 50px;
  padding: 10px;
  display: flex;
}

.control-report.erroneous-products {
  max-width: 100%;
}

.control-report.erroneous-products.line {
  margin-top: 10px;
  margin-bottom: 30px;
}

.control-report.erroneous-products.product-report-list {
  background-color: #fff;
  padding-left: 20px;
}

.control-report.erroneous-products.product-line {
  margin-bottom: 5px;
  display: flex;
}

.control-report.erroneous-products.ean {
  margin-right: 20px;
}

.control-report.erroneous-products.error-col {
  width: 20%;
  display: flex;
}

.control-report.erroneous-products.error-col.name-col {
  width: 40%;
}

.control-report.erroneous-products.error-col.brand-col {
  width: 30%;
}

.control-report.erroneous-products.error-col.qty-col {
  width: 10%;
}

.control-report.erroneous-products.products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.control-report.erroneous-products.header-col {
  justify-content: flex-start;
  width: 20%;
}

.control-report.erroneous-products.header-col.name-col {
  width: 40%;
}

.control-report.erroneous-products.header-col.brand-col {
  width: 30%;
}

.control-report.erroneous-products.header-col.qty-col {
  width: 10%;
}

.control-report.erroneous-products.erroneous-product-places-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.control-report.erroneous-bacs {
  max-width: 100%;
}

.control-report.erroneous-bacs.wrapper {
  padding-left: 5px;
}

.control-report.erroneous-bacs.line {
  margin-bottom: 20px;
}

.control-report.erroneous-bacs.line.bac-declaration-errors {
  margin-top: 10px;
}

.control-report.erroneous-bacs.bac-line {
  margin-bottom: 5px;
}

.control-report.erroneous-bacs.bac-declaration-errors.bac-report-list {
  padding-left: 20px;
}

.control-report.erroneous-product-places {
  max-width: 100%;
}

.control-report.erroneous-product-places.line {
  margin-bottom: 5px;
  display: flex;
}

.control-report.erroneous-product-places.error-col {
  width: 20%;
  display: flex;
}

.control-report.erroneous-product-places.error-col.declared-bac-col {
  display: flex;
}

.control-report.erroneous-product-places.error-col.qty-error {
  flex-wrap: wrap;
  width: 5%;
}

.control-report.erroneous-product-places.error-col.name-col {
  width: 35%;
}

.control-report.erroneous-product-places.wrapper {
  padding-left: 20px;
}

.control-report.erroneous-product-places.header-col {
  width: 20%;
}

.control-report.erroneous-product-places.header-col.name-col {
  width: 35%;
}

.control-report.report-content-wrapper {
  margin-bottom: 15px;
}

.control-report.control-report-content {
  background-color: #fff;
  border: 2px solid #227092;
  border-radius: 5px;
  padding: 10px;
}

.control-report.preparer-list {
  flex: 1;
}

.control-report.mission-info-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

.control-report.dlc-checked {
  flex: 1;
}

.control-report.dlc-ok {
  color: #20a757;
  font-family: Fontawesome, sans-serif;
}

.control-report.dlc-ko {
  color: red;
  font-family: Fontawesome, sans-serif;
}

.control-report.order-products {
  margin-top: 10px;
}

.control-report.order-products-toggle {
  cursor: pointer;
  display: flex;
}

.control-report.order-products-wrapper {
  border: 2px #227092;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.control-report.order-products-toggle-wrapper {
  display: flex;
}

.control-report.toggle-icon {
  color: #227092;
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
}

.control-report.order-products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  border-bottom: 1px solid #dedede;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
}

.control-report.order-products-col {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  display: flex;
}

.control-report.order-products-col.img-col {
  flex: 0 auto;
  width: 100px;
}

.control-report.order-products-row {
  border-bottom: 1px solid rgba(153, 153, 153, .79);
  min-height: 50px;
  display: flex;
}

.control-report.order-products-array {
  background-color: #fff;
}

.control-report.order-products-array.brand {
  text-align: center;
  text-transform: none;
  flex: 0 auto;
  display: block;
}

.control-report.order-products-array.img {
  flex: 1;
}

.control-report.print-btn-wrapper {
  justify-content: flex-end;
  display: flex;
}

.control-report.container {
  padding: 20px 30px;
}

.control-report.store {
  flex: 1;
}

.text-block-167 {
  margin-right: 5px;
}

.div-block-233 {
  display: flex;
}

.text-block-168, .text-block-169, .text-block-170, .text-block-171 {
  margin-right: 5px;
}

.text-block-172, .text-block-173 {
  margin-bottom: 5px;
}

.text-block-174 {
  margin-right: 5px;
}

.text-block-175 {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.text-block-176 {
  margin-right: 5px;
}

.service-name-text {
  margin-top: 10px;
  margin-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 700;
}

.zone-array {
  flex-direction: column;
  flex: 1;
  height: 210px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.zone-array-content {
  border: 1px solid #ccc;
  min-height: 30px;
  max-height: 180px;
  overflow: auto;
}

.zone-array-line-title {
  height: 30px;
  padding-left: 10px;
  display: flex;
}

.zone-array-cell.commune {
  flex: 1;
  padding-top: 5px;
  padding-right: 5px;
}

.zone-array-cell.commune.title {
  flex: 0 auto;
  padding-right: 5px;
}

.zone-array-cell.collumn2 {
  flex: none;
  justify-content: center;
  width: 101px;
  font-size: 18px;
  display: flex;
}

.zone-array-cell.collumn2.title {
  padding-right: 17px;
  font-size: 14px;
}

.zone-array-cell.nom {
  flex: 1;
  padding-top: 5px;
  overflow: hidden;
}

.zone-array-cell.nom.selected {
  color: #fff;
}

.zone-array-cell.commune {
  flex: 0 auto;
  width: 25%;
  padding-top: 5px;
  padding-right: 5px;
  overflow: hidden;
}

.zone-array-cell.commune.selected {
  color: #fff;
}

.zone-array-cell.collumn1 {
  width: 25%;
  padding-top: 5px;
  padding-right: 5px;
  overflow: hidden;
}

.zone-array-cell.collumn1.selected {
  color: #fff;
}

.text-block-177 {
  padding-top: 5px;
}

.zone-array-line {
  border-top: 1px #333;
  height: 30px;
  padding-left: 10px;
  display: flex;
}

.zone-array-line:hover {
  background-color: rgba(0, 0, 0, .05);
}

.zone-array-line.selected {
  background-color: rgba(250, 53, 67, .69);
}

.zone-array-line.selected:hover {
  background-color: rgba(250, 53, 67, .5);
}

.div-block-234 {
  margin-bottom: 20px;
}

.closurepage-wrapper {
  padding-top: 20px;
  padding-left: 30px;
}

.closure-services-content {
  border: 1px solid #ccc;
  max-width: 500px;
  max-height: 300px;
  margin-left: 20px;
  overflow: auto;
}

.checkbox-container, .checkbox-container.all {
  font-size: 18px;
}

.service-name-container {
  align-items: flex-end;
  display: flex;
}

.text-block-178 {
  margin-bottom: 10px;
}

.exception-search-form-block {
  display: flex;
}

.exception-search-form {
  align-items: center;
  padding-left: 20px;
  display: flex;
}

.field-label-4 {
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.submit-button {
  font-family: Fontawesome, sans-serif;
  font-size: 16px;
}

.exception-search-button {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 16px;
  display: flex;
  box-shadow: 1px 1px 3px rgba(166, 166, 166, .6);
}

.exception-search-button:hover {
  background-color: rgba(253, 50, 61, .9);
}

.exception-search-button:active {
  background-color: #a3040e;
  box-shadow: inset 1px 1px 3px rgba(166, 166, 166, .6);
}

.text-block-179 {
  font-family: Fontawesome, sans-serif;
}

.exception-search-input {
  height: 40px;
  margin-bottom: 0;
}

.text-block-180 {
  margin-bottom: 5px;
}

.closure-add-store {
  color: #999;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 18px;
  display: flex;
}

.closure-add-store.hidden {
  display: none;
}

.kpi-drop {
  z-index: 100;
  background-color: #fd323d;
  flex-direction: column;
  align-items: stretch;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  top: 60px;
  left: -20px;
}

.kpi-drop-line {
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.kpi-drop-line:hover {
  text-decoration: underline;
}

.image-6 {
  width: 100%;
}

.div-block-235 {
  height: 10px;
}

.button-wrapper {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.slot-return-button, .slot-saver-button.save {
  padding-left: 10px;
  padding-right: 10px;
}

.slot-button {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px 15px;
  font-size: 25px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.slot-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.slot-button:active {
  background-color: #a3040e;
}

.slot-button.save.disabled {
  background-color: rgba(166, 166, 166, .6);
}

.mission-input {
  z-index: 2;
  background-color: #fff;
  border: 1px solid #ccc;
  align-items: center;
  height: 35px;
  padding-left: 20px;
  font-size: 17px;
  display: flex;
  position: relative;
}

.split-modal-bloc {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.split-modal-bloc.hidden {
  display: none;
}

.split-modal-bloc.warning-bloc {
  justify-content: center;
}

.split-modal-label {
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.product-qty-max {
  background-color: #fff;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  margin-bottom: 0;
  font-size: 17px;
}

.div-block-237 {
  width: 55%;
}

.div-block-238 {
  height: 0;
}

.mission-dropdown {
  z-index: 1;
  background-color: #fff;
  position: relative;
  box-shadow: 1px 1px 5px rgba(51, 51, 51, .5);
}

.mission-dropdown-line {
  border: 1px solid #ccc;
  border-top-style: none;
  height: 35px;
  display: flex;
}

.mission-dropdown-line:hover {
  background-color: #f8f8f8;
}

.mission-line-content {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  font-size: 17px;
  display: flex;
}

.mission-previsu {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.mission-previsu-line {
  border-bottom: 1px solid #ccc;
}

.mission-previsu-line:hover {
  background-color: rgba(51, 51, 51, .05);
}

.mission-product-qty {
  justify-content: center;
  align-items: center;
  width: 60%;
}

.mission-previsu-body {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom-style: none;
}

.mission-previsu-header {
  background-color: #fff;
  border: 1px solid #ccc;
  height: 35px;
  margin-bottom: 5px;
  display: flex;
  box-shadow: 1px 1px 5px rgba(51, 51, 51, .05);
}

.div-block-239 {
  width: 80%;
}

.mission-previsu-title {
  display: flex;
}

.div-block-240 {
  justify-content: center;
  width: 100%;
  display: flex;
}

.text-block-182 {
  justify-content: center;
  display: flex;
}

.split-label-wrapper {
  text-align: right;
  width: 50%;
  margin-right: 10px;
  padding-right: 10px;
}

.warning-message {
  color: red;
}

.div-block-242 {
  height: 20px;
}

.add-path {
  cursor: pointer;
  flex: none;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 30px;
}

.add-path:hover {
  color: #dedede;
}

.inputs-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  max-width: 500px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.inputs-modal.qty-modal {
  border-radius: 7px;
  flex: 0 auto;
  display: block;
}

.inputs-modal.qty-modal.hidden {
  display: none;
}

.inputs-modal.error-modal {
  border-radius: 7px;
}

.inputs-modal.error-modal.hidden {
  display: none;
}

.inputs-modal.report-modal {
  border-radius: 7px;
}

.inputs-modal.report-modal.hidden, .inputs-modal.delay-modal.hidden, .inputs-modal.scanner-error-modal.hidden, .inputs-modal.select-splitting-modal.hidden, .inputs-modal.comment-modal.hidden, .inputs-modal.select-modal.hidden {
  display: none;
}

.inputs-modal.save-slots-modal {
  max-width: 800px;
}

.inputs-modal.print-missings-modal.hidden {
  display: none;
}

.inputs-modal.print-selection-modal {
  border-radius: 7px;
  flex: 0 auto;
  display: block;
}

.inputs-modal.print-selection-modal.hidden {
  display: none;
}

.inputs-modal.user-details-modal {
  min-width: 550px;
}

.inputs-modal.zone-iris-modal {
  width: 80%;
  max-width: none;
}

.inputs-modal.control-report-modal {
  flex: 1;
  width: 80%;
  max-width: none;
  display: flex;
}

.input-line {
  justify-content: space-around;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.input-description {
  flex: none;
  margin-right: 10px;
}

.input-inputbox {
  flex: 0 auto;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 16px;
}

.form-block-9-copy {
  justify-content: flex-start;
  width: 400px;
  margin-bottom: 0;
  display: flex;
}

.form-block-9-copy.select-form {
  flex: 1;
  justify-content: flex-end;
}

.mpx-view-formblock {
  margin-bottom: 0;
}

.mpx-view-headerform {
  flex: 1;
  height: 40px;
  display: flex;
}

.mpx-header-modeselect {
  height: 40px;
  margin-bottom: 0;
  margin-left: 17px;
}

.mpx-header-storeinput {
  margin-bottom: 0;
}

.mpx-view-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.mpx-view-titleblock {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.div-block-243 {
  justify-content: space-between;
  height: 40px;
  display: flex;
}

.controllers {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.controllers.disable {
  background-color: #e1e1e1;
}

.week-increase {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.week-increase:hover {
  background-color: #f2f2f2;
}

.week-increase:active {
  color: #f2f2f2;
  background-color: #fd323d;
}

.week-input-text {
  border-style: none;
  flex: 0 auto;
  width: 100px;
  height: 100%;
  margin-bottom: 0;
  padding: 10px 0 10px 10px;
}

.week-input-text.invalid {
  color: #fd323d;
}

.week-input-text.disabled {
  background-color: #e1e1e1;
}

.week-decrease {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.week-decrease:hover {
  background-color: #f2f2f2;
}

.week-decrease:active {
  color: #f2f2f2;
  background-color: #fd323d;
}

.week-picker {
  border: 1px solid #424242;
}

.week-picker.invalid {
  border-color: #fd323d;
}

.week-picker.disable {
  cursor: not-allowed;
}

.week-picker.disabled {
  cursor: not-allowed;
  border-color: #e1e1e1;
}

.week-result-container {
  background-color: #ebebeb;
  flex: 0 auto;
  padding-left: 5px;
  display: flex;
}

.week-result-container.invalid {
  background-color: #ff7b82;
}

.week-result-from, .week-result-to {
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.week-result-from-title, .week-result-to-title {
  text-align: center;
  font-size: 12px;
}

.slot-timeline-item-from {
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  display: flex;
}

.slot-timeline-item-from.header {
  font-size: 18px;
  font-weight: 700;
}

.slot-timeline-item-to {
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  display: flex;
}

.slot-timeline-item-to.header {
  font-size: 18px;
  font-weight: 700;
}

.closure-list-header {
  display: flex;
}

.slot-timeline-item-from-hour {
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  display: flex;
}

.slot-timeline-item-to-hour {
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  padding-left: 10px;
  display: flex;
}

.slot-timeline-date {
  justify-content: space-around;
  align-items: center;
  width: 30%;
  display: flex;
}

.slot-timeline-date.slots-timeline-header {
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 700;
}

.delivery-capa-consumed, .preparation-capa-consumed {
  font-size: 11px;
}

.div-block-244 {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.div-block-244.hidden {
  display: none;
}

.div-block-245 {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.div-block-245.hidden {
  display: none;
}

.div-block-246, .div-block-247 {
  width: 0;
  height: 0;
}

.div-block-248 {
  width: 50px;
}

.div-block-249 {
  align-items: center;
  width: 25px;
  margin-right: 10px;
  display: flex;
}

.puce {
  background-color: #fd323d;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.barre {
  background-color: #fd323d;
  width: 10px;
  height: 100%;
  margin-left: -15px;
}

.split-mission-id {
  width: 20%;
}

.text-block-184 {
  margin-right: 10px;
  font-weight: 700;
}

.div-block-250 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-251 {
  padding: 10px;
}

.div-block-252 {
  margin-top: 20px;
}

.control-report-logoimg {
  max-width: 100%;
  height: 50px;
  margin-top: 19px;
  margin-left: 6px;
}

.hour-error {
  color: #fff;
  text-align: center;
  background-color: #fa3543;
  margin-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 9px;
  position: absolute;
  overflow: visible;
}

.div-block-253 {
  margin-top: 3px;
  padding-left: 3px;
}

.div-block-254 {
  width: 0;
  height: 0;
}

.div-block-255 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: row;
  grid-template: ". ."
                 "Area Area-2"
                 / 1fr 7.25fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px;
  display: flex;
}

.text-block-186 {
  font-weight: 700;
}

.div-block-256 {
  align-items: center;
  display: flex;
}

.split-method-list {
  flex: 1;
}

.split-select-row {
  cursor: pointer;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.split-method-select {
  border: 1px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.split-method-select.selected {
  background-color: #fd323d;
}

.form-block-26 {
  margin-bottom: 0;
}

.ie-hour-input-wrapper {
  width: 100%;
  height: 100%;
}

.ie-hour-input._w-input {
  border: 1px solid #d1d1d1;
  width: 100%;
  height: 100%;
  display: flex;
}

.ie-hour-displayer {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 90%;
  display: flex;
}

.div-block-258 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.div-block-259 {
  justify-content: center;
  align-items: center;
  width: 10px;
}

.ie-example-input-wrapper {
  width: 100px;
  height: 30px;
  margin-left: 20px;
}

.div-block-263, .div-block-264 {
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 50%;
}

.div-block-260 {
  align-items: flex-end;
  width: 500px;
  height: 500px;
  display: flex;
}

.ie-hour-input-arrow-up {
  cursor: pointer;
  align-items: flex-end;
  width: 100%;
  height: 50%;
  display: flex;
}

.ie-hour-input-arrow-up:active {
  background-color: rgba(250, 53, 67, .38);
}

.ie-hour-input-hour {
  cursor: pointer;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
}

.ie-hour-input-hour.selected {
  background-color: rgba(250, 53, 67, .38);
}

.text-block-187 {
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
}

.ie-hour-input-minute {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
}

.ie-hour-input-minute.selected {
  background-color: rgba(250, 53, 67, .38);
}

.ie-hour-input-arrow-down {
  cursor: pointer;
  height: 50%;
}

.ie-hour-input-arrow-down:active {
  background-color: rgba(250, 53, 67, .38);
}

.div-block-265 {
  border: 1px solid #d1d1d1;
  width: 100%;
  height: 100%;
}

.slot-from-input {
  flex: 1;
  width: 25%;
  height: 40px;
}

.slot-to-input, .cut-off-hour-input, .mad-hour-input {
  flex: 1;
  height: 40px;
}

.div-block-266 {
  flex: 1;
}

.div-block-267 {
  width: 0;
  height: 0;
}

.div-block-268 {
  flex: 1;
}

.bemax-opening-from, .bemax-opening-to {
  width: 100px;
  height: 40px;
}

.missing-orderid {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
}

.missing-order-table {
  margin-top: 5px;
}

.missing-mission-details, .orders-form-container {
  margin-top: 20px;
}

.order-form-line {
  background-color: #fff;
  justify-content: flex-start;
  align-items: center;
  min-height: 30px;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: flex;
}

.form-order-value {
  flex: 1;
}

.form-order-checkbox {
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  font-size: 30px;
  display: flex;
}

.text-block-188, .text-block-189 {
  margin-bottom: 10px;
}

.split-mission-products {
  width: 25%;
}

.split-mission-col {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.split-mission-col.split-mission-id {
  border-right: 1px solid #d1d1d1;
  width: 50%;
}

.split-mission-col.mission-product-qty {
  border-right: 1px solid #dedede;
  width: 25%;
}

.split-mission-col.split-mission-products {
  font-family: Fontawesome, sans-serif;
}

.split-mission-col.split-mission-products.product-preview-button {
  cursor: pointer;
}

.div-block-269 {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.mission-previsu-product {
  max-height: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
}

.mission-previsu-mission-info {
  height: 35px;
  display: flex;
}

.split-mission-previsu.inline-info {
  margin-bottom: 10px;
  display: flex;
}

.split-mission-previsu.info-label {
  margin-right: 10px;
  font-weight: 700;
}

.split-mission-previsu.info-label.comment-label {
  margin-bottom: 0;
}

.split-mission-previsu.order-id, .split-mission-previsu.provision-date, .split-mission-previsu.controler-name, .split-mission-previsu.control-date, .split-mission-previsu.mission-id {
  flex: 1;
}

.split-mission-previsu.mission-info {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
}

.split-mission-previsu.controler-comments {
  margin-top: 30px;
}

.split-mission-previsu.control-report-wrapper {
  margin-bottom: 20px;
}

.split-mission-previsu.control-comment-content {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  flex-wrap: wrap;
  min-height: 50px;
  padding: 10px;
  display: flex;
}

.split-mission-previsu.erroneous-products {
  max-width: 100%;
}

.split-mission-previsu.erroneous-products.line {
  margin-top: 10px;
  margin-bottom: 30px;
}

.split-mission-previsu.erroneous-products.product-report-list {
  background-color: #fff;
  padding-left: 20px;
}

.split-mission-previsu.erroneous-products.product-line {
  margin-bottom: 5px;
  display: flex;
}

.split-mission-previsu.erroneous-products.ean {
  margin-right: 20px;
}

.split-mission-previsu.erroneous-products.error-col {
  width: 20%;
  display: flex;
}

.split-mission-previsu.erroneous-products.error-col.name-col {
  width: 40%;
}

.split-mission-previsu.erroneous-products.error-col.brand-col {
  width: 30%;
}

.split-mission-previsu.erroneous-products.error-col.qty-col {
  width: 10%;
}

.split-mission-previsu.erroneous-products.products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.split-mission-previsu.erroneous-products.header-col {
  justify-content: flex-start;
  width: 20%;
}

.split-mission-previsu.erroneous-products.header-col.name-col {
  width: 40%;
}

.split-mission-previsu.erroneous-products.header-col.brand-col {
  width: 30%;
}

.split-mission-previsu.erroneous-products.header-col.qty-col {
  width: 10%;
}

.split-mission-previsu.erroneous-products.erroneous-product-places-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.split-mission-previsu.erroneous-bacs {
  max-width: 100%;
}

.split-mission-previsu.erroneous-bacs.wrapper {
  padding-left: 5px;
}

.split-mission-previsu.erroneous-bacs.line {
  margin-bottom: 20px;
}

.split-mission-previsu.erroneous-bacs.line.bac-declaration-errors {
  margin-top: 10px;
}

.split-mission-previsu.erroneous-bacs.bac-line {
  margin-bottom: 5px;
}

.split-mission-previsu.erroneous-bacs.bac-declaration-errors.bac-report-list {
  padding-left: 20px;
}

.split-mission-previsu.erroneous-product-places {
  max-width: 100%;
}

.split-mission-previsu.erroneous-product-places.line {
  margin-bottom: 5px;
  display: flex;
}

.split-mission-previsu.erroneous-product-places.error-col {
  width: 20%;
  display: flex;
}

.split-mission-previsu.erroneous-product-places.error-col.declared-bac-col {
  display: flex;
}

.split-mission-previsu.erroneous-product-places.error-col.qty-error {
  flex-wrap: wrap;
  width: 5%;
}

.split-mission-previsu.erroneous-product-places.error-col.name-col {
  width: 35%;
}

.split-mission-previsu.erroneous-product-places.wrapper {
  padding-left: 20px;
}

.split-mission-previsu.erroneous-product-places.header-col {
  width: 20%;
}

.split-mission-previsu.erroneous-product-places.header-col.name-col {
  width: 35%;
}

.split-mission-previsu.report-content-wrapper {
  margin-bottom: 15px;
}

.split-mission-previsu.control-report-content {
  background-color: #fff;
  border: 2px solid #227092;
  border-radius: 5px;
  padding: 10px;
}

.split-mission-previsu.preparer-list {
  flex: 1;
}

.split-mission-previsu.mission-info-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

.split-mission-previsu.dlc-checked {
  flex: 1;
}

.split-mission-previsu.dlc-ok {
  color: #20a757;
  font-family: Fontawesome, sans-serif;
}

.split-mission-previsu.dlc-ko {
  color: red;
  font-family: Fontawesome, sans-serif;
}

.split-mission-previsu.order-products {
  margin-top: 10px;
}

.split-mission-previsu.order-products-toggle {
  cursor: pointer;
  display: flex;
}

.split-mission-previsu.order-products-wrapper {
  border: 2px #227092;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.split-mission-previsu.order-products-toggle-wrapper {
  display: flex;
}

.split-mission-previsu.toggle-icon {
  color: #227092;
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
}

.split-mission-previsu.order-products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  border-bottom: 1px solid #dedede;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
}

.split-mission-previsu.order-products-col {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  display: flex;
}

.split-mission-previsu.order-products-col.img-col {
  flex: 0 auto;
  width: 100px;
}

.split-mission-previsu.order-products-row {
  border-bottom: 1px solid rgba(153, 153, 153, .79);
  min-height: 50px;
  display: flex;
}

.split-mission-previsu.order-products-array {
  background-color: #fff;
}

.split-mission-previsu.order-products-array.brand {
  text-align: center;
  text-transform: none;
  flex: 0 auto;
  display: block;
}

.split-mission-previsu.order-products-array.img {
  flex: 1;
}

.split-mission-previsu.print-btn-wrapper {
  justify-content: flex-end;
  display: flex;
}

.split-mission-previsu.container {
  padding: 20px 30px;
}

.split-mission-previsu.store {
  flex: 1;
}

.select-shop-all-label {
  align-items: center;
  display: flex;
}

.slot-calendar-height {
  width: 100%;
  height: 180px;
}

.store-rights-header {
  z-index: 1;
  background-color: #fff;
  border: 2px solid #d1d1d1;
  justify-content: flex-start;
  align-items: stretch;
  height: 45px;
  margin-bottom: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
}

.store-rights-header.select-all {
  top: 103px;
}

.store-authorization-container {
  border: 2px solid #d1d1d1;
  border-top-style: none;
}

.store-authorization-col1 {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 30%;
  display: flex;
}

.store-authorization-col2 {
  text-align: center;
  border-right: 1px solid #d1d1d1;
  justify-content: center;
  align-items: center;
  width: 12%;
  height: 100%;
  display: flex;
}

.store-authorization-col3 {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.store-authorization-col4 {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.store-authorization-col5 {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.store-authorization-col6 {
  border-left: 1px solid var(--light-grey);
  text-align: center;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
  display: flex;
}

.store-authorization-line {
  border-top: 2px solid #d1d1d1;
  display: flex;
}

.store-authorization-line:hover {
  background-color: rgba(216, 216, 216, .43);
}

.store-authorization-col-mode {
  flex: 1;
  align-items: center;
  width: 100%;
  height: 45px;
  display: flex;
}

.store-authorization-col-mode.content {
  border-top: 1px solid #d1d1d1;
}

.store-authorization-col-mode.content.read {
  border-top-style: none;
}

.store-authorization-col-mode-wrapper {
  border-left: 1px solid #d1d1d1;
  flex: 1;
}

.div-block-271 {
  margin-top: 25px;
  margin-bottom: 50px;
}

.div-block-271.in-developpement {
  display: none;
}

.store-authorization-right {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.store-authorization-right.col-3-right {
  align-items: center;
  display: flex;
}

.store-authorization-store-restricted {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.text-block-190 {
  max-width: 100px;
}

.bo-collect-degrade.collect-order {
  margin-bottom: 20px;
  padding: 10px 20px;
}

.bo-collect-degrade.collect-list-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.bo-collect-degrade.collect-order-id {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.bo-collect-degrade.product-degrade {
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
}

.bo-collect-degrade.product-degrade-header {
  flex-wrap: wrap;
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
}

.bo-collect-degrade.product-degrade-details {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 5px;
  display: flex;
}

.bo-collect-degrade.product-name-wrapper {
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

.bo-collect-degrade.product-degrade-brand {
  text-transform: uppercase;
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
}

.bo-collect-degrade.product-degrade-substituable {
  color: #ff712e;
  margin-left: 5px;
  font-weight: 700;
}

.bo-collect-degrade.product-img-wrapper {
  flex: none;
}

.bo-collect-degrade.product-degrade-details-wrapper {
  flex-wrap: wrap;
  flex: 1;
  align-items: stretch;
  width: 37%;
  margin-right: 10px;
  display: flex;
}

.bo-collect-degrade.product-actions-wrapper {
  text-align: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  width: 600px;
  display: flex;
}

.bo-collect-degrade.product-degrade-img-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-right: 10px;
  display: flex;
}

.bo-collect-degrade.degrade-ean-line {
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.bo-collect-degrade.ean-edited {
  flex-wrap: wrap;
}

.bo-collect-degrade.bo-product-ean-list {
  flex-flow: column wrap;
  display: flex;
}

.bo-collect-degrade.product-degrade-details-col {
  flex-direction: column;
  flex: 1;
  padding: 5px;
}

.bo-collect-degrade.product-degrade-details-col.ean-col {
  text-align: right;
  flex: 0 auto;
  padding-right: 0;
}

.bo-collect-degrade.product-degrade-details-col.details-col {
  flex: 0 auto;
  width: 150px;
}

.bo-collect-degrade.products-details-line {
  margin-bottom: 2px;
}

.bo-collect-degrade.products-details-line.product-degrade-details-btn {
  cursor: pointer;
}

.bo-collect-degrade.product-degrade-ean {
  color: #fd323d;
  font-size: 18px;
  font-weight: 500;
}

.bo-collect-degrade.product-degrade-value-line {
  flex-wrap: wrap;
  display: flex;
}

.bo-collect-degrade.product-optional-details {
  padding-left: 10px;
}

.bo-collect-degrade.content-wrapper {
  padding-top: 14px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: visible;
}

.bo-collect-degrade.collect-order-msg {
  background-color: var(--medium-aquamarine);
  color: var(--white);
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
}

.text-block-191 {
  margin-right: 5px;
}

.text-block-192 {
  margin-left: 5px;
  margin-right: 5px;
}

.div-block-272 {
  width: 50%;
  padding: 5px;
}

.text-block-193 {
  text-align: center;
  margin-bottom: 5px;
  font-weight: 700;
}

.text-block-194 {
  margin-left: 5px;
  margin-right: 5px;
}

.text-block-195 {
  text-align: center;
  font-weight: 700;
}

.div-block-272-copy {
  width: 50%;
  padding: 5px;
}

.div-block-273 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.div-block-274 {
  margin-top: 10px;
}

.collect-degrade.inline-info {
  margin-bottom: 10px;
  display: flex;
}

.collect-degrade.info-label {
  margin-right: 10px;
  font-weight: 700;
}

.collect-degrade.info-label.comment-label {
  margin-bottom: 0;
}

.collect-degrade.order-id, .collect-degrade.provision-date, .collect-degrade.controler-name, .collect-degrade.control-date, .collect-degrade.mission-id {
  flex: 1;
}

.collect-degrade.mission-info {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
}

.collect-degrade.controler-comments {
  margin-top: 30px;
}

.collect-degrade.control-report-wrapper {
  margin-bottom: 20px;
}

.collect-degrade.control-comment-content {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  flex-wrap: wrap;
  min-height: 50px;
  padding: 10px;
  display: flex;
}

.collect-degrade.erroneous-products {
  max-width: 100%;
}

.collect-degrade.erroneous-products.line {
  margin-top: 10px;
  margin-bottom: 30px;
}

.collect-degrade.erroneous-products.product-report-list {
  background-color: #fff;
  padding-left: 20px;
}

.collect-degrade.erroneous-products.product-line {
  margin-bottom: 5px;
  display: flex;
}

.collect-degrade.erroneous-products.ean {
  margin-right: 20px;
}

.collect-degrade.erroneous-products.error-col {
  width: 20%;
  display: flex;
}

.collect-degrade.erroneous-products.error-col.name-col {
  width: 40%;
}

.collect-degrade.erroneous-products.error-col.brand-col {
  width: 30%;
}

.collect-degrade.erroneous-products.error-col.qty-col {
  width: 10%;
}

.collect-degrade.erroneous-products.products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.collect-degrade.erroneous-products.header-col {
  justify-content: flex-start;
  width: 20%;
}

.collect-degrade.erroneous-products.header-col.name-col {
  width: 40%;
}

.collect-degrade.erroneous-products.header-col.brand-col {
  width: 30%;
}

.collect-degrade.erroneous-products.header-col.qty-col {
  width: 10%;
}

.collect-degrade.erroneous-products.erroneous-product-places-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.collect-degrade.erroneous-bacs {
  max-width: 100%;
}

.collect-degrade.erroneous-bacs.wrapper {
  padding-left: 5px;
}

.collect-degrade.erroneous-bacs.line {
  margin-bottom: 20px;
}

.collect-degrade.erroneous-bacs.line.bac-declaration-errors {
  margin-top: 10px;
}

.collect-degrade.erroneous-bacs.bac-line {
  margin-bottom: 5px;
}

.collect-degrade.erroneous-bacs.bac-declaration-errors.bac-report-list {
  padding-left: 20px;
}

.collect-degrade.erroneous-product-places {
  max-width: 100%;
}

.collect-degrade.erroneous-product-places.line {
  margin-bottom: 5px;
  display: flex;
}

.collect-degrade.erroneous-product-places.error-col {
  width: 20%;
  display: flex;
}

.collect-degrade.erroneous-product-places.error-col.declared-bac-col {
  display: flex;
}

.collect-degrade.erroneous-product-places.error-col.qty-error {
  flex-wrap: wrap;
  width: 5%;
}

.collect-degrade.erroneous-product-places.error-col.name-col {
  width: 35%;
}

.collect-degrade.erroneous-product-places.wrapper {
  padding-left: 20px;
}

.collect-degrade.erroneous-product-places.header-col {
  width: 20%;
}

.collect-degrade.erroneous-product-places.header-col.name-col {
  width: 35%;
}

.collect-degrade.report-content-wrapper {
  margin-bottom: 15px;
}

.collect-degrade.control-report-content {
  background-color: #fff;
  border: 2px solid #227092;
  border-radius: 5px;
  padding: 10px;
}

.collect-degrade.preparer-list {
  flex: 1;
}

.collect-degrade.mission-info-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

.collect-degrade.dlc-checked {
  flex: 1;
}

.collect-degrade.dlc-ok {
  color: #20a757;
  font-family: Fontawesome, sans-serif;
}

.collect-degrade.dlc-ko {
  color: red;
  font-family: Fontawesome, sans-serif;
}

.collect-degrade.order-products {
  margin-top: 10px;
}

.collect-degrade.order-products-toggle {
  cursor: pointer;
  display: flex;
}

.collect-degrade.order-products-wrapper {
  border: 2px #227092;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.collect-degrade.order-products-toggle-wrapper {
  display: flex;
}

.collect-degrade.toggle-icon {
  color: #fa3543;
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
}

.collect-degrade.order-products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  border-bottom: 1px solid #dedede;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
}

.collect-degrade.order-products-col {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  display: flex;
}

.collect-degrade.order-products-col.img-col {
  flex: 0 auto;
  width: 100px;
}

.collect-degrade.order-products-row {
  border-bottom: 1px solid rgba(153, 153, 153, .79);
  min-height: 50px;
  display: flex;
}

.collect-degrade.order-products-array {
  background-color: #fff;
}

.collect-degrade.order-products-array.brand {
  text-align: center;
  text-transform: none;
  flex: 0 auto;
  display: block;
}

.collect-degrade.order-products-array.img {
  flex: 1;
}

.collect-degrade.print-btn-wrapper {
  justify-content: flex-end;
  display: flex;
}

.collect-degrade.container {
  padding: 20px 30px;
}

.collect-degrade.store {
  flex: 1;
}

.collect-degrade2.inline-info {
  margin-bottom: 10px;
  display: flex;
}

.collect-degrade2.info-label {
  margin-right: 10px;
  font-weight: 700;
}

.collect-degrade2.info-label.comment-label {
  margin-bottom: 0;
}

.collect-degrade2.order-id, .collect-degrade2.provision-date, .collect-degrade2.controler-name, .collect-degrade2.control-date, .collect-degrade2.mission-id {
  flex: 1;
}

.collect-degrade2.mission-info {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
}

.collect-degrade2.controler-comments {
  margin-top: 30px;
}

.collect-degrade2.control-report-wrapper {
  margin-bottom: 20px;
}

.collect-degrade2.control-comment-content {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  flex-wrap: wrap;
  min-height: 50px;
  padding: 10px;
  display: flex;
}

.collect-degrade2.erroneous-products {
  max-width: 100%;
}

.collect-degrade2.erroneous-products.line {
  margin-top: 10px;
  margin-bottom: 30px;
}

.collect-degrade2.erroneous-products.product-report-list {
  background-color: #fff;
  padding-left: 20px;
}

.collect-degrade2.erroneous-products.product-line {
  margin-bottom: 5px;
  display: flex;
}

.collect-degrade2.erroneous-products.ean {
  margin-right: 20px;
}

.collect-degrade2.erroneous-products.error-col {
  width: 20%;
  display: flex;
}

.collect-degrade2.erroneous-products.error-col.name-col {
  width: 40%;
}

.collect-degrade2.erroneous-products.error-col.brand-col {
  width: 30%;
}

.collect-degrade2.erroneous-products.error-col.qty-col {
  width: 10%;
}

.collect-degrade2.erroneous-products.products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.collect-degrade2.erroneous-products.header-col {
  justify-content: flex-start;
  width: 20%;
}

.collect-degrade2.erroneous-products.header-col.name-col {
  width: 40%;
}

.collect-degrade2.erroneous-products.header-col.brand-col {
  width: 30%;
}

.collect-degrade2.erroneous-products.header-col.qty-col {
  width: 10%;
}

.collect-degrade2.erroneous-products.erroneous-product-places-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 5px 5px 5px 20px;
  display: flex;
}

.collect-degrade2.erroneous-bacs {
  max-width: 100%;
}

.collect-degrade2.erroneous-bacs.wrapper {
  padding-left: 5px;
}

.collect-degrade2.erroneous-bacs.line {
  margin-bottom: 20px;
}

.collect-degrade2.erroneous-bacs.line.bac-declaration-errors {
  margin-top: 10px;
}

.collect-degrade2.erroneous-bacs.bac-line {
  margin-bottom: 5px;
}

.collect-degrade2.erroneous-bacs.bac-declaration-errors.bac-report-list {
  padding-left: 20px;
}

.collect-degrade2.erroneous-product-places {
  max-width: 100%;
}

.collect-degrade2.erroneous-product-places.line {
  margin-bottom: 5px;
  display: flex;
}

.collect-degrade2.erroneous-product-places.error-col {
  width: 20%;
  display: flex;
}

.collect-degrade2.erroneous-product-places.error-col.declared-bac-col {
  display: flex;
}

.collect-degrade2.erroneous-product-places.error-col.qty-error {
  flex-wrap: wrap;
  width: 5%;
}

.collect-degrade2.erroneous-product-places.error-col.name-col {
  width: 35%;
}

.collect-degrade2.erroneous-product-places.wrapper {
  padding-left: 20px;
}

.collect-degrade2.erroneous-product-places.header-col {
  width: 20%;
}

.collect-degrade2.erroneous-product-places.header-col.name-col {
  width: 35%;
}

.collect-degrade2.report-content-wrapper {
  margin-bottom: 15px;
}

.collect-degrade2.control-report-content {
  background-color: #fff;
  border: 2px solid #227092;
  border-radius: 5px;
  padding: 10px;
}

.collect-degrade2.preparer-list {
  flex: 1;
}

.collect-degrade2.mission-info-col {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

.collect-degrade2.dlc-checked {
  flex: 1;
}

.collect-degrade2.dlc-ok {
  color: #20a757;
  font-family: Fontawesome, sans-serif;
}

.collect-degrade2.dlc-ko {
  color: red;
  font-family: Fontawesome, sans-serif;
}

.collect-degrade2.order-products {
  margin-top: 10px;
}

.collect-degrade2.order-products-toggle {
  cursor: pointer;
  display: flex;
}

.collect-degrade2.order-products-wrapper {
  border: 2px #227092;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.collect-degrade2.order-products-toggle-wrapper {
  display: flex;
}

.collect-degrade2.toggle-icon {
  color: #fa3543;
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
}

.collect-degrade2.order-products-header {
  color: rgba(48, 48, 48, .66);
  background-color: rgba(0, 0, 0, .07);
  border-bottom: 1px solid #dedede;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
}

.collect-degrade2.order-products-col {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  padding: 5px;
  display: flex;
}

.collect-degrade2.order-products-col.img-col {
  flex: 0 auto;
  width: 100px;
}

.collect-degrade2.order-products-row {
  border-bottom: 1px solid rgba(153, 153, 153, .79);
  min-height: 50px;
  display: flex;
}

.collect-degrade2.order-products-array {
  background-color: #fff;
}

.collect-degrade2.order-products-array.brand {
  text-align: center;
  text-transform: none;
  flex: 0 auto;
  display: block;
}

.collect-degrade2.order-products-array.img {
  flex: 1;
}

.collect-degrade2.print-btn-wrapper {
  justify-content: flex-end;
  display: flex;
}

.collect-degrade2.container {
  padding: 20px 30px;
}

.collect-degrade2.store {
  flex: 1;
}

.div-block-275 {
  flex-wrap: wrap;
  display: flex;
}

.div-block-276 {
  align-items: center;
  display: flex;
}

.form-block-27 {
  width: 90px;
  margin-bottom: 0;
  margin-right: 10px;
}

.iris-rows-selector {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  justify-content: space-around;
  margin-bottom: 0;
}

.stores-tab-container {
  position: relative;
}

.stores-tab-wrapper {
  border-bottom: 3px solid #fd323d;
  align-items: flex-end;
  height: 60px;
  padding-top: 0;
  display: flex;
  box-shadow: inset 0 -1px 5px rgba(51, 51, 51, .5);
}

.stores-tab {
  color: #fff;
  cursor: pointer;
  background-color: rgba(250, 53, 67, .69);
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 100;
  display: flex;
  box-shadow: inset 0 -1px 5px -2px rgba(51, 51, 51, .5);
}

.stores-tab:hover {
  background-color: rgba(250, 53, 67, .53);
}

.stores-tab:active {
  background-color: rgba(250, 53, 67, .42);
}

.stores-tab.selected {
  box-shadow: none;
  cursor: default;
  background-color: #fd323d;
}

.degrade-ean-subline {
  flex-wrap: wrap;
  justify-content: flex-end;
  display: flex;
}

.multi-store-margin {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.stores-content-container-content {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: block;
}

.div-block-278 {
  padding-left: 15px;
  padding-right: 15px;
}

.warning-wrapper {
  position: absolute;
  top: 67px;
  left: 5px;
}

.warning-wrapper.hidden {
  display: none;
}

.warning-div {
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.warning-div.warning-viable {
  color: #e8902d;
}

.warning-div.warning-ok {
  color: #65ca8e;
  font-size: 29px;
}

.warning-div.warning-failure {
  color: #fd323d;
  font-size: 29px;
}

.warning-ok {
  font-family: Fontawesome, sans-serif;
}

.header-store-notwrapper {
  margin-left: 15px;
}

.form-block-28 {
  margin-bottom: 0;
}

.text-field-23 {
  width: 150px;
  height: 40px;
  margin-bottom: 0;
}

.div-block-279 {
  border: 2px solid #d1d1d1;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
}

.div-block-280 {
  margin-bottom: 10px;
  font-size: 18px;
}

.div-block-281 {
  border: 1px #d1d1d1;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 5px;
  display: flex;
}

.text-block-196 {
  border: 1px solid #d1d1d1;
  justify-content: center;
  align-items: center;
  width: 120px;
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
}

.text-block-196:hover {
  background-color: rgba(216, 216, 216, .43);
}

.text-block-196.store-shelves-group-line {
  align-items: stretch;
}

.text-block-197 {
  flex: 1;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.tabs-z-wrapper {
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
}

.order-name-col {
  width: 14%;
}

.mission-collect-default-cell {
  width: 16%;
}

.mission-expedition-default-cell {
  width: 18%;
}

.missions-all-default-cell {
  width: 13%;
}

.mission-control-default-cell {
  width: 17%;
}

.order-name-col-arrow {
  justify-content: center;
  align-items: flex-start;
  font-family: "Fa solid 900", sans-serif;
  display: flex;
}

.order-name-col-arrow.up.hidden, .order-name-col-arrow.down.hidden {
  display: none;
}

.col-filter-arrow {
  font-family: "Fa solid 900", sans-serif;
}

.div-block-282 {
  justify-content: center;
  align-items: center;
  height: 0;
  display: flex;
}

.order-col-bac {
  width: 6%;
}

.order-col-user {
  width: 14%;
}

.col-type {
  width: 8%;
}

.div-block-283 {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.order-prep-col {
  flex: 1;
}

.kbrw-view-exportlink {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 7px;
  padding: 10px;
  font-weight: 700;
  display: block;
}

.kbrw-view-exportlink:hover {
  background-color: #ff7b82;
}

.kbrw-view-exportlink:active {
  background-color: #991d00;
}

.kbrw-view-exportlink-container {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.mpx-header-date-picker {
  margin-bottom: 0;
  margin-left: 17px;
}

.div-block-284 {
  margin-top: 15px;
  display: flex;
}

.mpx-header-realtime {
  color: #fff;
  cursor: pointer;
  background-color: #303030;
  border-radius: 6px;
  flex: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.mpx-header-realtime:hover {
  background-color: #4b4b4b;
}

.mpx-header-realtime:active {
  background-color: #333;
}

.mpx-header-realtime.disabled {
  cursor: not-allowed;
  background-color: #b8b8b8;
}

.mpx-view-params-title {
  border-left: 5px solid #fa3543;
  margin-bottom: 5px;
  padding-left: 9px;
  font-size: 18px;
  font-weight: 400;
}

.mpx-view-params-list {
  border: 1px solid #d1d1d1;
  border-bottom-style: none;
  width: 60%;
}

.mpx-view-param {
  border-bottom: 1px solid #d1d1d1;
  display: flex;
}

.mpx-view-param:hover {
  background-color: var(--gainsboro);
}

.mpx-view-param-name {
  width: 60%;
}

.mpx-view-param-value {
  border-left: 1px solid #d1d1d1;
  width: 40%;
  padding-left: 3px;
}

.div-block-285 {
  border-left: 5px solid #fa3543;
  padding-left: 9px;
  font-size: 36px;
  font-weight: 400;
}

.mpx-view-reports-list {
  border: 1px solid #d1d1d1;
  border-bottom-style: none;
}

.mpx-view-report-list-header {
  display: flex;
}

.mpx-view-report-name {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  font-size: 18px;
  display: flex;
}

.mpx-view-report-item {
  border-bottom: 1px solid #d1d1d1;
}

.mpx-view-report-go {
  flex: none;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.div-block-286 {
  flex: 1;
}

.mpx-view-item-container {
  cursor: pointer;
  align-items: center;
  height: 20px;
  display: flex;
}

.mpx-view-item-container:hover {
  background-color: var(--gainsboro);
}

.mpx-view-report-report-list {
  background-color: #fff;
}

.mpx-view-report-option-item {
  align-items: stretch;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
}

.mpx-view-report-option-item:hover {
  background-color: var(--gainsboro);
}

.mpx-view-report-report-name {
  align-items: center;
  width: 50%;
  padding-left: 5px;
  font-weight: 500;
  display: flex;
}

.mpx-view-report-option-options {
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.form-block-29 {
  flex: 1;
  margin-bottom: -8px;
}

.mpx-view-report-option-select-field {
  height: 40px;
}

.mpx-view-report-select {
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.mpx-view-report-select:hover {
  background-color: rgba(250, 53, 67, .69);
}

.mpx-view-report-select:active {
  background-color: #fd323d;
}

.mpx-view-report-dropdown {
  cursor: default;
  background-color: #fff;
}

.div-block-288 {
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 5px;
  display: flex;
}

.mpx-view-report-report-button {
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  border-radius: 3px;
  margin-right: 5px;
  padding: 5px 5px 5px 6px;
}

.mpx-view-report-report-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.mpx-view-report-report-button:active {
  background-color: #fd323d;
}

.mpx-view-header-return {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.mpx-view-header-return:hover {
  background-color: rgba(250, 53, 67, .69);
}

.mpx-view-header-return:active {
  background-color: #fd323d;
}

.mpx-view-real-times-params {
  width: 60%;
  margin-top: 6px;
}

.mpx-view-error-message {
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 28px;
  display: flex;
}

.mpx-view-new-report-wrapper {
  justify-content: flex-end;
  display: flex;
}

.mpx-view-new-report-wrapper.hidden {
  display: none;
}

.div-block-289 {
  width: 60%;
}

.mpx-view-new-report-button {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 4px 4px 0 0;
  padding: 7px 10px 5px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
}

.mpx-view-new-report-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.mpx-view-new-report-button:active {
  background-color: #fd323d;
}

.div-block-290 {
  height: 0;
}

.mpx-view-new-report {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

.div-block-291 {
  background-color: #fff;
  border-radius: 5px;
  width: 60%;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.div-block-295, .mpx-view-new-id {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.mpx-view-new-book, .mpx-view-new-param {
  display: flex;
}

.mpx-view-new-validate {
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.div-block-292 {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 15px;
  font-size: 20px;
}

.div-block-292:hover {
  background-color: rgba(250, 53, 67, .69);
}

.div-block-292:active {
  background-color: #fd323d;
}

.div-block-293 {
  flex: none;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  display: flex;
}

.div-block-294 {
  flex: 1;
}

.success-message-2 {
  padding-bottom: 0;
  padding-right: 21px;
}

.form-block-30, .text-field-24 {
  margin-bottom: 0;
}

.expand-item {
  color: #fd323d;
  text-align: left;
  text-transform: none;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  width: 50px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 36px;
  font-style: normal;
  text-decoration: none;
  display: flex;
}

.expand-item:hover {
  color: rgba(250, 53, 67, .69);
}

.expand-item:active {
  color: #a3040e;
}

.mpx-view-report-option-item-2 {
  align-items: stretch;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
}

.mpx-view-report-option-item-2:hover {
  background-color: rgba(216, 216, 216, .43);
}

.closure-groupby-dropdown {
  z-index: 10;
  border: 1px #d1d1d1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: static;
}

.closure-groupby-dropdown-item {
  justify-content: flex-start;
  align-items: stretch;
  margin-right: 10%;
  display: flex;
}

.closure-groupby-dropdown-item:hover {
  background-color: rgba(209, 209, 209, .5);
}

.closure-groupby-dropdown-item-options {
  flex: 0 auto;
  justify-content: flex-end;
  width: 40%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.closure-groupby-dropdown-item-text {
  text-align: left;
  width: 40%;
  margin-left: 10px;
}

.closure-groupby-dropdown-group {
  border: 1px #000;
  overflow: visible;
}

.div-block-296 {
  height: 0;
}

.closure-dropdown-red-rectangle {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: left;
  background-color: #fd323d;
  flex-flow: wrap;
  flex: 0 auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-self: auto;
  width: 10px;
  margin-left: 5px;
  margin-right: 10px;
  display: block;
  position: static;
}

.closure-groupby-dropdown-info, .closure-groupby-dropdown-item-rectangle {
  flex: 1;
}

.closure-groupby-dropdown-item-red-rectangle {
  background-color: #fd323d;
  flex: 0 auto;
  justify-content: flex-end;
  width: 5px;
  margin-left: 10%;
  padding-top: 30px;
  padding-left: 3px;
  display: block;
}

.mpx-view-real-time-param {
  padding-left: 5px;
  display: flex;
}

.mpx-view-real-time-param-name {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.mpx-view-parm-container {
  flex: 1;
}

.mpx-view-real-time-form {
  margin-bottom: 0;
}

.mpx-view-real-time-param-input {
  width: 250px;
  margin: 5px;
}

.success-message-3 {
  padding: 0;
}

.error-message-2 {
  margin-top: 0;
  padding: 0;
}

.mpx-view-real-time-param-selector {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
  width: 250px;
  margin: 5px;
}

.mpx-view-param-validate {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.mpx-view-param-validate:hover {
  background-color: rgba(250, 53, 67, .69);
}

.mpx-view-param-validate:active {
  background-color: #fd323d;
}

.div-block-297 {
  justify-content: center;
  align-items: center;
  width: 60%;
  display: flex;
}

.disabled {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.select-field-5, .mission-preparer-input, .mission-status-input {
  background-image: linear-gradient(to top, #f3f3f3, #fff);
}

.missingp-info-wrapper {
  border: 1px solid #000;
  margin-bottom: -1px;
  padding: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 3px rgba(0, 0, 0, .24);
}

.missingp-no-missing {
  margin-top: 25px;
}

.import-button {
  z-index: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #fa3543;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.import-button:hover {
  background-color: rgba(250, 53, 67, .65);
}

.import-button.slot-planning-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.slot-planning-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button.slot-edit-validate {
  cursor: pointer;
  justify-content: center;
  margin-top: 22px;
  margin-left: 0;
}

.import-button.slot-edit-validate:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.slot-edit-validate:active {
  background-color: #a3040e;
}

.import-button.slot-edit-validate.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button.slots-deploy-back:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.slots-deploy-back:active {
  background-color: #a3040e;
}

.import-button.slots-deploy-submit {
  background-color: #fd323d;
}

.import-button.slots-deploy-submit:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.slots-deploy-submit:active {
  background-color: #a3040e;
}

.import-button.slots-deploy-submit.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button.slots-deploy:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.slots-deploy.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button.slots-model-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.slots-model-button:active {
  background-color: #a3040e;
}

.import-button.slots-model-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button.slots-prep-deploy.disabled {
  background-color: rgba(253, 50, 61, .5);
}

.import-button.save-button {
  cursor: pointer;
  justify-content: center;
  margin-top: 5px;
  margin-left: 0;
}

.import-button.save-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button.save-button:active {
  background-color: #a3040e;
}

.import-button.save-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button.submit {
  width: 30%;
  margin-top: 15px;
}

.import-button.submit.disabled {
  color: #999;
  cursor: not-allowed;
  background-color: #d1d1d1;
  width: 30%;
  position: static;
}

.import-button.import-button-text {
  text-align: center;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.div-block-298 {
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.import-dragndrop {
  background-color: #fff;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 14%;
  margin-right: 30px;
  display: block;
  box-shadow: inset 1px 1px 5px #000;
}

.import-dragndrop-text {
  text-align: center;
  border: 1px solid #999;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 49%;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.import-form {
  flex-direction: column;
  justify-content: flex-end;
  display: block;
}

.import-form-div {
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.import-choice-div {
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.import-button-div {
  z-index: 1;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 49%;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.import-button-div.slot-planning-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.slot-planning-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-div.slot-edit-validate {
  cursor: pointer;
  justify-content: center;
  margin-top: 22px;
  margin-left: 0;
}

.import-button-div.slot-edit-validate:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.slot-edit-validate:active {
  background-color: #a3040e;
}

.import-button-div.slot-edit-validate.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-div.slots-deploy-back:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.slots-deploy-back:active {
  background-color: #a3040e;
}

.import-button-div.slots-deploy-submit {
  background-color: #fd323d;
}

.import-button-div.slots-deploy-submit:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.slots-deploy-submit:active {
  background-color: #a3040e;
}

.import-button-div.slots-deploy-submit.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-div.slots-deploy:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.slots-deploy.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-div.slots-model-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.slots-model-button:active {
  background-color: #a3040e;
}

.import-button-div.slots-model-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-div.slots-prep-deploy.disabled {
  background-color: rgba(253, 50, 61, .5);
}

.import-button-div.save-button {
  cursor: pointer;
  justify-content: center;
  margin-top: 5px;
  margin-left: 0;
}

.import-button-div.save-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-div.save-button:active {
  background-color: #a3040e;
}

.import-button-div.save-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-div.submit {
  width: 30%;
  margin-top: 15px;
}

.import-button-div.submit.disabled {
  color: #999;
  cursor: not-allowed;
  background-color: #d1d1d1;
}

.import-choice-flex-div {
  justify-content: space-between;
  width: 30%;
  padding-left: 5px;
  display: flex;
}

.import-button-submit {
  z-index: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #fa3543;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.import-button-submit:hover {
  background-color: rgba(250, 53, 67, .65);
}

.import-button-submit.slot-planning-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.slot-planning-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-submit.slot-edit-validate {
  cursor: pointer;
  justify-content: center;
  margin-top: 22px;
  margin-left: 0;
}

.import-button-submit.slot-edit-validate:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.slot-edit-validate:active {
  background-color: #a3040e;
}

.import-button-submit.slot-edit-validate.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-submit.slots-deploy-back:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.slots-deploy-back:active {
  background-color: #a3040e;
}

.import-button-submit.slots-deploy-submit {
  background-color: #fd323d;
}

.import-button-submit.slots-deploy-submit:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.slots-deploy-submit:active {
  background-color: #a3040e;
}

.import-button-submit.slots-deploy-submit.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-submit.slots-deploy:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.slots-deploy.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-submit.slots-model-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.slots-model-button:active {
  background-color: #a3040e;
}

.import-button-submit.slots-model-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-submit.slots-prep-deploy.disabled {
  background-color: rgba(253, 50, 61, .5);
}

.import-button-submit.save-button {
  cursor: pointer;
  justify-content: center;
  margin-top: 5px;
  margin-left: 0;
}

.import-button-submit.save-button:hover {
  background-color: rgba(250, 53, 67, .69);
}

.import-button-submit.save-button:active {
  background-color: #a3040e;
}

.import-button-submit.save-button.disabled {
  cursor: not-allowed;
  background-color: rgba(166, 166, 166, .6);
}

.import-button-submit.submit {
  width: 30%;
  margin-top: 15px;
}

.import-button-submit.submit.disabled {
  color: #999;
  cursor: not-allowed;
  background-color: #d1d1d1;
  width: 30%;
  position: static;
}

.import-button-submit.disabled {
  color: #999;
  cursor: not-allowed;
  background-color: #d1d1d1;
}

.import-button-submit.import-button-text {
  text-align: center;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.import-choice-flex-div-submit {
  justify-content: space-between;
  width: 30%;
  padding-left: 5px;
  display: flex;
}

.scaledescription {
  color: #fff;
  background-color: #fd323d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  display: flex;
}

.scaledescription.aisle {
  background-color: #fa3543;
  flex-wrap: wrap;
  height: auto;
  min-height: 50px;
  padding: 10px;
}

.scaledescription.aisle.path-title-container {
  flex-flow: wrap;
  flex: none;
  justify-content: space-around;
  align-items: center;
  height: auto;
  min-height: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.scaledescription.aisle.path-title-container.description {
  min-height: 10px;
}

.pathdescription {
  text-align: center;
  padding: 5px;
}

.closure-group-text-button {
  color: #fff;
  align-items: flex-start;
  font-size: 20px;
  display: block;
}

.closure-group-text-button.plus {
  margin-left: 10px;
  font-family: "Fa solid 900", sans-serif;
}

.closure-groupby-button {
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 50px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.closure-groupby-button:hover {
  opacity: .5;
}

.add-closure-plus-button {
  color: #fff;
  align-items: flex-start;
  margin-left: 10px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: block;
}

.add-closure-plus-button.plus {
  margin-left: 10px;
  font-family: "Fa solid 900", sans-serif;
}

.add-closure-div-button {
  cursor: pointer;
  background-color: #fd323d;
  border-radius: 50px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.add-closure-div-button:hover {
  opacity: .5;
}

.toggle-icon {
  font-family: Fontawesome, sans-serif;
}

.toggle-icon.batch-modal {
  font-size: 25px;
}

.batch-error-details {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
}

.text-block-199 {
  padding-right: 10px;
}

.store-title {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
}

.mpx-form-block {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.mpx-input {
  margin-bottom: 0;
}

.mpx-input.big {
  border: 0 solid #000;
  font-size: 24px;
}

.mpx-input.tiny {
  height: 30px;
}

.mpx-slot-store-address-row {
  align-items: center;
  height: 35px;
  display: flex;
}

.mpx-slot-store-address-row-text {
  width: 100px;
}

.mpx-slot-store-address-row-text.second {
  margin-left: 23px;
}

.example-wrapper {
  justify-content: space-around;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
}

.input-labelized {
  margin-bottom: 0;
}

.input-labelized.big {
  border: 0 solid #000;
  font-size: 24px;
}

.input-labelized.tiny {
  height: 30px;
}

.cce-opening-from, .cce-opening-to {
  width: 100px;
  height: 40px;
}

.order-details-header {
  justify-content: space-between;
  align-items: center;
  margin-top: 0%;
  display: flex;
}

.order-details-order-container {
  border: 1px #000;
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin: 5%;
  padding-bottom: 5%;
  display: block;
  overflow: auto;
}

.order-details-header-number {
  flex: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: 700;
}

.order-details-header-button {
  background-color: var(--mpx_primary);
  cursor: pointer;
  border: 1px solid #858585;
  flex: 0 auto;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin-top: 0;
  padding: 9px 15px;
  display: flex;
  position: static;
  bottom: 100%;
}

.div-block-299 {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.text-block-200 {
  color: #fff;
  font-family: Fontawesome, sans-serif;
}

.text-block-201 {
  color: #fff;
}

.order-details-customer {
  border: 1px #000;
  flex: 1;
  margin-left: 7.5px;
  margin-right: 15px;
  overflow: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 1px 2px rgba(0, 0, 0, .12);
}

.order-details-info {
  border: 1px #000;
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin-left: 15px;
  margin-right: 7.5px;
  display: flex;
  overflow: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 1px 2px rgba(0, 0, 0, .12);
}

.order-details-main-content {
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 0;
  display: flex;
}

.order-details-info-title {
  background-color: var(--mpx_primary);
  color: #fff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
}

.order-details-info-block {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.order-details-customer-name {
  text-align: right;
  flex: 0 auto;
}

.order-details-info-status {
  text-align: right;
  font-weight: 700;
}

.order-details-info-creation-date, .order-details-info-available-date, .order-details-info-price, .order-details-info-delivery-date, .order-details-info-quantity {
  text-align: right;
}

.order-details-customer-field-name {
  flex: none;
}

.text-block-203 {
  flex: 0 auto;
}

.order-details-customer-shipping-address {
  text-align: right;
  text-transform: none;
  flex: 0 auto;
}

.order-details-info-field-name {
  flex: none;
}

.order-details-info-id {
  text-align: right;
  flex: 0 auto;
}

.order-details-tabs-wrapper {
  border-bottom: 3px solid #fd323d;
  align-items: flex-end;
  height: 60px;
  padding-top: 0;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  box-shadow: inset 0 -1px 5px rgba(51, 51, 51, .5);
}

.order-details-tab {
  color: #fff;
  cursor: pointer;
  background-color: rgba(250, 53, 67, .69);
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 100;
  display: flex;
  box-shadow: inset 0 -1px 5px -2px rgba(51, 51, 51, .5);
}

.order-details-tab:hover {
  background-color: rgba(250, 53, 67, .53);
}

.order-details-tab:active {
  background-color: rgba(250, 53, 67, .42);
}

.order-details-tab.selected {
  box-shadow: none;
  cursor: pointer;
  background-color: #fd323d;
}

.order-details-products-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 1px 2px rgba(0, 0, 0, .12);
}

.order-details-products-container {
  margin: 5%;
  display: block;
}

.order-details-products-header {
  background-color: var(--mpx_primary);
  border: 1px solid gray;
  justify-content: center;
  align-items: stretch;
  display: flex;
  overflow: auto;
}

.order-details-products-list {
  cursor: default;
  border: 1px solid gray;
  border-top-style: none;
  justify-content: center;
  align-items: stretch;
  height: 60px;
  display: flex;
  overflow: visible;
}

.div-block-300 {
  border-right: 1px solid #858585;
  flex: 1;
}

.order-details-products-col-irefc {
  cursor: default;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-products-price {
  border-right: 1px solid #858585;
  flex: 1;
}

.text-block-204 {
  color: var(--white);
  text-align: center;
}

.order-details-customer-billing-address, .order-details-customer-phone, .order-details-customer-email {
  text-align: right;
  text-transform: none;
  flex: 0 auto;
}

.order-details-products-list-col {
  border-right: 1px solid #858585;
  flex: 1;
}

.text-block-205 {
  text-align: center;
  padding: 5px;
}

.order-details-products-col-designation {
  object-fit: fill;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-products-col-brand {
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-products-col-qty-ask {
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 8%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-products-col-qty-collect {
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 14%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  position: relative;
  overflow: visible;
}

.order-details-products-col-qty-substitute {
  cursor: pointer;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 8%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-products-col-qty-returned {
  border: 1px #000;
  border-right-color: #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 8%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-products-col-eans {
  border-right: 1px solid #858585;
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 14%;
  display: flex;
  position: relative;
  overflow: visible;
}

.order-details-products-substitute {
  background-color: #c5c5c5;
  border: 1px solid gray;
  border-top-style: none;
  justify-content: center;
  align-items: stretch;
  height: 60px;
  display: flex;
  overflow: visible;
}

.order-details-products-arrow-down {
  font-family: Fontawesome, sans-serif;
}

.dropdown-toggle {
  display: inline-block;
}

.dropdown {
  flex: 1;
  justify-content: center;
  display: none;
}

.order-details-products-eans-id {
  text-align: center;
  flex: none;
}

.order-details-products-eans-count, .order-details-products-eans-dates {
  text-align: center;
}

.div-block-301 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  grid-template: ". ."
                 "Area Area" 1fr
                 / 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: space-around;
  justify-content: center;
  align-items: stretch;
  justify-items: center;
  display: flex;
}

.div-block-302 {
  justify-content: space-around;
  display: flex;
}

.order-details-products-arrow {
  padding-right: 5px;
  font-family: Fontawesome, sans-serif;
  display: block;
}

.order-details-products-col-irefc-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.order-details-products-eans-dropdown {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  display: block;
  position: static;
}

.order-details-products-eans-trigger {
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
}

.order-details-products-eans-content {
  background-color: gray;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
  overflow: visible;
}

.order-details-products-eans-item {
  z-index: 50;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #fff;
  border: 1px #b9b9b9;
  border-bottom-style: solid;
  flex-direction: column;
  flex: 1;
  grid-template: "."
  / 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 10px;
  display: grid;
  position: relative;
  overflow: visible;
}

.dropdown-2 {
  text-align: center;
  width: 100%;
  height: 100%;
}

.order-details-products-eans-wrapper {
  flex: 1;
  overflow: visible;
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 1px 2px rgba(0, 0, 0, .12);
}

.order-details-products-eans-item-sub {
  z-index: 50;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #c5c5c5;
  border: 1px solid #b9b9b9;
  border-top-style: none;
  flex-direction: column;
  flex: 1;
  grid-template: "."
  / 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: end;
  justify-content: end;
  align-items: stretch;
  justify-items: stretch;
  padding: 10px;
  display: grid;
  position: relative;
  overflow: visible;
}

.order-details-products-collect-dropdown {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  display: block;
  position: static;
}

.order-details-collect-container {
  margin: 5%;
  display: block;
}

.div-block-303 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 1;
  grid-template: "Area Area-2 Area-3" 30px
                 "Area-4 Area-5 Area-5" 30px
                 "Area-6 Area-5 Area-5" 30px
                 / .5fr .5fr .5fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  padding: 10px;
  display: grid;
}

.div-block-304 {
  border: 1px solid #858585;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.order-details-mission-status {
  font-weight: 700;
}

.div-block-304-copy {
  border: 1px solid #858585;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.text-block-206 {
  flex: none;
}

.order-details-mission-summary {
  flex: 0 auto;
}

.order-details-products-col-code {
  border: 1px #000;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 8%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-mission {
  margin-bottom: 5%;
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 1px 2px rgba(0, 0, 0, .12);
}

.order-details-info-mission {
  border: 1px solid #858585;
  border-bottom: 1px #000;
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  overflow: auto;
}

.text-block-209 {
  text-align: center;
  font-weight: 700;
}

.order-details-mission-total {
  cursor: default;
  border: 1px solid gray;
  border-top-style: none;
  justify-content: center;
  align-items: stretch;
  height: 60px;
  display: flex;
  overflow: visible;
}

.cce-promise, .cce-opening-from-2, .cce-opening-to-2 {
  width: 100px;
  height: 40px;
}

.order-details-mission-label {
  width: 200px;
}

.order-details-mission-label.little {
  width: 120px;
}

.order-details-shipping-container {
  margin: 5%;
}

.text-block-210 {
  color: #fff;
}

.div-block-305 {
  justify-content: space-around;
  display: flex;
}

.div-block-303-copy {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 1;
  grid-template: "Area Area-2 Area-3" 30px
                 "Area-4 Area-5 Area-5" 30px
                 / .5fr .5fr .5fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  padding: 10px;
  display: grid;
}

.order-details-shipping-scanned-list {
  border: 1px #858585;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  display: flex;
}

.order-details-shipping {
  background-color: var(--mpx_primary);
  color: #fff;
  text-align: center;
  border-left: 1px #858585;
  border-right: 1px #858585;
  width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
}

.order-details-shipping-id {
  text-align: center;
  border-right: 1px #858585;
  width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.order-details-shipping-lists {
  border: 1px solid #858585;
  border-top-style: none;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

.order-details-shipping-missing-list {
  background-color: rgba(255, 0, 0, .26);
  border: 1px #858585;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  display: flex;
}

.order-details-shipping-item {
  border-top: 1px solid #858585;
  border-left: 1px #858585;
  border-right: 1px #858585;
}

.order-details-info-shipping {
  border: 1px solid #858585;
  border-bottom: 1px #000;
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  overflow: auto;
}

.order-details-shipping-wrapper-scanned {
  border: 1px solid #858585;
  margin-right: 5px;
  padding-right: 0;
  display: flex;
}

.order-details-shipping-wrapper-missing {
  border: 1px solid #858585;
  margin-left: 5px;
}

.order-detail-shipping-summary {
  margin-bottom: 0%;
}

.div-block-306 {
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 0 2px rgba(0, 0, 0, .12);
}

.order-details-return-container {
  margin: 5%;
}

.order-details-return-col-irefc {
  cursor: default;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-return-col-designation {
  object-fit: fill;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-return-col-brand {
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-return-col-code {
  border: 1px #000;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-return-col-qty-ask {
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-return-col-qty-substitute {
  cursor: pointer;
  border-right: 1px solid #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-return-col-qty-returned {
  border: 1px #000;
  border-right-color: #858585;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  overflow: auto;
}

.order-details-substitute-list {
  cursor: default;
  background-color: rgba(255, 0, 0, .26);
  border: 1px solid gray;
  border-top-style: none;
  justify-content: center;
  align-items: stretch;
  height: 60px;
  display: flex;
  overflow: visible;
}

.order-details-return-list {
  cursor: default;
  border: 1px solid gray;
  border-top-style: none;
  justify-content: center;
  align-items: stretch;
  height: 60px;
  display: flex;
  overflow: visible;
}

.main-content {
  padding-top: 100px;
  padding-bottom: 100px;
}

.fsm-modal {
  z-index: 100;
  background-color: rgba(66, 66, 66, .34);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.fsm-modal-content {
  background-color: #fff;
  flex-direction: row;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  height: 70%;
  display: flex;
  position: relative;
  box-shadow: 0 0 3px rgba(0, 0, 0, .24), 1px 1px 2px rgba(0, 0, 0, .12);
}

.fsm-modal-header {
  background-color: var(--mpx_primary);
  border-bottom: 1px solid #858585;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.fsm-modal-date {
  color: #000;
  text-align: center;
  border-right: 1px solid #858585;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 10px;
  display: flex;
}

.fsm-modal-destination, .fsm-modal-action {
  color: #000;
  border-right: 1px solid #858585;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 10px;
  display: flex;
}

.fsm-modal-origin {
  color: #000;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 10px;
  display: flex;
}

.fsm-modal-row {
  cursor: pointer;
  border-bottom: 1px solid #858585;
  display: flex;
}

.fsm-modal-row.fsm-modal-row-selected {
  background-color: #ffd0d0;
}

.fsm-modal-header-text {
  color: #fff;
  text-align: center;
}

.div-block-307 {
  position: relative;
  top: 0%;
  left: 50%;
}

.fsm-modal-row-text {
  color: #000;
  text-align: center;
}

.fsm-modal-logs {
  border: 1px solid #858585;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.fsm-modal-fsm-container {
  border-top: 1px solid #858585;
  border-bottom: 1px solid #858585;
  border-right: 1px solid #858585;
  flex: 1;
  height: 100%;
}

.div-block-308 {
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.text-block-211 {
  text-align: center;
  font-family: Fontawesome, sans-serif;
  font-size: 28px;
}

.fsm-modal-close {
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.tml-order-content-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1330px;
  max-height: 100vh;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.tml-order-content-modal.hidden {
  display: none;
}

.print-ordertab-tml-line {
  border-bottom: 2px solid #f1ebed;
  justify-content: space-between;
  padding: 7px 8px;
  display: flex;
}

.missions-delivery-table, .missions-restock-table {
  margin-bottom: 10px;
}

.types-element {
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}

.types-select {
  border: 2px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.types-select.selected {
  background-color: #fd323d;
}

.types-select.selected.mission {
  background-color: #227092;
}

.types-select.mission {
  border-color: #227092;
}

.states-element-copy {
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}

.states-select-copy {
  border: 1px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.states-select-copy.selected {
  background-color: #fd323d;
}

.states-select-copy.selected.mission {
  background-color: #227092;
}

.states-select-copy.mission {
  border-color: #227092;
}

.modal-content-order-tml {
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

.receipt-modal-array-tml {
  background-color: #fff;
  flex: auto;
  min-width: 850px;
  max-width: 1250px;
}

.receipt-modal-array-tml.spe-ug {
  width: 25vw;
  min-width: 400px;
  max-width: 500px;
}

.print-order-ean-code {
  font-family: Ean13, sans-serif;
  font-size: 90px;
  line-height: 100px;
}

.store-authorization-col5-copy {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.store-authorization-col7 {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.stores-group-container {
  position: relative;
}

.stores-group-table-container {
  margin-top: 25px;
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.stores-group-table-header {
  margin-bottom: 10px;
  display: flex;
  box-shadow: 0 1px 5px rgba(51, 51, 51, .5);
}

.div-block-309 {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stores-group-table-body-row {
  border: 1px solid #dedede;
  display: flex;
}

.div-block-310 {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.store-group-store-name {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 18px;
  display: flex;
}

.store-move-form-wrapper {
  margin-right: 20px;
  padding-left: 20px;
}

.form-17 {
  display: flex;
}

.store-selector, .user-selector {
  margin-right: 20px;
}

.move-store-button {
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  padding: 5px 15px;
  display: flex;
}

.move-store-button:hover {
  background-color: rgba(250, 53, 67, .9);
}

.move-store-button.impression-degrade {
  margin-right: 10px;
}

.move-store-button.paginate-actions {
  margin-left: 10px;
}

.move-store-button.paginate-action {
  margin-top: 5px;
  margin-bottom: 5px;
}

.move-store-button.paginate-action.missions-button:hover {
  background-color: rgba(34, 112, 146, .9);
}

.move-store-button.missions-button.clear-date-btn {
  flex: 0 auto;
  width: 40px;
  min-width: auto;
  height: 38px;
  margin-left: 0;
  padding-left: 17px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
  line-height: 20px;
}

.move-store-button.clear-date-button {
  width: 40px;
  min-width: 40px;
  height: 38px;
  margin-left: 0;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.move-store-button.clear-date-button.missions-button:hover {
  background-color: rgba(34, 112, 146, .9);
}

.move-store-button.clear-date-button.orders-button {
  min-width: 40px;
}

.move-store-button.orders-search-button {
  margin-left: 10px;
}

.move-store-button.list-missingp-button {
  flex: 0 auto;
  margin-left: 10px;
}

.move-store-button.clear-date-from {
  width: 40px;
  min-width: 40px;
  height: 38px;
  margin-left: 0;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.move-store-button.clear-date-from.orders-button {
  min-width: 40px;
}

.move-store-button.clear-date-to {
  width: 40px;
  min-width: 40px;
  height: 38px;
  margin-left: 0;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Fontawesome, sans-serif;
  font-size: 25px;
}

.move-store-button.clear-date-to.orders-button {
  min-width: 40px;
}

.move-store-button.list-missing-button {
  flex: 0 auto;
  margin-left: 10px;
}

.move-store-button.printslip-print-btn {
  margin-bottom: 10px;
}

.move-store-button.printslip-print-btn.not-printed {
  margin-top: 10px;
}

.move-store-button.printsub-print-btn {
  margin-bottom: 10px;
}

.move-store-button.print-order-print-btn, .move-store-button.mission-labels-print-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.printslip--catline, .printslip-tray-catline-bag {
  border-bottom: 1px solid rgba(139, 68, 92, .36);
  justify-content: space-between;
  display: flex;
}

.scanner-subs-modal {
  background-color: #f5f5f5;
  flex-direction: column;
  max-width: 500px;
  margin-left: 30px;
  margin-right: 30px;
  box-shadow: 1px 1px 11px rgba(51, 51, 51, .5);
}

.scanner-subs-modal.hidden {
  display: none;
}

.ean-subs-line {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.kbrw-datetime-input-wrapper {
  height: 5vh;
  margin-top: 40px;
}

.input-datetime {
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  width: 200px;
  margin-bottom: 0;
  font-weight: 400;
  transition: padding-bottom .25s, border-width .25s;
}

.input-datetime:hover {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 8px;
}

.input-datetime:focus {
  border-style: solid;
  border-color: #c9c9c9;
  border-bottom-width: 1px;
}

.kbrw-datetime-range-input-wrapper {
  height: 5vh;
  margin-top: 40px;
}

.container-2 {
  background-color: #fff;
  min-width: 100vw;
  min-height: 100vh;
  font-family: Lato, sans-serif;
  font-weight: 300;
}

.kbrw-input-dropdown-multi {
  z-index: 10;
  background-color: #fdfdfd;
  max-height: 100px;
  position: relative;
  overflow: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.kbrw-switch-choices-container {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.kbrw-integer-test-input-wrapper {
  height: 5vh;
  margin-top: 40px;
}

.kbrw-checkbox-empty {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.kbrw-range-right {
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: auto;
  padding-left: 10px;
  display: flex;
}

.div-block {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.kbrw-text-range-wrapper {
  height: 5vh;
  margin-top: 40px;
}

.input-labelized-2 {
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  margin-bottom: 0;
  padding-bottom: 6px;
  padding-left: 5px;
  font-weight: 400;
  text-decoration: none;
  transition: padding-bottom .25s, border-width .25s;
}

.input-labelized-2:hover {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 5px;
}

.input-labelized-2:focus {
  border-style: solid;
  border-color: #c9c9c9;
  border-bottom-width: 1px;
}

.input-number {
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  flex: none;
  margin-bottom: 0;
  transition: padding-bottom .25s, border-width .25s;
}

.input-number:hover {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 8px;
}

.input-number:focus {
  border-style: solid;
  border-color: #c9c9c9;
  border-bottom-width: 1px;
}

.kbrw-checkbox-wrapper {
  color: rgba(0, 0, 0, 0);
  width: 4vw;
  height: 4vw;
  font-size: 3vw;
}

.kbrw-checkbox-wrapper.dropdown {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.kbrw-color-display {
  background-color: #943c3c;
  width: 20px;
  height: 20px;
  padding: 5px;
}

.kbrw-range-wrapper {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.test-progress-container {
  border: 1px solid #000;
  height: 4vh;
  margin-top: 220px;
}

.kbrw-lightcheckbox-content {
  color: #000;
  font-size: 25px;
}

.kbrw-lightcheckbox-content.checked {
  font-family: "Fa solid 900", sans-serif;
}

.kbrw-progress-bar-label {
  text-align: center;
}

.input-range-number {
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  width: 80px;
  margin-bottom: 0;
  font-weight: 400;
  transition: padding-bottom .25s, border-width .25s;
}

.input-range-number:hover {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 8px;
}

.input-range-number:focus {
  border-style: solid;
  border-color: #c9c9c9;
  border-bottom-width: 1px;
}

.kbrw-input-container-2 {
  width: 100%;
  height: 100%;
}

.kbrw-input-container-2.error {
  border: 1px solid red;
}

.kbrw-date {
  flex: 0 auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: auto;
  display: flex;
}

.kbrw-checkbox-validated-wrapper {
  display: none;
}

.kbrw-checkbox-validated-wrapper.checked {
  object-fit: scale-down;
  align-items: stretch;
  display: block;
}

.kbrw-interger-input-form {
  align-items: stretch;
  width: 100%;
  display: flex;
}

.kbrw-date-container {
  width: 100%;
  display: flex;
}

.kbrw-input-wrapper-2 {
  width: 200px;
}

.kbrw-range-middle {
  justify-content: center;
  align-items: center;
  width: 20px;
  display: flex;
}

.kbrw-input-error-message {
  margin-top: 2px;
  margin-left: 2px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
}

.kbrw-input-dropdown-row-text {
  flex: 1;
  margin-right: 2px;
}

.kbrw-progress-bar-fill-container {
  flex: 1;
  height: 100%;
  display: flex;
}

.kbrw-switch-wrapper {
  background-color: rgba(33, 150, 243, .43);
  border-radius: 7px;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 2px #000;
}

.kbrw-switchs-wrapper {
  height: 5vh;
  margin-top: 40px;
}

.form-no-offset {
  flex: 1;
  align-self: auto;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.kbrw-progress-bar {
  height: 100%;
  position: relative;
}

.kbrw-range-left {
  flex: 0 auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: auto;
  display: flex;
}

.kbrw-color-text-input-wrapper {
  width: 130px;
  height: 5vh;
  margin-top: 40px;
}

.kbrw-contained-image {
  width: 100%;
  height: 100%;
  margin: auto;
}

.bash-warehouse-progress-bar-fill {
  width: 70%;
  height: 100%;
  display: flex;
}

.kbrw-color-input-form {
  align-items: stretch;
  width: 100%;
  display: flex;
}

.kbrw-input-dropdown {
  z-index: 10;
  background-color: #fdfdfd;
  max-height: 100px;
  position: relative;
  overflow: auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 1px 3px rgba(0, 0, 0, .24);
}

.input-color {
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  flex: none;
  margin-bottom: 0;
  transition: padding-bottom .25s, border-width .25s;
}

.input-color:hover {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 8px;
}

.input-color:focus {
  border-style: solid;
  border-color: #c9c9c9;
  border-bottom-width: 1px;
}

.kbrw-input-dropdown-row {
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 25px;
  transition: background-color .25s;
}

.kbrw-input-dropdown-row:hover {
  background-color: #e0e0e0;
}

.kbrw-input-dropdown-row:active {
  background-color: #c9c9c9;
}

.kbrw-input-dropdown-row-multi {
  cursor: pointer;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  transition: background-color .25s;
  display: flex;
}

.kbrw-input-dropdown-row-multi:hover {
  background-color: #e1e1e1;
}

.kbrw-input-dropdown-row-multi:active {
  background-color: #c9c9c9;
}

.input-range-datetime {
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  width: 200px;
  margin-bottom: 0;
  font-weight: 400;
  transition: padding-bottom .25s, border-width .25s;
}

.input-range-datetime:hover {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 8px;
}

.input-range-datetime:focus {
  border-style: solid;
  border-color: #c9c9c9;
  border-bottom-width: 1px;
}

.kbrw-switch-choice {
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 100%;
  margin-left: 0;
  padding-left: 5px;
  padding-right: 5px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.kbrw-switch-choice.selected {
  cursor: default;
  background-color: rgba(33, 150, 243, .45);
  border-radius: 8px;
  font-weight: 700;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.kbrw-integer-input-wrapper {
  flex: 1;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
}

.kbrw-input-dropdown-wrapper {
  height: 0;
}

.kbrw-range-form {
  width: 100%;
  display: flex;
}

.kbrw-buttons-wrapper {
  justify-content: space-around;
  align-items: center;
  min-width: 30vw;
  padding-top: 5px;
  display: flex;
}

.kbrw-datetime-wrapper, .kbrw-datetime-range-wrapper {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.kbrw-color-input-wrapper {
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.kbrw-progress-bar-label-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .24), 0 1px 3px rgba(0, 0, 0, .24);
}

.div-block-311 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.kbrw-page-selector-list {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.kbrw-page-selector-page-number {
  justify-content: space-between;
  align-items: center;
}

.kbrw-page-selector {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.kbrw-page-selector:hover {
  background-color: #e0e0e0;
  border-radius: 10px;
}

.kbrw-page-selector.disabled {
  color: #a3a3a3;
  cursor: default;
}

.kbrw-page-selector.disabled:hover {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
}

.kbrw-page-selector-wrapper {
  justify-content: flex-start;
  align-items: center;
  margin: 5px auto;
  display: flex;
}

.kbrw-page-selector-page-number-container {
  text-align: left;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px 5px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.kbrw-page-selector-page-number-container:hover, .kbrw-page-selector-page-number-container.selected {
  color: #fff;
  background-color: #000;
  border-radius: 10px;
}

.div-block-312 {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.kbrw-embeded-icon {
  width: 100%;
  height: 100%;
}

.div-block-313 {
  height: 75px;
  display: flex;
}

.div-block-313.modification {
  justify-content: center;
  align-items: center;
}

.div-block-314 {
  border: 1px solid #000;
  padding: 5px;
}

.snack-wrapper-2 {
  z-index: 100;
  max-height: 500px;
  margin: 50px;
  font-size: 16px;
  transition: margin-top .25s, max-height .25s;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.kbrw-snack-success {
  color: #fff;
  background-color: #009a31;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.kbrw-snack-success-message {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.kbrw-snack-success-close {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.kbrw-snack-warning {
  color: #fff;
  background-color: #ffa600;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.kbrw-snack-warning-message {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.kbrw-snack-warning-close {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.kbrw-snack-error {
  color: #fff;
  background-color: #9a0000;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
  position: static;
}

.kbrw-snack-error-message {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.kbrw-snack-error-close {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.kbrw-checkbox {
  cursor: pointer;
  background-color: #333;
  border-radius: 15%;
  width: 100%;
  height: 100%;
  transition: background-color .25s;
}

.kbrw-checkbox:hover {
  background-color: #444;
}

.kbrw-checkbox:active {
  background-color: #666;
}

.kbrw-button-flat {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 700;
  transition: background-color .25s;
}

.kbrw-button-flat:hover {
  background-color: #f1f1f1;
}

.kbrw-button-flat:active {
  background-color: #e0e0e0;
}

.kbrw-button-flat.secondary {
  color: #b00;
}

.kbrw-button-flat.secondary:hover {
  background-color: #fff5f5;
}

.kbrw-button-flat.secondary:active {
  background-color: #fadbdb;
}

.kbrw-button-flat.disabled {
  color: #a3a3a3;
  cursor: not-allowed;
}

.kbrw-button-flat.disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.kbrw-button-flat.round {
  border-radius: 20px;
}

.kbrw-button-flat.primary {
  color: #2196f3;
}

.kbrw-button-flat.primary:hover {
  background-color: #e2f1fb;
}

.kbrw-button-flat.primary:active {
  background-color: #badcf8;
}

.buttons-container {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.adc-admin-color-svg {
  color: red;
  width: 40px;
  height: 40px;
}

.kbrw-button {
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 700;
  transition: box-shadow .25s, background-color .25s;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.kbrw-button:hover {
  background-color: #c9c9c9;
  box-shadow: 0 0 7px rgba(0, 0, 0, .12), 0 5px 7px rgba(0, 0, 0, .24);
}

.kbrw-button:active {
  background-color: #a3a3a3;
  box-shadow: 0 0 10px rgba(0, 0, 0, .12), 0 7px 10px rgba(0, 0, 0, .24);
}

.kbrw-button.secondary {
  color: #fff;
  background-color: #b00;
}

.kbrw-button.secondary:hover {
  background-color: #ac0002;
}

.kbrw-button.secondary:active {
  background-color: #9a0000;
}

.kbrw-button.round {
  border-radius: 25px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.kbrw-button.disabled {
  color: #a3a3a3;
  cursor: not-allowed;
}

.kbrw-button.disabled:hover {
  background-color: #e0e0e0;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.kbrw-button.primary {
  color: #fff;
  background-color: #2196f3;
}

.kbrw-button.primary:hover {
  background-color: #1d87e4;
}

.kbrw-button.primary:active {
  background-color: #1d76d2;
}

.kbrw-button-icon {
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 700;
  transition: box-shadow .25s, background-color .25s;
  box-shadow: 0 0 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.kbrw-button-icon:hover {
  background-color: #c9c9c9;
  box-shadow: 0 0 7px rgba(0, 0, 0, .12), 0 5px 7px rgba(0, 0, 0, .24);
}

.kbrw-button-icon:active {
  background-color: #a3a3a3;
  box-shadow: 0 0 10px rgba(0, 0, 0, .12), 0 7px 10px rgba(0, 0, 0, .24);
}

.kbrw-button-icon.primary {
  color: #fff;
  background-color: #2196f3;
}

.kbrw-button-icon.primary:hover {
  background-color: #1d87e4;
}

.kbrw-button-icon.primary:active {
  background-color: #1d76d2;
}

.kbrw-button-icon.secondary {
  color: #fff;
  background-color: #b00;
}

.kbrw-button-icon.secondary:hover {
  background-color: #ac0002;
}

.kbrw-button-icon.secondary:active {
  background-color: #9a0000;
}

.kbrw-button-flat-line {
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 700;
  transition: background-color .25s;
}

.kbrw-button-flat-line:hover {
  background-color: #f1f1f1;
}

.kbrw-button-flat-line:active {
  background-color: #e0e0e0;
}

.kbrw-button-flat-line.secondary {
  color: #b00;
  border-color: #b00;
}

.kbrw-button-flat-line.secondary:hover {
  background-color: #fff5f5;
}

.kbrw-button-flat-line.secondary:active {
  background-color: #fadbdb;
}

.kbrw-button-flat-line.round {
  border-radius: 20px;
}

.kbrw-button-flat-line.primary {
  color: #2196f3;
  border-color: #2196f3;
}

.kbrw-button-flat-line.primary:hover {
  background-color: #e2f1fb;
}

.kbrw-button-flat-line.primary:active {
  background-color: #badcf8;
}

.kbrw-button-flat-line.disabled {
  cursor: not-allowed;
}

.kbrw-button-flat-line.disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.stores-filter-container {
  padding: 10px 15px;
  display: flex;
}

.slot-modified {
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: var(--steel-blue-2) var(--steel-blue-2) transparent black;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.store-authorization-col8 {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.store-authorization-col7-copy {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 12%;
  display: flex;
}

.alert-hour {
  width: 100px;
  height: 40px;
}

.intervention-preview-button {
  color: #303030;
  cursor: pointer;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 5px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.intervention-preview-button:hover {
  color: rgba(48, 48, 48, .8);
}

.intervention-preview-button.hidden {
  display: none;
}

.intervention-history-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1330px;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.intervention-history-modal.hidden {
  display: none;
}

.history-modal-header {
  background-color: #fff;
  flex-direction: column;
  flex: auto;
  width: 100%;
  min-width: 850px;
  max-width: 1250px;
  margin-bottom: 20px;
}

.history-modal-header.spe-ug {
  width: 25vw;
  min-width: 400px;
  max-width: 500px;
}

.history-modal-content-wrapper {
  clear: none;
  object-fit: fill;
  flex-flow: column;
  align-content: center;
  align-items: center;
  max-height: 50vh;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  position: static;
  overflow: auto;
}

.history-modal-content-wrapper.hidden {
  display: none;
}

.history-modal-content {
  object-fit: fill;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 850px;
  max-width: 1250px;
  margin-bottom: 20px;
  position: static;
  overflow: visible;
}

.history-modal-content.spe-ug {
  width: 25vw;
  min-width: 400px;
  max-width: 500px;
}

.history-modal-content-body {
  justify-content: center;
  align-items: stretch;
  display: block;
}

.history-header-label {
  text-align: center;
  align-self: center;
  width: 19%;
  font-size: 18px;
  font-weight: 500;
}

.history-header-label.mission-nbr {
  text-align: left;
  width: 100%;
  padding-left: 10px;
}

.receipt-modal-array-row-copy {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  justify-content: center;
  min-height: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
}

.receipt-modal-array-row-copy:hover {
  background-color: rgba(0, 0, 0, .03);
}

.receipt-modal-array-row-copy.header {
  padding-top: 14px;
}

.history-body-row {
  text-align: center;
  justify-content: center;
  align-self: center;
  height: auto;
  min-height: 50px;
  display: flex;
}

.history-body-row-content {
  align-self: center;
  width: 19%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
}

.history-body-row-content.rank {
  width: 5%;
  font-size: 18px;
}

.div-block-315 {
  width: 5%;
}

.client-view-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1330px;
  max-height: 100vh;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.client-view-modal.hidden {
  display: none;
}

.client-view-wrapper {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: row;
  flex: auto;
  justify-content: space-between;
  width: 100%;
  min-width: 850px;
  max-width: 1250px;
  margin-bottom: 0;
  display: flex;
}

.client-view-wrapper.spe-ug {
  width: 25vw;
  min-width: 400px;
  max-width: 500px;
}

.client-view-content-day {
  color: #656565;
  text-align: center;
  background-color: rgba(0, 0, 0, .07);
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.client-view-content-row {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, .07);
  margin-bottom: 20px;
}

.client-view-content-row-body {
  height: auto;
  min-height: auto;
  max-height: none;
  padding-bottom: 10px;
  display: flex;
  overflow: auto;
}

.client-view-slot-wrapper.afternoon, .client-view-slot-wrapper.evening {
  margin-top: 15px;
}

.client-view-slot-header {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}

.client-view-slot-body {
  text-align: center;
}

.client-view-slot-body-row {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.client-view-slot-body-row.green {
  border: 1px solid var(--medium-sea-green);
  color: #fff;
  background-color: #65ca8e;
}

.client-view-slot-body-row.red {
  border: 1px solid var(--mpx_primary_darken);
  color: #fff;
  background-color: #fd323d;
}

.div-block-316 {
  display: flex;
}

.unused-ug-icon {
  cursor: pointer;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  font-size: 18px;
  display: flex;
}

.div-block-317 {
  margin-left: 10px;
  display: flex;
}

.unused-ug-number {
  padding-bottom: 10px;
}

.unused-ug-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 25vw;
  min-width: 450px;
  max-width: 720px;
  max-height: 100vh;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.unused-ug-modal.hidden {
  display: none;
}

.client-view-content-col {
  width: 15%;
}

.client-view-modal-content {
  flex-direction: column;
  align-items: center;
  max-height: 500px;
  padding: 0 20px 10px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  overflow: auto;
}

.client-view-modal-content.hidden {
  display: none;
}

.orders-status {
  border-bottom: 1px solid #dedede;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.orders-status-copy {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.orders-sub-status {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.substatus {
  border-right: 1px solid #dedede;
  width: 29%;
  height: 100%;
}

.substatus.in-collect, .substatus.to-complete {
  border-right: 1px solid #dedede;
}

.orders-status-details {
  justify-content: center;
  align-items: center;
  width: 13%;
  height: 100%;
  display: flex;
}

.substatus-label {
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.substatus-label.fa {
  font-family: Fontawesome webfont, sans-serif;
}

.substatus-label.product-number {
  width: 70%;
}

.substatus-label.product-date.hidden {
  display: none;
}

.substatus-label.id-picker {
  flex: 1;
}

.substatus-label.id-picker.hidden {
  display: none;
}

.substatus-label.bac-number {
  color: #000;
  font-weight: 400;
}

.substatus-label.mission-state {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.substatus-label.mission-state.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.substatus-label.missing-number {
  width: 70%;
}

.substatus-label.check-all {
  font-size: 30px;
}

.substatus-label.order-customer {
  text-align: center;
  flex: none;
  width: 70%;
}

.substatus-label.order-type {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.substatus-label.order-type.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.substatus-label.order-type {
  color: #000;
  width: 70%;
  font-weight: 400;
}

.substatus-label.bac-number-copy, .substatus-label.order-univers {
  color: #000;
  font-weight: 400;
}

.status-preview-button {
  cursor: pointer;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 5px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.status-preview-button:hover {
  color: rgba(48, 48, 48, .8);
}

.status-preview-button.hidden {
  display: none;
}

.orders-sub-status-header {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.substatus-header {
  border-right: 1px #dedede;
  width: 29%;
  height: 100%;
}

.substatus-header.in-collect, .substatus-header.to-complete {
  border-right: 1px solid #dedede;
}

.substatus-header.done {
  border-right-style: solid;
}

.orders-sub-status-content {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.substatus-label-content {
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.substatus-label-content.fa {
  font-family: Fontawesome webfont, sans-serif;
}

.substatus-label-content.product-number {
  width: 70%;
}

.substatus-label-content.product-date.hidden {
  display: none;
}

.substatus-label-content.id-picker {
  flex: 1;
}

.substatus-label-content.id-picker.hidden {
  display: none;
}

.substatus-label-content.bac-number {
  color: #000;
  font-weight: 400;
}

.substatus-label-content.mission-state {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.substatus-label-content.mission-state.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.substatus-label-content.missing-number {
  width: 70%;
}

.substatus-label-content.check-all {
  font-size: 30px;
}

.substatus-label-content.order-customer {
  text-align: center;
  flex: none;
  width: 70%;
}

.substatus-label-content.order-type {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.substatus-label-content.order-type.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.substatus-label-content.order-type {
  color: #000;
  width: 70%;
  font-weight: 400;
}

.substatus-label-content.bac-number-copy, .substatus-label-content.order-univers {
  color: #000;
  font-weight: 400;
}

.substatus-label-copy {
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.substatus-label-copy.fa {
  font-family: Fontawesome webfont, sans-serif;
}

.substatus-label-copy.product-number {
  width: 70%;
}

.substatus-label-copy.product-date.hidden {
  display: none;
}

.substatus-label-copy.id-picker {
  flex: 1;
}

.substatus-label-copy.id-picker.hidden {
  display: none;
}

.substatus-label-copy.bac-number {
  color: #000;
  font-weight: 400;
}

.substatus-label-copy.mission-state {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.substatus-label-copy.mission-state.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.substatus-label-copy.missing-number {
  width: 70%;
}

.substatus-label-copy.check-all {
  font-size: 30px;
}

.substatus-label-copy.order-customer {
  text-align: center;
  flex: none;
  width: 70%;
}

.substatus-label-copy.order-type {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  padding: 6px;
}

.substatus-label-copy.order-type.mission-state-red {
  color: #fff;
  background-color: #ff7b82;
}

.substatus-label-copy.order-type {
  color: #000;
  width: 70%;
  font-weight: 400;
}

.substatus-label-copy.bac-number-copy, .substatus-label-copy.order-univers {
  color: #000;
  font-weight: 400;
}

.notification-modal {
  background-color: #f5f5f5;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 100vh;
  display: block;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, .5);
}

.notification-modal.hidden {
  display: none;
}

.modal-content-notification {
  flex-flow: column;
  min-width: 60px;
  max-width: 60vw;
  max-height: 500px;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

.modal-content-notification.hidden {
  display: none;
}

.notification-modal-array {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  overflow: auto;
}

.notification-modal-array.spe-ug {
  width: 25vw;
  min-width: 400px;
  max-width: 500px;
}

.notification-modal-content-header {
  color: #656565;
  background-color: rgba(0, 0, 0, .07);
  justify-content: flex-start;
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  display: flex;
}

.notification-modal-content-header.mission-nbr {
  justify-content: flex-start;
}

.notification-modal-array-content {
  justify-content: center;
  align-items: center;
}

.notification-header-label {
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.notification-header-label.date {
  width: 30%;
}

.notification-header-label.message {
  width: 70%;
}

.notification-content-row-data {
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.notification-content-row-data.message {
  width: 68%;
  padding-right: 10px;
}

.notification-content-row-data.date {
  width: 25%;
}

.notification-content-row {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  align-content: center;
  min-height: 50px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
}

.notification-content-row:hover {
  background-color: rgba(0, 0, 0, .03);
}

.notification-content-row.header {
  padding-top: 14px;
}

.notification-content-row.hidden {
  display: none;
}

.modal-no-content {
  text-align: center;
}

.header-notification {
  cursor: pointer;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-end;
  margin-right: 10px;
  display: flex;
}

.header-notification-nbr {
  text-align: center;
  text-transform: uppercase;
  padding-right: 5px;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.header-notification-nbr.hidden {
  display: none;
}

.notification-delete {
  align-items: center;
  width: 7%;
  padding-right: 5px;
  display: flex;
}

.notification-delete-btn {
  cursor: pointer;
  padding-right: 0;
}

.notification-info {
  cursor: pointer;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  font-size: 20px;
  display: flex;
}

.email-valid-img {
  width: 163px;
  display: block;
}

.email-logo-container {
  flex: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  display: flex;
}

.email-content-container {
  background-color: #fff;
  border: 1px #000;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  padding: 20px;
  display: flex;
}

.email-content-container.hidden {
  display: none;
}

.email-content-container.product-list-wrapper {
  flex-wrap: nowrap;
  overflow: auto;
}

.email-content-container.order-info-wrapper {
  align-items: stretch;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.email-content-container.item-image {
  flex: 0 auto;
  align-items: center;
}

.email-content-container.product-picking-report {
  flex: 0 auto;
}

.email-content-container.item-description-wrapper {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.email-validation-text {
  font-size: 18px;
}

.body-5, .body-6 {
  font-size: 14px;
}

.create-form-wrapper {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.create-icon, .refresh-icon {
  color: #fff;
  padding-left: 10px;
  font-family: Fontawesome, sans-serif;
  font-size: 22px;
  display: inline-block;
}

.formation-order-input-wrapper {
  flex: none;
  align-items: center;
  width: 48%;
  margin-bottom: 10px;
  display: flex;
}

.formation-order-input-wrapper.status-line.hidden {
  display: none;
}

.formation-order-input-wrapper.order-store {
  flex: 0 auto;
  width: auto;
  margin-bottom: 0;
}

.stores-label {
  font-weight: 700;
}

.formation-row {
  flex-flow: wrap;
  flex: 1;
  align-content: stretch;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.formation-row.states-row, .formation-row.types-row {
  align-content: stretch;
  margin-bottom: 10px;
}

.formation-row.types-row {
  align-content: stretch;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.create-order-form-actions {
  flex-wrap: wrap;
  flex: 1;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.formation-content-wrapper {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.formation-store {
  flex: 1;
  margin-bottom: 0;
  margin-left: 10px;
}

.grid {
  grid-template-columns: 1fr 1fr 1fr;
}

.new-order-tab-header {
  border: 1px solid rgba(0, 0, 0, .39);
  justify-content: space-around;
  margin-bottom: 5px;
  display: flex;
}

.tab-container {
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tab-container.awaiting_orders {
  margin-top: 10px;
}

.field-header {
  border: 1px solid rgba(0, 0, 0, .28);
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 5px 10px;
  display: flex;
}

.field-header.create-order-store-field-header {
  justify-content: center;
  align-items: center;
  width: 20%;
  display: flex;
}

.field-header.create-order-courses-field-header, .field-header.create-order-mode-field-header {
  flex: 0 auto;
  width: 17%;
}

.field-header.create-order-nborder-field-header {
  flex: 0 auto;
  width: 8%;
}

.field-header.create-order-nbproducts-field-header {
  flex: 0 auto;
  width: 12%;
}

.field-header.create-order-date-field-header {
  width: 20%;
}

.field-header.create-order-delete-field-header {
  width: 6%;
}

.field-header.to-create-order-store-field-header {
  justify-content: center;
  align-items: center;
  width: 28%;
  display: flex;
}

.field-header.to-create-order-courses-field-header-copy, .field-header.to-create-order-mode-field-header-copy {
  flex: 0 auto;
  width: 20%;
}

.text-block-212 {
  justify-content: center;
  align-items: center;
  display: block;
}

.store-label-formation, .label-courses-header, .label-mode-header {
  font-weight: 700;
}

.label-nb-order-header, .label-nb-products-header {
  flex: 1;
  font-weight: 700;
}

.label-date-header {
  text-align: center;
  flex: 1;
  font-weight: 700;
}

.new-order-tab-row {
  border: 1px solid rgba(0, 0, 0, .39);
  border-top-style: none;
  justify-content: space-around;
  margin-top: -1px;
  display: flex;
}

.field-formation {
  border: 1px solid rgba(0, 0, 0, .38);
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.field-formation.formation-field-store {
  width: 20%;
}

.field-formation.formation-courses-field {
  align-items: center;
  width: 17%;
}

.field-formation.formation-mode-field {
  width: 17%;
  padding-right: 10px;
}

.field-formation.fomation-nb-order-field {
  text-align: left;
  flex-flow: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: center;
  width: 8%;
  display: flex;
}

.field-formation.formation-nb-products-field {
  width: 12%;
}

.field-formation.formation-delete-field {
  width: 6%;
}

.field-formation.formation-field-store-to-create {
  width: 28%;
}

.field-formation.formation-courses-field-to-create {
  align-items: center;
  width: 20%;
}

.field-formation.formation-mode-field-to-create {
  width: 20%;
  padding-right: 10px;
}

.field-formation.formation-nb-products-field-to-create {
  width: 12%;
}

.field-formation.formation-delete-field-to-create {
  width: 6%;
}

.field-formation.formation-date-field, .field-formation.formation-date-field-to-create {
  width: 20%;
}

.label-store-formation {
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  display: flex;
}

.select-field-10 {
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: block;
}

.div-block-318 {
  background-color: var(--orange-red);
}

.delete-icon {
  color: var(--white);
  font-family: Fontawesome, sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.delete-button {
  background-color: var(--orange-red);
  border-radius: 9px;
  flex: 0 auto;
  padding: 5px 5px 8px;
}

.text-field-25 {
  flex: 0 auto;
}

.course-type {
  height: 100%;
  margin-top: 10px;
}

.mode-type {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  font-size: 16px;
  display: flex;
}

.nb-order {
  text-align: right;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  padding-right: 10px;
  font-size: 16px;
  display: flex;
}

.nb-products {
  text-align: right;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-top: 0;
  padding-right: 10px;
  font-size: 16px;
  display: flex;
}

.creation-date {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  font-size: 16px;
  display: flex;
}

.course-type {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-right: 10px;
  font-size: 16px;
  display: flex;
}

.modal-wrapper {
  z-index: 1000;
  background-color: rgba(166, 166, 166, .6);
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrapper.hidden {
  display: none;
}

.add-store-modal {
  z-index: 100;
  background-color: var(--white);
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 60px 20px 20px;
  display: flex;
  overflow: scroll;
}

.title-add-store-modal {
  text-align: center;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 19px;
  font-weight: 700;
}

.modal-course-row {
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
}

.modal-mode-row {
  justify-content: space-between;
  margin-top: 10px;
  display: flex;
}

.modal-nb-order-row {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.modal-nb-products-row {
  align-items: center;
  display: flex;
}

.modal-date-row {
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.modal-validation-row {
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.course-title {
  text-align: right;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
}

.select-field-11 {
  flex: 1;
}

.courses-field, .select-field-12 {
  flex: 1;
  margin-bottom: 0;
  margin-right: 10px;
}

.text-block-213 {
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.service-title {
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
}

.service-field {
  flex: 1;
  justify-content: flex-start;
  max-width: 75%;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.text-field-26 {
  flex: 0 auto;
  width: 60%;
  margin-left: 0;
}

.text-block-214, .text-block-215 {
  width: 25%;
}

.nb-order-title {
  text-align: right;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  margin-bottom: 15px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  display: flex;
}

.text-block-216 {
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding-right: 0;
  display: flex;
}

.nb-order-field {
  text-align: right;
  flex: 1;
  margin-bottom: 0;
  margin-right: 10px;
}

.nb-product-title {
  text-align: right;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  margin-bottom: 15px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  display: flex;
}

.nb-product-field {
  text-align: right;
  flex: 1;
  margin-bottom: 0;
  margin-right: 10px;
}

.date-title {
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
}

.form-block-31 {
  margin-bottom: 0;
}

.cancel-button {
  background-color: var(--orange-red);
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
}

.validation-button {
  color: var(--white);
}

.title-cancel {
  color: var(--white);
  font-weight: 700;
}

.title-validation {
  background-color: var(--orange-red);
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  font-weight: 700;
}

.form-add-orders {
  padding-right: 10px;
}

.mode-row {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: stretch;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.mode-row.states-row, .mode-row.types-row {
  align-content: stretch;
  margin-bottom: 10px;
}

.mode-row.types-row {
  align-content: stretch;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.add-store-form-wrapper {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.add-store-modal-container {
  background-color: var(--white);
  width: 40%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 0;
  position: absolute;
  top: 30%;
  bottom: auto;
  left: 30%;
  right: auto;
}

.modal-wrapper-formation, .modal-wrapper-formation.hidden {
  display: block;
}

.formation-modal-wrapper {
  background-color: var(--black-2);
  width: 40%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-formation-container {
  z-index: 1000;
  background-color: rgba(51, 51, 51, .53);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-formation-container.hidden {
  background-color: rgba(51, 51, 51, .51);
  display: none;
}

.modal-wrapper-copy {
  z-index: 1000;
  background-color: rgba(166, 166, 166, .6);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrapper-copy.hidden {
  display: none;
}

.modal-wrapper-copy-copy {
  z-index: 1000;
  background-color: rgba(166, 166, 166, .6);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrapper-copy-copy.hidden {
  display: none;
}

.hop-opening-from, .hop-opening-to, .hop-opening-from-2, .hop-opening-to-2 {
  width: 100px;
  height: 40px;
}

.date-order-field {
  text-align: right;
  flex: 1;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-field-27 {
  flex: 1;
  max-width: 25%;
}

.time-order-field {
  text-align: right;
  max-width: 25%;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.course-select {
  border: 2px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.course-select.selected {
  background-color: #fd323d;
}

.course-select.selected.mission {
  background-color: #227092;
}

.course-select.mission {
  border-color: #227092;
}

.mode-select {
  border: 2px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.mode-select.selected {
  background-color: #fd323d;
}

.mode-select.selected.mission {
  background-color: #227092;
}

.mode-select.mission {
  border-color: #227092;
}

.stand-by-container {
  flex-direction: column;
  padding-top: 40px;
  display: flex;
}

.text-block-217 {
  justify-content: center;
  font-size: 16px;
  line-height: 1.5em;
  display: flex;
}

.stand-by-options {
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.stand-by-option {
  color: #fff;
  cursor: pointer;
  background-color: #fd323d;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-size: 18px;
  line-height: 1.5em;
  display: flex;
}

.stand-by-option.stand-by-disable {
  margin-left: 40px;
  margin-right: 40px;
}

.sticky-banner-container-header {
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.picker-banner {
  justify-content: center;
  align-items: center;
  min-height: 35px;
  display: flex;
  position: relative;
}

.picker-banner.picker-banner-blue {
  color: #fff;
  background-color: #227092;
}

.picker-banner.picker-banner-blue.picker-banner-stand-by {
  z-index: 1;
  min-height: 30px;
  padding-right: 5px;
}

.formation-tab {
  margin-bottom: 15px;
  display: flex;
}

.div-block-319, .div-block-320, .order-creation-tab, .order-to-create-tab {
  width: 20%;
}

.tab-formation-text {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.awaiting-order-container {
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.awaiting-orders-tab-row {
  border: 1px solid rgba(0, 0, 0, .39);
  border-top-style: none;
  justify-content: space-around;
  margin-top: -1px;
  display: flex;
}

.label-store-to-create {
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  display: flex;
}

.nb-products-to-create {
  text-align: right;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-top: 0;
  padding-right: 10px;
  font-size: 16px;
  display: flex;
}

.creation-date-to-create {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  font-size: 16px;
  display: flex;
}

.formation-store-selector {
  width: 30%;
  margin-left: 10px;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.time-formation-field {
  margin-bottom: 0;
}

.time-formation-field.failed {
  border: 1px solid #fd323d;
}

.formation-add-store {
  color: #999;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 18px;
  display: flex;
}

.formation-add-store.hidden {
  display: none;
}

.formation-remove-store {
  color: #fa3543;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-family: "Fa solid 900", sans-serif;
  font-size: 20px;
  display: flex;
}

.formation-remove-store.hidden {
  display: none;
}

.formation-store-name {
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 15px;
  display: flex;
}

.formation-store-line {
  display: flex;
}

.formation-store-line:hover {
  background-color: #eee;
}

.formation-store-selected-line {
  justify-content: flex-start;
  display: flex;
}

.formation-store-selected-line:hover {
  background-color: #eee;
}

.formation-services {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.order-id-container {
  flex: 0 auto;
  order: 0;
  justify-content: flex-start;
  align-self: center;
  align-items: center;
  display: flex;
}

.order-id-text {
  align-self: center;
}

.id-container {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: stretch;
  display: flex;
}

.text-id {
  display: block;
}

.store-authorization-col9 {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.store-authorization-col8-copy {
  text-align: center;
  border-left: 1px solid #d1d1d1;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.is-all-stores {
  border: 1px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.is-all-stores.selected {
  background-color: #fd323d;
}

.is-all-stores.selected.mission {
  background-color: #227092;
}

.is-all-stores.mission {
  border-color: #227092;
}

.is-monophop {
  border: 1px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.is-monophop.selected {
  background-color: #fd323d;
}

.is-monophop.selected.mission {
  background-color: #227092;
}

.is-monophop.mission {
  border-color: #227092;
}

.is-monoprix {
  border: 1px solid #fd323d;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.is-monoprix.selected {
  background-color: #fd323d;
}

.is-monoprix.selected.mission {
  background-color: #227092;
}

.is-monoprix.mission {
  border-color: #227092;
}

.monophop-wrapper, .monoprix-wrapper {
  cursor: pointer;
  display: flex;
}

.store-users-table {
  margin-left: 20px;
  margin-right: 20px;
}

.store-users-header {
  margin-bottom: 2px;
  display: flex;
}

.store-users-cell {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.store-users-cell.header {
  border-right: 1px solid var(--white);
  background-color: var(--monop-red);
  color: var(--white);
}

.store-users-row {
  margin-bottom: 2px;
  display: flex;
}

.mailing-list-hop {
  align-items: center;
  padding-right: 30px;
  display: flex;
}

.mailing-list-hop.adresse-field {
  padding-bottom: 5px;
}

.mailing-list-hop.bemax-opening, .mailing-list-hop.cce-opening, .mailing-list-hop.hop-opening {
  margin-bottom: 15px;
}

.immininent {
  background-color: #f5d24c;
}

.imminent-mode {
  background-color: #dc324e;
}

.blue-mode {
  background-color: #2a8db8;
}

.store-info-table-header {
  z-index: 1;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-bottom: 1px #000;
  grid-template-rows: auto;
  grid-template-columns: 150px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  grid-auto-columns: 200px;
  width: 100%;
  margin-bottom: 0;
  display: grid;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.store-info-table-body-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px #dedede;
  grid-template-rows: auto;
  grid-template-columns: 150px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
  grid-auto-columns: 200px;
  display: grid;
}

.div-block-321 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.text-block-218 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.text-block-218.only-zebra-error-message {
  color: red;
  object-fit: fill;
  position: static;
}

.blocker {
  z-index: 999;
  background-color: var(--bg);
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.slot-order-button {
  z-index: 1;
  color: #fff;
  cursor: pointer;
  background-color: #fa3543;
  padding: 10px 15px;
  font-size: 25px;
  position: fixed;
  bottom: 30px;
  right: 250px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, .2);
}

.is_order {
  background-color: #fff8e5;
}

@media screen and (max-width: 991px) {
  .content-title-wrappe {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .modal-actions {
    padding-left: 20px;
  }

  .modal.zone-iris-modal {
    overflow: scroll;
  }

  .content-container.dispatch-container {
    align-items: center;
  }

  .items-tab.active {
    background-color: #e8522d;
  }

  .dispatch-title {
    font-size: 22px;
  }

  .dispatch-indication {
    text-align: center;
    width: 100%;
    padding: 20px;
    font-size: 18px;
  }

  .div-block-5 {
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .text-block-14 {
    font-family: Merriweather, serif;
  }

  .colis {
    align-items: stretch;
    display: flex;
  }

  .div-block-9 {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    padding-left: 20px;
    display: flex;
  }

  .package-desc {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .package-desc-item {
    align-items: flex-start;
    display: flex;
  }

  .package-desc-label {
    min-width: 200px;
    padding-right: 20px;
  }

  .product-actions {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .product-remove {
    color: #333;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    font-family: Fontawesome, sans-serif;
    font-size: 40px;
    text-decoration: none;
    display: flex;
  }

  .package-info-spec-value {
    text-align: center;
    min-width: 100px;
  }

  .package-weight-input {
    text-align: center;
  }

  .package-info-total-product {
    text-align: left;
    width: 100%;
    padding-left: 20px;
  }

  .content-wrapper.order-count-wrapper {
    justify-content: space-around;
    align-items: center;
  }

  .bac {
    justify-content: center;
    align-items: center;
  }

  .bo-bac {
    flex: none;
    justify-content: center;
    align-items: center;
  }

  .bo-wrapper-grey.bo-bac-list {
    padding-left: 0;
  }

  .bo-bac-container {
    flex-flow: wrap;
    flex: 1;
  }

  .print-ordertab-h3 {
    width: 4.5cm;
  }

  .paginate-page.active {
    height: auto;
  }

  .modal-actions-2 {
    padding-left: 20px;
  }

  .iris-table-header {
    padding-left: 1px;
    padding-right: 1px;
  }

  .iris-col.iris-col-1, .iris-col.iris-col-2, .iris-col.iris-col-3 {
    flex: none;
    width: 20%;
  }

  .iris-col.iris-col-4 {
    flex: 1;
    width: 20%;
  }

  .iris-col.iris-col-0 {
    flex: none;
    width: 5%;
  }

  .iris-col.iris-col-7 {
    flex: 1;
    width: 20%;
  }

  .iris-header-col-content.spread {
    border-right-style: none;
  }

  .orders-form-container {
    margin-top: 20px;
  }

  .bo-collect-degrade.product-degrade-details {
    align-content: space-around;
  }

  .mpx-view-real-times-params, .div-block-289, .div-block-297 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .product-img-wrapper {
    flex: 0 auto;
  }

  .product-title-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bo-product-ean {
    flex: none;
  }

  .package-info-spec-label {
    width: 180px;
  }

  .package-info-total-product {
    text-align: left;
    padding-left: 20px;
  }

  .form-wrapper {
    flex: none;
    justify-content: flex-start;
    display: flex;
  }

  .content-wrapper {
    flex-flow: column wrap;
    flex: none;
    display: flex;
  }

  .bo-product-description {
    flex-wrap: wrap;
    flex: 1;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
  }

  .bo-product-brand, .bo-product-label {
    flex: none;
  }

  .bo-wrapper-grey.bo-product {
    justify-content: space-between;
    align-items: center;
  }

  .bo-wrapper-grey.bo-bac-list {
    justify-content: center;
  }

  .liste-mission-header {
    align-items: flex-end;
  }

  .product-ean {
    flex: none;
  }

  .table-line {
    flex-wrap: nowrap;
    align-items: center;
  }

  .mission-cell {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mission-header {
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .report-title {
    max-width: 80%;
  }

  .bo-product-title-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bo-bac-container {
    justify-content: center;
  }

  .iris-col {
    overflow: hidden;
  }

  .iris-col.iris-col-4 {
    flex: 1;
    width: auto;
  }

  .iris-col.iris-col-5, .iris-col.iris-col-6 {
    flex: none;
    width: 28%;
  }

  .iris-col.iris-col-7 {
    flex: none;
  }

  .iris-table-row {
    flex-wrap: nowrap;
  }

  .mpx-view-params-list {
    width: 100%;
  }

  .main-content {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 479px) {
  .container {
    flex-flow: wrap;
    align-content: flex-start;
    justify-content: center;
    font-family: Roboto, sans-serif;
  }

  .container.home {
    display: block;
  }

  .form {
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .jar-button {
    color: #fff;
    cursor: pointer;
    background-color: #fa3543;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }

  .jar-button.floating-button {
    text-transform: uppercase;
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    box-shadow: 1px 1px 11px rgba(0, 0, 0, .67);
  }

  .jar-button.floating-button.disabled {
    box-shadow: none;
    background-color: rgba(250, 53, 67, .6);
  }

  .header-wrapper {
    background-color: rgba(0, 0, 0, 0);
    height: 50px;
  }

  .content-title-wrappe {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100vh;
    padding-left: 5px;
    padding-right: 5px;
  }

  .header-content {
    justify-content: flex-start;
    display: flex;
  }

  .menu-button-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .menu-button-wrapper.menu-button {
    cursor: pointer;
  }

  .text-block {
    cursor: pointer;
    font-size: 25px;
  }

  .menu-bg {
    background-color: rgba(166, 166, 166, .6);
    width: 100%;
    height: 100vh;
    transition: background-color .5s;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .menu-bg.menu-bg-hidden {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .5s;
    display: block;
  }

  .menu-bg.menu-bg-hidden.hidden, .hidden {
    display: none;
  }

  .menu-wrapper {
    background-color: #f5f5f5;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 100vh;
    transition: left .5s;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 1px 1px 11px #646161;
  }

  .menu-wrapper.menu-wrapper-hidden {
    transition: left .5s;
    left: -80%;
  }

  .menu-user-section {
    flex-direction: column;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    position: relative;
  }

  .text-block-2 {
    color: rgba(232, 82, 45, .56);
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    font-family: Materialicons, sans-serif;
    font-size: 140px;
    display: flex;
  }

  .text-block-3 {
    font-size: 24px;
    font-weight: 700;
  }

  .text-block-3.user-label {
    text-transform: uppercase;
    font-size: 15px;
  }

  .body {
    height: 100%;
    font-family: Roboto, sans-serif;
  }

  .store-label {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .menu-items-wrapper {
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .menu-items {
    height: 100%;
  }

  .disconnect-wrapper {
    position: relative;
  }

  .menu-bottom-button {
    cursor: pointer;
    background-color: #d1d1d1;
    justify-content: center;
    align-items: center;
    height: 40px;
    display: flex;
  }

  .text-block-4 {
    text-transform: none;
    font-size: 14px;
  }

  .text-block-5 {
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    font-family: Materialicons, sans-serif;
    font-size: 35px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .div-block-2 {
    height: 70px;
  }

  .item {
    cursor: pointer;
    justify-content: flex-start;
    height: 40px;
    padding-left: 0;
    display: flex;
  }

  .item.collect-item {
    border-top: 1px solid #d1d1d1;
  }

  .item.collect-item.active {
    color: #fff;
    background-color: #e8522d;
  }

  .item.preparation-item {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
  }

  .item.preparation-item.active {
    color: #fff;
    background-color: #e8522d;
  }

  .item-label {
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    line-height: 28px;
    display: flex;
  }

  .text-block-7 {
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    font-family: Materialicons, sans-serif;
    font-size: 22px;
    display: flex;
  }

  .text-block-8 {
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    font-family: Materialicons, sans-serif;
    font-size: 22px;
    display: flex;
  }

  .close-menu {
    color: #ee9a85;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .menu-title-wrapper {
    text-align: left;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .title {
    font-size: 17.5px;
    font-weight: 300;
  }

  .confirmation-modal {
    box-shadow: 1px 1px 11px rgba(51, 51, 51, .5);
  }

  .modal-title {
    font-size: 14px;
  }

  .modal-actions {
    flex-wrap: wrap;
    align-content: stretch;
  }

  .modal-button {
    flex: 1;
    margin-bottom: 10px;
  }

  .modal-button.ok-button {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .modal-button.ok-button.ok-error {
    flex: 1;
    width: auto;
  }

  .modal-button.ok-button.disabled {
    background-color: rgba(232, 82, 45, .5);
  }

  .modal-button.ko-button {
    color: #333;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #333;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    flex: 1;
  }

  .modal-button.delete-button {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .modal-button.delete-button.ok-error {
    flex: 1;
    width: auto;
  }

  .modal-button.delete-button.disabled {
    background-color: rgba(232, 82, 45, .5);
  }

  .scanner-modal {
    border-radius: 7px;
  }

  .scanner-modal-title {
    font-size: 18px;
    font-weight: 500;
  }

  .form-block-2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .text-field-2 {
    border: 1px solid rgba(166, 166, 166, .6);
    border-radius: 4px;
    margin-bottom: 0;
  }

  .modal {
    border-radius: 7px;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
  }

  .text {
    font-weight: 300;
  }

  .text.max-qty-text {
    flex: 1;
    width: auto;
  }

  .content-container {
    background-color: #fff;
    padding-top: 50px;
  }

  .content-container.scan-label-wrapper {
    flex: 0 auto;
    align-items: center;
  }

  .content-container.order-info-wrapper {
    flex-wrap: wrap;
    align-items: center;
  }

  .collect-indication {
    flex-direction: column;
  }

  .indication-text {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 25px;
  }

  .manual-input-wrapper {
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    display: flex;
  }

  .manual-link.cleanlink {
    color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    text-decoration: none;
  }

  .manual-link.cleanlink.saisie-manuel {
    background-color: #ff712e;
  }

  .product-list {
    flex: none;
  }

  .product {
    border-bottom: 1px #ff7b82;
    border-right-style: none;
    flex: 0 auto;
    padding: 5px;
  }

  .product.expedition-order {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .product-img-wrapper {
    justify-content: space-between;
    margin-right: 10px;
    display: flex;
  }

  .product-img {
    max-width: 65px;
  }

  .product-description {
    flex-flow: column wrap;
    flex: 1;
    justify-content: center;
    align-items: stretch;
    padding: 5px 10px 5px 0;
    display: flex;
  }

  .product-title-wrapper {
    flex-flow: wrap;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 14px;
  }

  .product-brand {
    flex: 0 auto;
    margin-right: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    display: block;
  }

  .product-brand.main-view {
    display: none;
  }

  .product-label {
    flex: 0 auto;
    margin-left: 0;
    font-size: 12px;
    line-height: 12px;
    overflow: visible;
  }

  .product-details {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    display: flex;
  }

  .product-details-line {
    flex: 0 auto;
    justify-content: space-between;
    margin-right: 15px;
    display: flex;
  }

  .product-details-key {
    flex: none;
    width: 75px;
    font-size: 12px;
  }

  .product-details-value {
    flex: none;
    font-size: 12px;
  }

  .bo-product-ean {
    flex: 1;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  .product-list-wrapper {
    flex: none;
    justify-content: space-between;
    align-items: stretch;
  }

  .product-list-shelf {
    text-align: center;
    text-shadow: 1px 1px rgba(175, 169, 169, .25);
    padding-left: 0;
    overflow: hidden;
  }

  .products {
    padding-bottom: 60px;
  }

  .items-tabs {
    border-bottom: 1px solid rgba(166, 166, 166, .6);
    justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 10px;
    display: flex;
  }

  .items-tab {
    text-align: center;
    cursor: pointer;
    background-color: rgba(166, 166, 166, .6);
    border-radius: 10px;
    width: 45%;
    padding: 10px;
    font-size: 12px;
  }

  .items-tab.active {
    color: #fff;
    background-color: #e8522d;
    width: 45%;
    font-size: 12px;
  }

  .products-details {
    text-align: left;
    width: 100%;
    display: flex;
  }

  .product-image {
    width: 100%;
  }

  .image-3 {
    box-shadow: none;
  }

  .product-name {
    font-size: 16px;
  }

  .product-attributes-line {
    flex-flow: wrap;
    justify-content: flex-start;
  }

  .product-attributes-key {
    width: 100px;
  }

  .report-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .report-list {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .report-item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .report-item.found-report {
    flex-direction: row;
    max-width: 100%;
    display: flex;
    position: relative;
  }

  .report-content-title {
    width: auto;
  }

  .report-clear {
    width: 20px;
    height: 20px;
    font-size: 15px;
    display: flex;
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 0;
  }

  .div-block-3 {
    text-align: right;
    width: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .div-block-4 {
    flex-direction: row;
    flex: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .item-remaining-count {
    text-align: center;
    flex: 0 auto;
    justify-content: center;
    align-items: stretch;
    width: 50px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  .checkbox-wrapper {
    text-align: center;
  }

  .dispatch-title {
    color: #333;
    text-align: center;
    background-color: #fff;
    margin-top: 0;
    font-size: 16px;
  }

  .dispatch-indication {
    font-size: 14px;
  }

  .div-block-5 {
    flex: 1;
    align-items: flex-start;
  }

  .dispatch-codebar {
    justify-content: center;
    align-items: center;
    height: 120px;
    margin-top: 0;
    display: flex;
  }

  .text-block-16 {
    color: #ee9a85;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-top: 20px;
    font-family: Materialicons, sans-serif;
    font-size: 90px;
    display: flex;
  }

  .close-dispatch {
    width: 40px;
    height: 40px;
    font-size: 35px;
  }

  .dispatch-product-overlay-wrapper {
    padding: 10px;
  }

  .overlay-wrapper {
    display: block;
  }

  .overlay-wrapper.hidden {
    display: none;
  }

  .snack.error {
    padding-left: 10px;
    padding-right: 10px;
  }

  .snack-text {
    text-align: center;
  }

  .overlay-snack.error {
    background-color: #ee9a85;
  }

  .form-block-3 {
    margin-bottom: 5px;
  }

  .div-block-8 {
    background-color: rgba(216, 216, 216, .43);
    border-radius: 7px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .text-block-21 {
    font-weight: 700;
  }

  .colis {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .package-desc {
    width: 100%;
  }

  .package-desc-label {
    min-width: 160px;
  }

  .package-desc-value {
    flex: 0 auto;
  }

  .package-info-indication {
    font-size: 12px;
  }

  .package-info-spec-label, .package-info-spec-value {
    font-size: 14px;
  }

  .package-weight-indication {
    width: 100%;
    margin-bottom: 10px;
  }

  .text-block-23 {
    font-size: 18px;
  }

  .products-hint {
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    display: flex;
  }

  .products-hint.hidden {
    display: none;
  }

  .user-hint {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 300;
  }

  .product-id-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .dispatch-indication-order {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 28px;
  }

  .bold {
    font-size: 36px;
  }

  .tab-name {
    padding: 6px 5px;
    font-size: 18px;
  }

  .page-title {
    font-size: 28px;
    font-weight: 300;
  }

  .order-info-wrapper {
    flex-wrap: nowrap;
    flex: 0 auto;
  }

  .command-number {
    flex: 0 auto;
    justify-content: flex-start;
    align-items: center;
    display: block;
  }

  .command-number.content-title {
    font-size: 22px;
  }

  .pck-btn {
    width: 90%;
    padding: 13px;
  }

  .pck-btn.huge-btn {
    width: 90%;
  }

  .pck-btn.btn-product {
    text-transform: none;
    width: 45%;
    padding: 10px;
  }

  .pck-btn.btn-product.btn-disabled {
    background-color: #dedede;
  }

  .pck-btn.small {
    border-radius: 20px;
    width: 30px;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .pck-btn.small.active {
    background-color: rgba(153, 153, 153, .5);
    border: 1px solid #dedede;
  }

  .pck-btn.refresh-btn {
    position: fixed;
    top: 70px;
  }

  .pck-btn.create-order-btn {
    width: 200px;
    height: 50px;
    position: fixed;
    top: 70px;
    left: 10px;
  }

  .pck-btn.print-labels-btn {
    width: 150px;
    height: 40px;
    margin-top: 10px;
    margin-left: 0;
    padding-top: 10px;
  }

  .pck-btn.collect-btn.vue-no-scanette-btn {
    display: none;
  }

  .pck-btn.print-bon-degrade {
    width: 150px;
    margin-top: 10px;
    margin-left: 0;
  }

  .pck-btn-text {
    font-size: 12px;
  }

  .pck-btn-text.more-info-btn {
    font-family: Fontawesome, sans-serif;
    font-size: 18px;
  }

  .pck-btn-text.more-info-icon {
    line-height: 30px;
  }

  .pck-btn-text.new-order-btn-txt {
    font-size: 14px;
  }

  .heading {
    background-color: rgba(0, 0, 0, 0);
  }

  .content-title-wrapper {
    margin-top: 100px;
  }

  .content-title-wrapper.retour-title {
    margin-top: 70px;
  }

  .content-title-wrapper.collect-title {
    margin-top: 65px;
  }

  .form-wrapper {
    flex: 1;
  }

  .text-field-3 {
    font-size: 12px;
    line-height: 14px;
  }

  .content-wrapper {
    flex-wrap: wrap;
    flex: 1;
    align-content: stretch;
    align-items: flex-start;
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .content-wrapper.missions-collecte {
    justify-content: flex-start;
    margin-bottom: 0;
    margin-right: 0;
    padding: 0 10px;
  }

  .content-wrapper.liste-mission-wrapper {
    flex-flow: column;
    flex: 0 auto;
    align-content: space-around;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .content-wrapper.order-count-wrapper {
    flex-direction: row;
  }

  .heading-2 {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .bac-list-wrapper {
    flex-direction: column;
    flex: none;
  }

  .bac-list {
    flex-direction: column;
  }

  .form-block-5 {
    margin-right: 10px;
  }

  .content-title {
    font-size: 16px;
    line-height: 24px;
  }

  .content-title.command-number {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .bo-product-description {
    flex-flow: wrap;
    flex: 1;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    display: flex;
  }

  .bo-product {
    border-bottom: 1px #ff7b82;
    border-right-style: none;
    flex-direction: row;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
  }

  .bo-product-brand {
    text-align: left;
    flex: 1;
    font-size: 14px;
  }

  .bo-product-label {
    flex: 1;
    margin-left: 0;
    font-size: 12px;
    line-height: 16px;
    overflow: visible;
  }

  .bo-product-list {
    flex: none;
  }

  .bo-product-list-wrapper {
    flex: none;
    justify-content: space-between;
    align-items: stretch;
  }

  .bo-product-img {
    max-width: 60px;
  }

  .bo-bac-list-wrapper {
    flex-direction: column;
    flex: none;
  }

  .bo-bac-list {
    flex-direction: column;
  }

  .bo-bac-id {
    flex: 1;
    margin-right: 10px;
  }

  .bo-bac {
    flex-wrap: nowrap;
    flex: none;
    width: 100%;
  }

  .bo-btn {
    width: 90%;
    padding: 13px;
  }

  .bo-btn.huge-btn {
    width: 90%;
  }

  .bo-btn.substitution-btn, .bo-btn.missing-btn {
    width: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .bo-btn-text {
    font-size: 18px;
  }

  .bo-btn-text.substitution-btn-text, .bo-btn-text.missing-btn-text {
    font-size: 16px;
  }

  .bo-btn-text.remove-bac-btn-text, .bo-btn-text.remove-ean-btn-text {
    font-size: 15px;
  }

  .bo-wrapper-grey {
    flex-direction: row;
  }

  .bo-wrapper-grey.bo-bac-list {
    flex-direction: column;
    padding-right: 0;
  }

  .bo-content-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 22px;
    line-height: 20px;
  }

  .login-form {
    flex-flow: column wrap;
    flex: 0 auto;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .pck-content-container {
    flex-flow: column wrap;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .pck-content-container.product-list-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pck-content-container.order-info-wrapper {
    flex: 0 auto;
    justify-content: center;
    align-items: stretch;
  }

  .pck-content-container.item-description-wrapper {
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .liste-mission-header {
    flex: none;
  }

  .header-collecte-2 {
    width: 35%;
    font-size: 12px;
  }

  .header-collecte-1 {
    font-size: 12px;
  }

  .header-expedition-3 {
    width: 20%;
    font-size: 12px;
  }

  .text-block-51 {
    font-size: 12px;
  }

  .mission-id {
    flex: 0 auto;
    line-height: 14px;
    display: inline;
  }

  .mission-desc {
    line-height: 14px;
    display: inline;
  }

  .mission-status {
    flex-wrap: nowrap;
    flex: 1;
    display: block;
  }

  .mission-assignment {
    display: inline;
  }

  .div-block-19 {
    flex-wrap: wrap;
  }

  .div-block-20 {
    width: 35%;
  }

  .div-block-21 {
    flex-wrap: wrap;
    width: 20%;
    display: block;
  }

  .heading-5 {
    margin-top: -1px;
    font-size: 24px;
  }

  .heading-6 {
    margin-top: 0;
    font-size: 24px;
  }

  .heading-7 {
    margin-top: -2px;
    font-size: 24px;
  }

  .header-collecte-4.white, .header-expedition-1, .header-expedition-4.white, .header-expedition-5.white {
    font-size: 12px;
  }

  .btn-wrapper {
    width: 100%;
  }

  .btn-wrapper.btn-products-wrapper {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .btn-wrapper.bo-action-btn-wrapper {
    flex-direction: column;
  }

  .header-expedition-2 {
    width: 35%;
    font-size: 12px;
  }

  .header-expedition-2.white {
    width: 30%;
  }

  .header-collecte-3 {
    width: 20%;
    font-size: 12px;
  }

  .mission-expedition {
    padding: 0 10px;
  }

  .mission-inter {
    flex-wrap: nowrap;
    flex: 0 auto;
    display: inline;
  }

  .mission-collecte-wrapper {
    align-items: stretch;
  }

  .product-ean {
    flex: none;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 14px;
  }

  .product-details-wrapper {
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    line-height: 14px;
    display: flex;
  }

  .product-picking-zone {
    font-size: 12px;
    line-height: 12px;
  }

  .order-summary {
    flex-wrap: nowrap;
    flex: 0 auto;
    align-items: stretch;
    width: 100%;
  }

  .order-summary-line {
    flex-flow: wrap;
    align-content: stretch;
    justify-content: flex-start;
  }

  .order-summary-key {
    flex: 0 auto;
    width: 50%;
    margin-right: 0;
    font-size: 12px;
    line-height: 12px;
  }

  .order-summary-value {
    width: 50%;
    padding-left: 10px;
    font-size: 12px;
    line-height: 12px;
  }

  .tab-title {
    text-align: center;
    width: 45%;
    padding: 5px 0;
    font-size: 12px;
  }

  .tab-title.tab-selected {
    padding-left: 0;
    padding-right: 0;
  }

  .tab-wrapper {
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .header-title {
    text-transform: none;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }

  .table-line {
    flex-wrap: wrap;
    min-height: 50px;
  }

  .table-line.impair {
    flex-flow: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }

  .table-line.pair {
    padding-left: 10px;
    padding-right: 10px;
  }

  .table-line.mission-cell.impair {
    flex-flow: wrap;
  }

  .table-line.table-cell.pair {
    justify-content: space-around;
  }

  .mission-cell {
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    margin-right: 0;
    padding: 5px;
    font-size: 11px;
    line-height: 14px;
    display: flex;
  }

  .mission-header {
    flex: 1;
  }

  .div-block-38 {
    flex-flow: column wrap;
    width: 100%;
    max-width: 100%;
  }

  .heading-8 {
    flex: 0 auto;
  }

  .order-count-name {
    font-size: 12px;
    line-height: 14px;
  }

  .order-dashboard-wrapper-fake {
    margin-top: 0;
    padding: 10px 5px;
  }

  .order-dashboard-wrapper-fake.order-dashboard-collect {
    margin-top: 0;
  }

  .order-dashboard-wrapper-fake.start-margin {
    margin-top: 50px;
  }

  .order-count-line {
    flex-wrap: nowrap;
  }

  .order-count-wrapper {
    justify-content: center;
    width: 90px;
    height: 90px;
  }

  .order-count {
    font-size: 22px;
    line-height: 22px;
  }

  .table-cell {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
  }

  .table-cell.no-expand {
    flex: 0 auto;
    justify-content: flex-start;
    padding-left: 0;
    display: block;
  }

  .table-cell.mission-picker-name {
    clear: none;
    display: none;
  }

  .table-cell.one-line-picker {
    margin-left: 0;
    display: flex;
  }

  .div-block-42 {
    flex: 1;
  }

  .product-info-wrapper {
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: center;
    line-height: 14px;
    display: flex;
  }

  .product-title {
    flex-wrap: wrap;
  }

  .product-title.expedition-product {
    justify-content: center;
  }

  .scan-label-title {
    font-size: 16px;
    line-height: 20px;
  }

  .scan-label-title.command-number {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .report-title {
    max-width: 70%;
    font-size: 20px;
  }

  .div-block-44 {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .div-block-45 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .dispatch-comments {
    flex: 1;
    width: 100%;
  }

  .bo-product-title-wrapper {
    flex-flow: column wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 14px;
  }

  .items-collected-count-title, .items-remaining-count-title {
    font-size: 13px;
  }

  .item-count-wrapper {
    text-align: center;
    flex: 0 auto;
    justify-content: center;
    align-items: stretch;
    width: 50px;
    margin-right: 10px;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 700;
    display: block;
  }

  .item-collected-count {
    text-align: center;
    flex: 0 auto;
    justify-content: center;
    align-items: stretch;
    width: 50px;
    font-size: 16px;
    font-weight: 700;
  }

  .item-total-count {
    text-align: center;
    flex: 0 auto;
    justify-content: center;
    align-items: stretch;
    width: 50px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  .div-block-47 {
    flex-flow: wrap;
    align-content: stretch;
    justify-content: space-around;
    align-items: center;
  }

  .max-qty {
    flex: 1;
    min-width: 100px;
  }

  .dev-btn-container {
    position: relative;
  }

  .bo-bac-container {
    margin-right: 0;
  }

  .collect-mission-title {
    flex-direction: row;
    justify-content: center;
    display: flex;
  }

  .collect-mission-status {
    justify-content: center;
    display: flex;
  }

  .mission-button-wrapper {
    margin-top: 5px;
  }

  .order-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .order-block {
    margin-left: 0;
  }

  .product-number {
    justify-content: center;
    display: flex;
  }

  .order-link-list {
    flex-flow: column wrap;
    justify-content: center;
  }

  .top-button-wrapper {
    flex-direction: column;
  }

  .searching-input-div {
    flex-direction: column;
    justify-content: flex-start;
  }

  .substitution-product {
    justify-content: flex-end;
    margin-top: 0;
    margin-left: 0;
    font-size: 13px;
    display: flex;
  }

  .substitution-product.degrade {
    margin-left: 0;
  }

  .substitution-product.main-view, .grid-header.table-header {
    display: none;
  }

  .div-block-56 {
    flex-direction: column;
    justify-content: flex-start;
  }

  .text-block-66 {
    color: #fa3543;
    font-family: Fontawesome webfont, sans-serif;
  }

  .container-overlay-indication {
    text-align: center;
  }

  .tab-wrapper-2, .tab-wrapper-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .modal-2 {
    border-radius: 7px;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
  }

  .ug-input, .id-input {
    flex: 1;
    min-width: 100px;
  }

  .product-var-weight {
    font-size: 12px;
    line-height: 12px;
  }

  .modal-content-2 {
    display: block;
  }

  .modal-actions-2 {
    flex-wrap: wrap;
    align-content: stretch;
    display: flex;
  }

  .heading-9 {
    max-width: 100%;
  }

  .div-block-81 {
    flex-wrap: wrap;
  }

  .list-missingp-date-from {
    max-width: 100%;
  }

  .text-block-119 {
    min-width: 40px;
    min-height: 40px;
  }

  .missions-tabs {
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .missings_from {
    flex: 1;
    min-width: 100px;
  }

  .missings-modal-row {
    flex-flow: wrap;
    align-content: stretch;
    justify-content: space-around;
    align-items: center;
  }

  .list-missingp-date-to, .list-missingp-to {
    max-width: 100%;
  }

  .order-content-modal, .ug-view-modal {
    box-shadow: 1px 1px 11px rgba(51, 51, 51, .5);
  }

  .page-number {
    font-size: 7px;
  }

  .inputs-modal {
    border-radius: 7px;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
  }

  .input-line {
    flex-flow: wrap;
    align-content: stretch;
    justify-content: space-around;
    align-items: center;
  }

  .input-inputbox {
    flex: 1;
    min-width: 100px;
  }

  .split-mission-id, .split-mission-products {
    flex: 0 auto;
    line-height: 14px;
    display: inline;
  }

  .div-block-279 {
    width: 100%;
  }

  .div-block-285 {
    font-size: 20px;
  }

  .mpx-view-report-name {
    font-size: 12px;
  }

  .mpx-view-report-report-name, .mpx-view-report-option-select-field {
    font-size: 11px;
  }

  .mpx-view-report-report-button {
    font-size: 8px;
  }

  .tml-order-content-modal {
    box-shadow: 1px 1px 11px rgba(51, 51, 51, .5);
  }

  .scanner-subs-modal {
    border-radius: 7px;
  }

  .kbrw-checkbox-wrapper {
    border-width: 1px;
    border-radius: 2px;
  }

  .kbrw-buttons-wrapper {
    flex-wrap: wrap;
  }

  .intervention-history-modal, .client-view-modal, .unused-ug-modal, .notification-modal {
    box-shadow: 1px 1px 11px rgba(51, 51, 51, .5);
  }

  .email-content-container {
    flex-flow: column wrap;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .email-content-container.product-list-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .email-content-container.order-info-wrapper {
    flex: 0 auto;
    justify-content: center;
    align-items: stretch;
  }

  .email-content-container.item-description-wrapper {
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#w-node-fba6826a-ec33-ec94-7ca6-38be2ea9749e-f6aebbea {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-d2a015b8-3077-d3ae-e588-4fb5a97e048b-f6aebbea {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_85dacd0d-a335-13aa-936a-b514df1c6f35-f6aebbea {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_9ec2ecf5-a451-6962-1566-11291ee6417a-f6aebbea {
  grid-area: 2 / 2 / 3 / 3;
}

#w-node-_3e917f6d-7a2a-d944-c0c4-53cd9f5cdb55-f6aebbea {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-_2ae57ccc-5a37-8673-87fe-c86f326a3519-f6aebbea {
  grid-area: 3 / 2 / 4 / 3;
}

#w-node-_50aef1c9-de16-b7fd-edd6-87350357f69e-f6aebbea {
  grid-area: 4 / 1 / 5 / 2;
}

#w-node-_3c5e63fb-6d81-0e58-3e7e-6d853901966d-f6aebbea {
  grid-area: 4 / 2 / 5 / 3;
}

#w-node-_3bcf700d-8c0d-2f7f-bba1-67bf8252a682-39eb1e02 {
  grid-area: Area-2;
}

#w-node-_3bcf700d-8c0d-2f7f-bba1-67bf8252a687-39eb1e02 {
  grid-area: Area;
  justify-self: end;
}

#w-node-_3bcf700d-8c0d-2f7f-bba1-67bf8252a62f-39eb1e02 {
  align-self: auto;
}

#w-node-_7b24df2c-39aa-4274-93e9-2fa1bdee54b7-e0efee97 {
  grid-area: span 2 / span 2 / span 2 / span 2;
  align-self: stretch;
  justify-self: stretch;
}

#w-node-fc2a877f-ad1e-a9a8-41f5-c7eeca27a0d6-e0efee97 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}


@font-face {
  font-family: 'Fontawesome webfont';
  src: url('../fonts/fontawesome-webfont.woff2') format('woff2'), url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Materialicons';
  src: url('../fonts/MaterialIcons-Regular.woff2') format('woff2'), url('../fonts/MaterialIcons-Regular.eot') format('embedded-opentype'), url('../fonts/MaterialIcons-Regular.woff') format('woff'), url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Fa solid 900';
  src: url('../fonts/fa-solid-900.woff2') format('woff2'), url('../fonts/fa-solid-900.eot') format('embedded-opentype'), url('../fonts/fa-solid-900.woff') format('woff'), url('../fonts/fa-solid-900.ttf') format('truetype'), url('../fonts/fa-solid-900.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Ean13';
  src: url('../fonts/ean13.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Idautomationhc39m code 39 barcode';
  src: url('../fonts/IDAutomationHC39M%20Code%2039%20Barcode.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Fontawesome';
  src: url('../fonts/FontAwesome.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}